import React, { Component, Fragment } from 'react';
import LogoComponent from '../../components/Logo';
import { FormContent } from './../../components/Styled/Content';
import { Redirect } from 'react-router-dom';
import { StoragePage, ErrorBox } from './Styles';
import HeadlineComponent from '../../components/Headline';
import RadioListComponent from '../../components/Radio/RadioList';
import RadioComponent from './../../components/Radio';
import ButtonComponent from './../../components/Button';
import StepsComponent from '../../components/Steps';
import SummaryContainer from '../../containers/summary';

class Storage extends Component{
  constructor(props){
    super(props);
    this.state = {
      nextStep: false,
    }
    this.setSelected = this.setSelected.bind(this);
    this.sendOrderStep3 = this.sendOrderStep3.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.getContractsDescription = this.getContractsDescription.bind(this);
    this.getContractsPrice = this.getContractsPrice.bind(this);
  }
  
  componentDidMount(){
    this.props.clearAllErrors();
  }
  
  componentWillReceiveProps(nextProps){
    if(JSON.stringify(this.props.orderStep3) !== JSON.stringify(nextProps.orderStep3) || JSON.stringify(this.props.selectedAdditionalPackages) !== JSON.stringify(nextProps.selectedAdditionalPackages) || JSON.stringify(this.props.selectedPromotions) !== JSON.stringify(nextProps.selectedPromotions)){
      this.sendOrderStep3(nextProps);
    }
  }

  get contract(){
    return this.props.orderStep3.przedplata;
  }
  get contractsList(){
    if(this.contract){
      return (
        <Fragment>
          <HeadlineComponent uppercase>Opłata za przechowywanie</HeadlineComponent>
          <RadioListComponent setSelected={this.setSelected} selectedValue={this.contract.value}>
            { this.contracts }
          </RadioListComponent>
        </Fragment>
      )
    }
    return '';
  }

  getContractsDescription(id){
    const contract = this.props.orderStep3.przedplata;
    const prices = this.props.prices['3'] || null;
    const prices2 = this.props.prices['3sz'] || null;
    const pricesAbo = this.props.prices['4'] || null;
    const pricesAbo2 = this.props.prices['4sz'] ? this.props.prices['4sz']['2'][0][1] : 0;
    const desc1 = id ==1 ? 'Opłata roczna za krew pępowinową' : 'Opłata za krew pępowinową';
    const desc2 = id ==1 ? 'Opłata roczna za sznur pępowinowy' : 'Opłata za sznur pępowinowy';
    if(contract != undefined && prices != null){
      const contracts = [];
      for(let key in contract.MAP){
        contracts.push(parseInt(key));
      }
      const contractIndex = contracts.indexOf(parseInt(id));
      const result =  [{
        description: prices['2'][contractIndex][0],
        priceDescription: id == 1 ? `${pricesAbo[0]}: ${parseInt(pricesAbo['2'][0][1]) + parseInt(pricesAbo2)}zł` : ''
      },
      {
        description: `${desc1} - ${parseFloat(prices['2'][contractIndex][1])}zł`,
        priceDescription: id == 1 ? `${desc1}: ${parseFloat(pricesAbo['2'][0][1])}zł` : ''
      },
      prices2 && pricesAbo2 ? {
        description: `${desc2} - ${parseFloat(prices2['2'][contractIndex][1])}zł`,
        priceDescription: id == 1 ? `${desc2}: ${parseFloat(pricesAbo2)}zł` : ''
      } : undefined
    ];
      return result;
    }
    return [];
  }

  getContractsPrice(id){
    const contract = this.props.orderStep3.przedplata;
    const prices = this.props.prices['3'] || null;
    const prices2 = this.props.prices['3sz'] || null;
    if(contract != undefined && prices != null){
      const contracts = [];
      for(let key in contract.MAP){
        contracts.push(parseInt(key));
      }
      const contractIndex = contracts.indexOf(parseInt(id));
      const price2 = prices2 ? prices2['2'][contractIndex][1] : 0;
      const result = {
        promo: null,
        standard: parseInt(prices['2'][contractIndex][1]) + parseInt(price2)
      }
      return result;
    }
    return false;
  }

  get contracts(){
    const result = [];
    for(let key in this.contract.MAP){
      if(this.contract.MAP.hasOwnProperty(key)){
        if(key !== ''){
          result.push(<RadioComponent withPrices descriptions={this.getContractsDescription(key)} prices={this.getContractsPrice(key)} key={key} option={key} id={`radio-${key}`} name={this.contract.name} value={key} label={this.contract.MAP[key]} />);
        }
      }
    }
    return result;
  }

  // get contracts(){
  //   return this.props.contracts && this.props.contracts.map((type, index) => {
  //     return (<RadioComponent withPrices key={type.id} option={type} id={`radio-contract-${type.id}`} name="contract" value={type.id} label={type.label} descriptions={type.descriptions} prices={type.prices} />)
  //   }) 
  // }

  componentDidMount(){
    // this.props.receiveOrderStep3();
  }

  setSelected(selected, name){
    this.props.setOrderStep3(selected, name);
  }

  getStep3Data(props){
    const selectedPackages = props.selectedAdditionalPackages.map(pack => {
      return pack.id;
    }).join('|');
    return {
      uslugi_dodatkowe_new: selectedPackages,
      przedplata: props.orderStep3.przedplata.value,
      promocja_id: props.selectedPromotions,
    }
  }
  
  sendOrderStep3(props){
    const data = this.getStep3Data(props);
    this.props.sendOrderStep3(data);
  }

  get redirector(){
    if(typeof(this.props.orderStep1.rodzaj_ciazy) == 'undefined'){
      return (
        <Redirect to='/pregnant' />
      )
    }
    return '';
  }
  
  nextStep(){
    const data = this.getStep3Data(this.props);
    this.props.checkCurrentStep(data, () => {
      this.setState({nextStep: true});
    });
  }

  get renderNextStep(){
    return this.state.nextStep && (
      <Redirect to='/address' />
    )
  }

  get errors(){
    return this.props.orderErrors && this.props.orderErrors.map((error, i) => {
      return <ErrorBox key={i}>{ error.msg }</ErrorBox>;
    })
  }

  render(){
    return(
      <StoragePage className="content">
        <FormContent>
          <LogoComponent />
          <StepsComponent active="3" />

          { this.contractsList }
          
          { this.errors }
          <ButtonComponent primary label="Przejdź dalej" click={this.nextStep} />
        </FormContent>
        <SummaryContainer />
        { this.redirector }
        { this.renderNextStep }
      </StoragePage>
    )
  }
}

export default Storage;