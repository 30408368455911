import React from 'react';
import { SmallText } from '../Styled';
import {Tooltip, TooltipIcon, TooltipInfo} from './Styles/';

const TooltipComponent = ({copy}) => {
  return (
    <Tooltip>
      <TooltipIcon />
      <TooltipInfo>
        <SmallText dangerouslySetInnerHTML={{ __html: copy}}></SmallText>
      </TooltipInfo>
    </Tooltip>
  );
}

export default TooltipComponent;