const seed = {
  fname: '',
  lname: '',
  PESEL: '',
  telefon: '',
  email: '',
  fname_ojca: '',
  lname_ojca: '',
  PESEL_ojca: '',
  telefon_ojca: '',
  email_ojca: '',
  adr_id_matka_meldunek: '',
  adr_id_matka_koresp: '',
  adr_id_ojciec_meldunek: '',
  adr_id_ojciec_koresp: '',
  adr_id_umowa_koresp: '',
  adr_matka_koresp: '',
  adr_ojciec_koresp: '',
};

const FIELD_TYPES = {
  1: 'adr_id_matka_koresp',
  2: 'adr_id_matka_meldunek',
  3: 'adr_id_ojciec_koresp',
  4: 'adr_id_ojciec_meldunek',
};

const FIELD_INDEX = {
  kod: 1,
  miejscowosc: 2,
  ulica: 3,
  nr: 4,
  lokal: 5,
};

export const userAddressData = (state = seed, action) => {
  switch(action.type){
    case 'INIT_ADDRESS_DATA': {
      const addressData = Object.assign({}, state);
      for(let key in addressData){
        if(action.payload.hasOwnProperty(key)){
          addressData[key] = action.payload[key];
        }
      }
      return addressData;
    }
    case 'SET_ADDRESS_DATA': {
      if(!action.payload.parent) {
        return {...state, [action.payload.field]: {
            ...state[action.payload.field],
            value: action.payload.value,
            error: null,
          }};
      }
      return {
        ...state,
        [action.payload.parent]: {
          ...state[action.payload.parent],
          fields: {
            ...state[action.payload.parent].fields,
            [action.payload.field]: {
              ...state[action.payload.parent].fields[action.payload.field],
              value: action.payload.value,
              error: null,
            },
          }
        }};
    }
    case 'SET_ADDRESS_DATA_ERRORS': {
      const data = Object.assign({}, state);
      action.payload.map(field => {
        const fieldName = field.field;
        const errorValue = field.msg;
        if(fieldName.split('_').length && fieldName !== "adr_id_umowa_koresp") {
          const f = fieldName.split('_');
          const fieldType = FIELD_TYPES[f[1]];
          const fieldIndex = FIELD_INDEX[f[f.length - 1]];
          if(fieldType !== undefined && fieldIndex !== undefined){
            data[fieldType].fields[fieldIndex].error = errorValue;
          }
        } else {
          data[fieldName].error = errorValue;
        }
      });
      return data;
    }
    case 'CLEAR_ADDRESS_DATA_ERRORS': {
      const addressData = Object.assign({}, state);
      for(let key in addressData){
        if(typeof(addressData[key].error) != 'undefined'){
          addressData[key].error = null;
        }
      }
      return addressData;
    }
    default: 
      return state;
  }
}