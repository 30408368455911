export const selectedAdditionalPackages = (state = [], action) => {
  switch(action.type){
    case 'ADD_SELECTED_ADDITIONAL_PACKAGE':{
      const packages = [...state];
      const exist = packages.filter(pack => {
        return pack.name == action.payload.name;
      });
      if(exist.length === 0){
        packages.push(action.payload);
      }
      return packages; 
    }
    case 'REMOVE_SELECTED_ADDITIONAL_PACKAGE':{
      const packages = [...state];
      return packages.filter(pack => {
        return pack.name != action.payload.name;
      });
    }
    case 'CLEAR_SELECTED_ADDITIONAL_PACKAGE':
      return [];
    default:
      return state;
  }
}