import React, { Component, Fragment } from 'react';
import LogoComponent from '../../components/Logo';
import { FormContent } from './../../components/Styled/Content';
import { MedicalInverviewPage, FormSection, ErrorBox } from './Styles';
import { Redirect } from 'react-router-dom';
import HeadlineComponent from '../../components/Headline';
import ButtonComponent from './../../components/Button';
import StepsComponent from '../../components/Steps';
import InputComponent from '../../components/Input';
import SeparatorComponent from '../../components/Separator';
import {Header5, Header4} from './../../components/Styled';
import SelectComponent from '../../components/Select';
import CalendarComponent from '../../components/Calendar';
import SummaryContainer from '../../containers/summary';
import YesNoRadioInputComponent from '../../components/Radio/YesNoInput';
import {YesNoBox, YesNo} from '../../components/YesNo/Styles';


class MedicalInverview extends Component{
  constructor(props){
    super(props);
    this.state = {
      nextStep: false,
      errors: [],
    }
    this.handleChange = this.handleChange.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.getValue = this.getValue.bind(this);
    this.handleChangeQuestions = this.handleChangeQuestions.bind(this);
    this.handleChangeQuestionsInput = this.handleChangeQuestionsInput.bind(this);
    this.getQuetionError = this.getQuetionError.bind(this);
    this.getLabelQuestions = this.getLabelQuestions.bind(this);
    this.getValueQuestions = this.getValueQuestions.bind(this);
    this.updateOrderStep5 = this.updateOrderStep5.bind(this);
    this.getRequire = this.getRequire.bind(this);
    window.scrollTo(0, 0);
  }

  componentDidMount(){
    this.props.clearAllErrors();
    this.props.receiveOrderStep5();
  }

  getLabel(key){
    return this.props.orderStep5 && this.props.orderStep5[key] ? this.props.orderStep5[key].label : '';
  }

  getValue(key){
    return this.props.orderStep5 && this.props.orderStep5[key] ? this.props.orderStep5[key].value : '';
  }

  getRequire(item){
    return  (item.minlength > 0) || item.min > 0 || item.isReq ? true : false;
  }


  handleChange(data, name){
    this.props.setOrderStep5(data, name);
  }

  getLabelQuestions(key){
    return this.props.orderStep5Questions && this.props.orderStep5Questions[key] ? this.props.orderStep5Questions[key].label : '';
  }

  getValueQuestions(key){
    return this.props.orderStep5Questions && this.props.orderStep5Questions[key] ? this.props.orderStep5Questions[key].value : '';
  }

  getQuetionError(key){
    const error = this.state.errors.filter(e => e.field === key);
    if(error.length){
      return error[0].msg
    }
    return false;
  }

  handleChangeQuestions(value, name){
    this.props.setOrderStep5Questions(value, name);
  }

  handleChangeQuestionsInput(data){
    this.setState({
      errors: this.state.errors.filter(e => e.field !== data.field)
    });
    this.props.setOrderStep5Questions(data.value, data.field);
  }

  updateOrderStep5(){
    if(this.validateForm()){
      const data = {};
      for(let key in this.props.orderStep5){
        if(this.props.orderStep5.hasOwnProperty(key)){
          data[key] = this.props.orderStep5[key].value;
        }
      }
      this.props.updateOrderStep5(data, () => {
        this.updateOrderStep5Questions();
      });
    }
  }

  updateOrderStep5Questions(){
    const data = {};
      this.props.orderStep5Questions.map(question => {
        data[question.name] = question.value;
      });
    this.props.updateOrderStep5Questions(data, () => {
      this.setState({nextStep: true});
    });
  }

  
  get renderNextStep(){
    return this.state.nextStep && (
      <Redirect to='/summary' />
    )
  }

  validateForm(){
    const errors = [];
    for(let i = 0; i < this.props.orderStep5Questions.length; i++){
      const question = this.props.orderStep5Questions[i];
      if(question.type === 'hidden'){
        continue;
      }
      if(question.type === 'IntStringMap'){
        const isInputAvailable = i+1 < this.props.orderStep5Questions.length && this.props.orderStep5Questions[i+1].name === `${question.name}_data`;
        if(((question.value == "1" && typeof(question.notRelated) == 'undefined') || (question.value == "0" && question.notRelated == 1)) && isInputAvailable && this.getRequire(this.props.orderStep5Questions[i+1]) && (typeof(this.props.orderStep5Questions[i+1].value) == 'undefined' || this.props.orderStep5Questions[i+1].value.length === 0)){
          errors.push({
            msg: `Opis pola ${question.label} jest wymagany.`,
            field: this.props.orderStep5Questions[i+1].name
          });
        }
      }
    }
    this.setState({
      errors
    });
    if(errors.length){
      return false;
    }
    return true;
  }

  get illnesses(){
    const illnessesList = [];
    for(let i = 0; i < this.props.orderStep5Questions.length; i++){
      const question = this.props.orderStep5Questions[i];
      if(question.type === 'hidden'){
        continue;
      }
      if(question.type === 'IntStringMap'){
        const options = [];
        for(let key in question.MAP){
          if(question.MAP[key] !== ' '){
            options.push({
              value: key,
              label: question.MAP[key],
            })
          }
        }
        const radios = options.map(option => {
          return <YesNoRadioInputComponent selectedValue={question.value} changeHandler={(value) => {this.handleChangeQuestions(value, question.name)}} id={`${question.name}-${option.label}`} label={option.label} value={option.value} name={question.name} />
        });
        const isInputAvailable = i+1 < this.props.orderStep5Questions.length && this.props.orderStep5Questions[i+1].name === `${question.name}_data`;
        const input = ((question.value == "1" && typeof(question.notRelated) == 'undefined') || (question.value == "0" && question.notRelated == 1)) && isInputAvailable ? <InputComponent required={this.getRequire(this.props.orderStep5Questions[i+1])} error={this.getQuetionError(this.props.orderStep5Questions[i+1].name)} changeHandler={this.handleChangeQuestionsInput} type="text" label={this.props.orderStep5Questions[i+1].label} value={this.props.orderStep5Questions[i+1].value} id={this.props.orderStep5Questions[i+1].name} /> : '';
        const errors = this.props.orderErrors && this.props.orderErrors.filter(e => e.field == `${question.name}_data` || e.field == question.name);
        const error = errors && errors.length > 0 ? (
          <ErrorBox fieldError>{errors[0].msg}</ErrorBox>
        ) : '';
        const inputs = (
          <Fragment>
            {question.apigrp1 !== null && typeof(question.apigrp1) != 'undefined' ? <SeparatorComponent half /> : ''}
            <YesNo>
              {question.apigrp1 !== null && typeof(question.apigrp1) != 'undefined' ? <Header4 dangerouslySetInnerHTML={{ __html: question.apigrp1.split("&nbsp;").join(" ")}}></Header4> : ''}
              {question.apigrp2 !== null && typeof(question.apigrp2) != 'undefined' ? <Header4 dangerouslySetInnerHTML={{ __html: question.apigrp2.split("&nbsp;").join(" ")}}></Header4> : ''}
              <Header5 dangerouslySetInnerHTML={{ __html: question.label != question.apigrp1 && question.label != question.apigrp2 && typeof(question.label) != 'undefined' ? question.label.split("&nbsp;").join(" ") : '' }}></Header5>
              <YesNoBox>
                {radios}
              </YesNoBox>
              {input}
              { error }
            </YesNo>
          </Fragment>
        )
        illnessesList.push(inputs);
        if(isInputAvailable){
          i++;
        }
      }
    }
    return illnessesList;
  }

  get redirector(){
    if(typeof(this.props.orderStep1.rodzaj_ciazy) == 'undefined'){
      return (
        <Redirect to='/pregnant' />
      )
    }
    return '';
  }

  get error(){
    return this.state.errors && this.state.errors.map((error, i) => {
      return <ErrorBox key={i}>{ error.msg }</ErrorBox>;
    })
  }

  get orderError(){
    return this.props.orderErrors && this.props.orderErrors.map((error, i) => {
      return error.field === 'common' || error.field === 'termin_porodu_plan' || error.field === 'plan_szpital_id' ? (
        <ErrorBox fieldError key={i}>{ error.msg }</ErrorBox>
      ) : '';
    })
  }


  render(){
    return(
      <MedicalInverviewPage className="content">
        <FormContent>
          <LogoComponent />
          <StepsComponent active="5" />

          <HeadlineComponent>Wypełnij wywiad medyczny</HeadlineComponent>
         
          <FormSection>
            { this.illnesses }
          </FormSection>
          
          <SeparatorComponent />
          <HeadlineComponent>Wypełnij dane dotyczące porodu</HeadlineComponent>
          <FormSection>
            <CalendarComponent changeHandler={this.handleChange} id="termin_porodu_plan" label={ this.getLabel('termin_porodu_plan')} type="text" name="termin_porodu_plan" value={ this.getValue('termin_porodu_plan')} />
            <SelectComponent changeHandler={this.handleChange} id="plan_szpital_id" label={ this.getLabel('plan_szpital_id')} name="plan_szpital_id" options={this.props.hospitals} value={this.getValue('plan_szpital_id')} />
            <InputComponent changeHandler={this.handleChange} id="lekarz_prowadzacy" label={ this.getLabel('lekarz_prowadzacy')} type="text" name="lekarz_prowadzacy" value={this.getValue('lekarz_prowadzacy')} />
            <InputComponent changeHandler={this.handleChange} id="lekarz_prowadzacy_tel" label={ this.getLabel('lekarz_prowadzacy_tel')} type="text" name="lekarz_prowadzacy_tel" id="lekarz_prowadzacy_tel" value={this.getValue('lekarz_prowadzacy_tel')} />
            <InputComponent changeHandler={this.handleChange} id="lekarz_prowadzacy_mail" label={ this.getLabel('lekarz_prowadzacy_mail')} type="text" name="lekarz_prowadzacy_mail" id="lekarz_prowadzacy_mail" value={this.getValue('lekarz_prowadzacy_mail')} />
            <InputComponent changeHandler={this.handleChange} id="uwagi" label={ this.getLabel('uwagi')} type="text" name="uwagi" id="uwagi" value={this.getValue('uwagi')} />
          </FormSection>
          { this.error }
          { this.orderError }
          <ButtonComponent primary label="Przejdź dalej" click={this.updateOrderStep5} />
        </FormContent>
        <SummaryContainer />
        { this.redirector }
        { this.renderNextStep }
      </MedicalInverviewPage>
    )
  }
}

export default MedicalInverview;