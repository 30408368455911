import React, { Component } from 'react';
import {LinkBox, LinkLabel } from './Styles';

class LinkComponent extends Component {
  constructor(props) {
    super(props);
  }
  render() { 
    return ( 
      <LinkBox {...this.props} onClick={this.props.action}>
        <LinkLabel>{this.props.label}</LinkLabel>
      </LinkBox>
     );
  }
}
 
export default LinkComponent;