import styled from 'styled-components';
import {ButtonBox} from './../../Button/Styles';
import {grid, media} from './../../Styled/helpers';
import { Header6 } from './../../Styled';

const SignInBox = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: ${grid(4)};
  padding: 0 ${grid(1)};
  ${ButtonBox}{
    margin-top: 56px;
  }
  ${media.mobile`
    width: 100%; 
    padding: 0;
  `}
`;

const ErrorBox = styled.span`
  ${ Header6 };
  color: ${props => props.theme.color.vermillion};
  margin-top: 16px;
`;

const SuccessBox = styled.span`
  ${ Header6 };
  color: ${props => props.theme.color.success};
  margin-top: 16px;
`;

export {SignInBox, ErrorBox, SuccessBox};