import React, { Component, Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';
import LogoComponent from '../../components/Logo';
import { FormContent } from './../../components/Styled/Content';
import { PregnantPage, ErrorBox } from './Styles';
import HeadlineComponent from '../../components/Headline';
import RadioListComponent from '../../components/Radio/RadioList';
import RadioComponent from './../../components/Radio';
import ButtonComponent from './../../components/Button';
import StepsComponent from '../../components/Steps';
import SummaryContainer from '../../containers/summary';

class Pregnant extends Component{
  constructor(props){
    super(props);
    this.state = {
      nextStep: false,
    }

    this.setSelected = this.setSelected.bind(this);
    this.nextStep = this.nextStep.bind(this);
  }

  componentDidMount(){
    this.props.clearAllErrors();
    if(typeof(this.props.orderStep1.rodzaj_ciazy) == 'undefined'){
      this.props.initOrder();
    }
    // typeof(this.props.orderStep1.rodzaj_ciazy) == 'undefined' && this.props.receiveOrderStep1();
  }

  componentWillReceiveProps(props){
    if(JSON.stringify(this.props.orderStep3) !== JSON.stringify(props.orderStep3) || JSON.stringify(this.props.additionalPackages) !== JSON.stringify(props.additionalPackages)){
      if(props.orderStep3.promocja_id && props.orderStep3.promocja_id.value){
        props.setSelectedPromotion(props.orderStep3.promocja_id.value);
      }
      if(props.selectedAdditionalPackages.length === 0 && props.additionalPackages && props.additionalPackages.length && props.orderStep3.uslugi_dodatkowe_new && props.orderStep3.uslugi_dodatkowe_new.value && props.orderStep3.uslugi_dodatkowe_new.value != '|' && props.orderStep3.uslugi_dodatkowe_new.value != ''){
        const services = props.orderStep3.uslugi_dodatkowe_new.value.split('|');
        services.map(service => {
          const selectedService = props.additionalPackages.filter(addPack => {
            return addPack.id == service;
          });
          if(selectedService.length){
            props.addSelectedAdditionalPackage(selectedService[0]);
          }
        })
      }
    }
  }

  get field(){
    return this.props.orderStep1.rodzaj_ciazy || false;
  }

  get pregnantField(){
    if(this.field){
      return (
        <Fragment>
          <HeadlineComponent>{this.field.label}</HeadlineComponent>
          <RadioListComponent setSelected={this.setSelected} name={this.field.name} selectedValue={this.field.value}>
            { this.pregnantTypes }
          </RadioListComponent>
        </Fragment>
      )
    }
    return '';
  }

  get pregnantTypes(){
    const result = [];
    for(let key in this.field.MAP){
      if(this.field.MAP.hasOwnProperty(key)){
        if(key !== ''){
          result.push(<RadioComponent key={key} option={key} id={`radio-${key}`} name={this.field.name} value={key} label={this.field.MAP[key]} />);
        }
      }
    }
    return result;
  }

  setSelected(selected, name){
    this.props.setOrderStep1(selected, name);
    this.props.sendOrderStep1({
      [name]: selected,
    });
  }

  nextStep(){
    this.props.checkCurrentStep({[this.field.name]: this.field.value}, () => {
      this.setState({nextStep: true});
    });
  }

  get renderNextStep(){
    return this.state.nextStep && (
      <Redirect to='/packages' />
    )
  }

  
  get errors(){
    return this.props.orderErrors && this.props.orderErrors.map((error, i) => {
      return <ErrorBox key={i}>{ error.msg }</ErrorBox>;
    })
  }


  render(){
    return(
      <PregnantPage className="content">
        <FormContent>
          <LogoComponent />
          <StepsComponent active="1" />
          { this.pregnantField }
          { this.errors }
          <ButtonComponent primary label="Przejdź dalej" click={this.nextStep} />
        </FormContent>
        <SummaryContainer />
        { this.renderNextStep }
      </PregnantPage>
    )
  }
}

export default Pregnant;