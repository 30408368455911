import styled from 'styled-components';
import { Header3, Header5 } from './../../Styled';
import { Tooltip } from './../../Tooltip/Styles/index';
import { media } from './../../Styled/helpers';

const Headline = styled.div`
  width: calc(100% - 48px);
  position: relative;
  margin-left: 16px;
  margin-bottom: 24px;
  padding: 24px 0 24px 32px;
  text-transform: ${props => props.uppercase ? 'uppercase': 'none'}
  ${Header3}{
    z-index: 1;
    position: relative;
  }
  ${Header5}{
    position: relative;
    padding-top: 8px;
  }
  ${ media.mobile`
    margin-left: 0;
  `}
  ${Tooltip}{
    display: inline-flex;
    top: 4px;
  }
`;

const ColorBox = styled.span`
  width: 76px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  background-color: ${props => props.beige ? props.theme.color.beige : props.veryLightPink ? props.theme.color.veryLightPink : props.theme.color.duckEggBlue};
`;

export {Headline, ColorBox};