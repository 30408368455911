import React, { Component } from 'react';
import { StepsBox } from './Styles';
import Step from './Step';
class StepsComponent extends Component{
  constructor(props){
    super(props);

    this.state = {
      active: 1
    }
  }

  componentDidMount(){
    this.setState({active: this.props.active});
  }

  isActive(id){
    return this.state.active == id;
  }

  isComplete(id){
    return parseInt(this.state.active, 10) > id;
  }

  render(){
    return(
      <StepsBox>
        <Step id="1" label="Rodzaj ciąży" link="pregnant" active={this.isActive(1)} complete={this.isComplete(1)} />
        <Step id="2" label="Pakiety" link="packages" active={this.isActive(2)} complete={this.isComplete(2)} />
        <Step id="3" label="Przechowywanie" link="storage" active={this.isActive(3)}  complete={this.isComplete(3)} />
        <Step id="4" label="Dane adresowe" link="address" active={this.isActive(4)}  complete={this.isComplete(4)} />
        <Step id="5" label="Wywiad medyczny" link="medical_interview" active={this.isActive(5)}  complete={this.isComplete(5)} />
        <Step id="6" label="Podsumowanie i rejestracja" link="summary"  active={this.isActive(6)} complete={this.isComplete(6)} />
      </StepsBox>
    );
  }
}

export default StepsComponent;