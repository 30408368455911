import React, { Component } from 'react';
import moment from 'moment';
import ClickOutside from 'react-click-outside';
import {InputBox, InputField, InputLabel, CalendarBox, CalendarNav, CalendarNavItem, CalendarOptions, DayItem, MonthItem, YearItem} from './Styles';

const monthsNames = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'];
const monthsDays = [31 ,29 ,31 ,30 ,31 ,30 ,31 ,31 ,30 ,31 ,30 ,31];


class CalendarComponent extends Component{
  constructor(props){
    super(props);
    this.state = {
      opened: false,
      day: 'DD',
      month: 'MM',
      year: 'RRRR',
      selectionState: 0,
    }
    this.setSelectionState = this.setSelectionState.bind(this);
    this.isActive = this.isActive.bind(this);
    this.showCalendar = this.showCalendar.bind(this);
    this.hideCalendar = this.hideCalendar.bind(this);
  }

  componentWillReceiveProps(props){
    const date = moment(props.value, 'YYYY-MM-DD');
    if(date.isValid()){
      this.setState({
        day: date.format('DD'),
        month: date.format('MM'),
        year: date.format('YYYY')
      });
    }
  }

  get value(){
    return `${this.state.day}-${this.state.month}-${this.state.year}`;
  }

  get stateValue(){
    return `${this.state.year}-${this.state.month}-${this.state.day}`;
  }

  get days(){
    if(this.state.selectionState === 0){
      const days = [];
      for(let i = 1; i <= 31; i++){
        days.push(
          <DayItem key={i} onClick={() => this.selectDay(i)}>{i}</DayItem>
        )
      }
      return days;
    }
  }

  get months(){
    if(this.state.selectionState === 1){
      return monthsNames.map((m, i) => {
        const isActive = monthsDays[i] >= parseInt(this.state.day);
        return <MonthItem key={i} isActive={isActive} onClick={() => this.selectMonth(i+1)}>{m}</MonthItem>
      });
    }
  }
  
  get year(){
    if(this.state.selectionState === 2){
      const years = [];
      for(let i = moment().year(); i <= moment().year()+2; i++){
        const isActive = moment(`${i}-${this.state.month}`, "YYYY-MM").daysInMonth() >= parseInt(this.state.day) && moment(`${i}-${this.state.month}-${this.state.day}`, "YYYY-MM-DD").isSameOrAfter();
        years.push(
          <YearItem key={i} isActive={isActive} onClick={() => this.selectYear(i)}>{i}</YearItem>
        )
      }
      return years;
    }
  }

  selectDay(day){
    const d = day < 10 ? `0${day}` : day;
    this.setState({day: d, selectionState: 1});
  }

  selectMonth(month){
    const m = month < 10 ? `0${month}` : month;
    this.setState({month: m, selectionState: 2});
  }

  selectYear(year){
    this.setState({year, selectionState: 2, opened: false}, () => {
      this.props.changeHandler({
        field: this.props.name,
        value: this.stateValue
      });
    });
  }

  showCalendar(){
    this.setState({opened: true, selectionState: 0});
  }

  hideCalendar(){
    this.setState({opened: false, selectionState: 0});
  }

  isActive(id){
    return this.state.selectionState === id;
  }

  setSelectionState(id){
    this.setState({selectionState: id});
  }

  render(){
    return (
      <InputBox {...this.props}>
        <ClickOutside onClickOutside={this.hideCalendar}>
          <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
          <InputField id={this.props.id} type={this.props.type} readOnly value={ this.value } onFocus={this.showCalendar} />
          <CalendarBox opened={this.state.opened}>
            <CalendarNav>
              <CalendarNavItem active={this.isActive(0)} onClick={() => this.setSelectionState(0)}>dzień</CalendarNavItem>
              <CalendarNavItem active={this.isActive(1)} onClick={() => this.setSelectionState(1)}>miesiąc</CalendarNavItem>
              <CalendarNavItem active={this.isActive(2)} onClick={() => this.setSelectionState(2)}>rok</CalendarNavItem>
            </CalendarNav>
            <CalendarOptions>
              {this.days}
              {this.months}
              {this.year}
            </CalendarOptions>
          </CalendarBox>
        </ClickOutside>
      </InputBox>
    )
  }
}

export default CalendarComponent;