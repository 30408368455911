import Api from '../../../utils/api';
import {
  initOrderStep3,
  hideLoader,
  receivePrices,
  clearOrderErrors,
  checkErrors,
  setError,
} from '../index';

export function receiveOrderStep3() {
  return (dispatch) => {
    new Api(`/api.php?action=UmowaS3Get${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            const data = {};
            json.OK.map(field => {
              data[field.name] =  field;
            });
            dispatch(initOrderStep3(data));
          }else{
            dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}

export function sendOrderStep3(data){
  return (dispatch) => {
    dispatch(clearOrderErrors()); 
    const errors = [];
    if(data.przedplata === null || typeof(data.przedplata) == 'undefined'){
      errors.push({field: 'przedplata', msg: 'Proszę wybrać czas przechowywania.'});
      dispatch(checkErrors(errors));
      return;
    }
    new Api(`/api.php?action=UmowaS3Edit${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post(data)
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            dispatch(receivePrices());
          }else{
            const errors = [];
            json.ERRORS.ERROR_USERINFO.map(er => {
              for(let key in er){
                errors.push({
                  field: key,
                  msg: er[key],
                });
              }
            });
            dispatch(checkErrors(errors));
          }
          
        });
      }
      dispatch(hideLoader());
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}

export function checkOrderStep3(data, cb){
  return (dispatch) => {
    dispatch(clearOrderErrors());
    
    const errors = [];
    if(data.przedplata === null || typeof(data.przedplata) == 'undefined'){
      errors.push({field: 'przedplata', msg: 'Proszę wybrać czas przechowywania.'});
      dispatch(checkErrors(errors));
      dispatch(hideLoader());
      return;
    }
    new Api(`/api.php?action=UmowaS3Edit${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post(data)
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            cb && cb();
          }else{
            const errors = [];
            json.ERRORS.ERROR_USERINFO.map(er => {
              for(let key in er){
                errors.push({
                  field: key,
                  msg: er[key],
                });
              }
            });
            dispatch(checkErrors(errors));
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}