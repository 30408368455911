import React, { Component, Fragment } from 'react';
import { DividerHorizontal } from './Styles';
import Radio from './index';

class RadioListComponent extends Component{
  constructor(props){
    super(props);
    this.handleValueChange = this.handleValueChange.bind(this);
  }

  handleValueChange(value, name){
    this.props.setSelected(value, name);
  }

  render(){
    const children = () => {
      return this.props.children && this.props.children.map((child, index) => {
        const divider = index + 1 < this.props.children.length ? (<DividerHorizontal />) : '';
        return (
          <Fragment key={child.props.id}>
            <Radio {...child.props} selectedValue={this.props.selectedValue} changeSelectedValue={this.handleValueChange} />
            { divider }
          </Fragment>
        );
      })
    }
    return(
      <Fragment>
        { children() }
      </Fragment>
    )
  }
}

export default RadioListComponent;