import { connect } from 'react-redux';
import Storage from './../../screens/Storage';
import { clearAllErrors, receiveOrderStep3, setOrderStep3, sendOrderStep3, checkOrderStep3} from './../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    orderStep1: state.get('orderStep1'),
    orderStep3: state.get('orderStep3'),
    selectedAdditionalPackages: state.get('selectedAdditionalPackages'),
    selectedPromotions: state.get('selectedPromotions'),
    orderErrors: state.get('orderErrors'),
    prices: state.get('prices'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    receiveOrderStep3: () => {
      dispatch(receiveOrderStep3());
    },
    setOrderStep3: (value, name) => {
      const payload = {
        field: name,
        value: value,
      }
      dispatch(setOrderStep3(payload));
    },
    sendOrderStep3: (data) => {
      dispatch(sendOrderStep3(data));
    },
    checkCurrentStep: (data, cb) => {
      dispatch(checkOrderStep3(data, cb));
    },
    clearAllErrors: () => {
      dispatch(clearAllErrors());
    }
  }
}

const OrderStep3Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(Storage);

export default OrderStep3Container;
