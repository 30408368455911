import { connect } from 'react-redux';
import SignUpScreen from './../../screens/SignUp';
import {  clearAllErrors, registerUser } from './../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    errors: state.get('loggedErrors'),
    logged: state.get('registered'),
    registrationData: state.get('registrationData'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    registerUser: (data) => {
      dispatch(registerUser(data));
    },
    clearAllErrors: () => {
      dispatch(clearAllErrors());
    }
  }
}

const SignUpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpScreen);

export default SignUpContainer;
