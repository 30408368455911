export const orderStep2 = (state = {}, action) => {
  switch(action.type){
    case 'SET_ORDER_STEP2': {
      return {...state, [action.payload.field]: {
        ...state[action.payload.field],
        value: action.payload.value
      }};
    }
    case 'INIT_ORDER_STEP2':
      return action.payload;
    default:
      return state;
  }
}