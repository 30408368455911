import styled from 'styled-components';
import newContract from './new.svg';
import contact from './contact.svg';
import contract from './contract.svg';
import {Header2} from './../../../components/Styled';
import {media} from './../../../components/Styled/helpers';
import {ButtonLabel, ButtonIcon, ButtonBox} from './../../../components/Button/Styles';

const SignInPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  ${Header2}{
    text-align: center;
    margin-top: 102px;
    margin-bottom: 144px;
    width: 100%;
    ${media.mobile`
      margin: 56px 0;
    `}
  }
  a{
    text-decoration: none;
  }
`;


const PanelMain = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  ${media.mobile`
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: calc(40px + 20vh);
  `}
`;

const PanelNavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${media.mobile`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 40px;
  `}
  &:hover ${ButtonLabel}{
    opacity: 0;
  }
  &:hover ${ButtonIcon}{
    right: calc(50% - 28px);
  }
  ${ButtonLabel}{
    ${media.mobile`
      padding: 0 !important;
    `}
  }
  ${ButtonBox}{
    ${media.mobile`
      padding: 0!important;
    `}
  }
  ${ButtonIcon}{
    ${media.mobile`
      background-color: transparent;
      width: 24px;
      height: 24px;
      background-size: 16px;
      margin: 0;
      right: -24px;
    `}
  }
`;  

const PanelNavText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  color: ${props => props.theme.color.black};
  ${media.mobile`
    align-items: flex-start;
    justify-content: center;
    margin-right: 24px;
  `}
`

const PanelNavIcon = styled.div`
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: ${props => {
    if(props.new){
      return props.theme.color.veryLightPink;
    }else if(props.contact){
      return props.theme.color.duckEggBlue;
    }else{
      return props.theme.color.beige;
    }
  }};
  background-image: ${props => {
    if(props.new){
      return `url(${newContract})`;
    }else if(props.contact){
      return `url(${contact})`;
    }else {
      return `url(${contract})`
    }
  }};
  background-size: 48px;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 16px;
  ${media.mobile`
    width: 56px;
    height: 56px;
    min-width: 56px;
    background-size: 24px;
    margin-right: 24px;
    margin-bottom: 0;
  `}
`;

export {SignInPage, PanelMain, PanelNavItem, PanelNavIcon, PanelNavText}