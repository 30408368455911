import styled from 'styled-components';
import {ButtonBox} from './../../Button/Styles';
import {grid, media} from './../../Styled/helpers';

const SignUpBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: ${grid(4)};
  padding: 0 ${grid(1)};
  ${ButtonBox}{
    margin-top: 32px;
    ${media.mobile`
      margin-top: 8px;
    `}
  }
  ${media.mobile`
    width: 100%; 
    padding: 0;
    padding-bottom: 48px;
    margin-bottom: 48px;
  `}
`;

export {SignUpBox};