import React from 'react';
import {LoaderBox, LoaderIcon} from './Styles';

const LoaderComponent = ({ visible }) => {
  return (
    <LoaderBox visible={visible}>
      <LoaderIcon>
        <div></div>
        <div></div>
      </LoaderIcon>
    </LoaderBox>
  )
}

export default LoaderComponent;