import React from 'react';
import { Header5, SmallText, SmallBoldText } from '../Styled';
import { SummaryListItemBox, SummaryData} from './Styles';

const SummaryListItemComponent = ({selected, description, index}) => {
  const getOptionPrice = () => {
    const optionPrice = selected.reduce((sum, selected) => {
      return sum + parseInt(selected.price);
    }, 0);
    
    return !isNaN(optionPrice) ? `${optionPrice} zł` : '';
  }

  const itemPrice = (item) => {
    const price2 = item.price2 || '';
    if(item.price){
      return ` - ${parseInt(item.price)} zł ${price2}`;
    }
  }

  const getOptionSelected = () => {
    return selected.map((item, index) => {
      return (item.name && item.name.length) ? (<SmallBoldText key={index}>Wybrano: {item.name}<span style={{whiteSpace: 'nowrap'}}>{ itemPrice(item) }</span></SmallBoldText>) : '';
    })
  }

  const name = () => {
    if(index === 0) return "Opłata wstępna (przed porodem)";
    if(index === 1) return "Opłata właściwa (po porodzie)";
    if(index === 2) return "Opłata za usługi dodatkowe (po porodzie)";
    if(index === 3) return "Opłata za przechowywanie (po porodzie)";
  }

  return (
    <SummaryListItemBox>
      <SummaryData>
        <Header5>{name()}</Header5>
        {getOptionSelected()}
        <SmallText>
          {description}
        </SmallText>
      </SummaryData>
      <Header5><span style={{whiteSpace: 'nowrap'}}>{getOptionPrice()}</span></Header5>
    </SummaryListItemBox>
  )
}

export default SummaryListItemComponent;