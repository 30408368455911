export const selectedAdditionalPacks = (state = [], action) => {
  switch(action.type){
    case 'ADD_SELECTED_ADDITIONAL_PACK':{
      const packs = [...state];
      const exist = packs.filter(pack => {
        return pack == action.payload;
      });
      if(exist.length === 0){
        packs.push(action.payload);
      }
      return packs; 
    }
    case 'REMOVE_SELECTED_ADDITIONAL_PACK':{
      const packs = [...state];
      return packs.filter(pack => {
        return pack !== action.payload;
      });
    }
    case 'CLEAR_SELECTED_ADDITIONAL_PACK':
      return [];
    default:
      return state;
  }
}