import React, {Component} from 'react';
import LogoComponent from '../../components/Logo';
import {FormContent} from './../../components/Styled/Content';
import {Redirect} from 'react-router-dom';
import {AddressPage, ErrorBox, FormSection} from './Styles';
import HeadlineComponent from '../../components/Headline';
import ButtonComponent from './../../components/Button';
import StepsComponent from '../../components/Steps';
import InputComponent from '../../components/Input';
import FormHeadlineComponent from '../../components/FormHeadline';
import {Header5, SmallText} from './../../components/Styled';
import SummaryContainer from '../../containers/summary';
import CheckboxComponent from "../../components/Checkbox";
import {CheckboxDivider} from "../../components/Checkbox/Styles";

const ADDRESS_NUMBER = {
  ['adr_id_matka_meldunek']: 1,
  ['adr_id_matka_koresp']: 2,
  ['adr_id_ojciec_meldunek']: 3,
  ['adr_id_ojciec_koresp']: 4,
};

const ADDRESS_VALUES = {
  1: 'adr_id_matka_koresp',
  2: 'adr_id_ojciec_koresp',
  3: 'adr_id_matka_meldunek',
  4: 'adr_id_ojciec_meldunek',
};

class Address extends Component{
  constructor(props){
    super(props);
    this.state = {
      nextStep: false,
      copyAddressFrom: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.getValue = this.getValue.bind(this);
    this.getError = this.getError.bind(this);
    this.updateAddressData = this.updateAddressData.bind(this);
  }
  
  componentDidMount(){
    this.props.clearAllErrors();
    this.props.receiveAddressData();
    window.scrollTo(0, 0);
  }

  copyAddress(data) {
    const field = data.field === 'adr_ojciec_koresp' ? 'adr_id_ojciec_koresp' : 'adr_id_matka_koresp';
    const source = this.props.addressData[ADDRESS_VALUES[data.value]].fields;
    const result = {...this.props.addressData[field].fields};
    for(let i = 1; i < 6; i++) {
      result[String(i)].value = source[String(i)].value;
    }
  }

  handleChange(data){
    if(data.field.includes('adr_id_ojciec_koresp')){
      this.props.setAddressData({
        field: 'adr_ojciec_koresp',
        value: '',
      })
    }
    if(data.field.includes('adr_id_matka_koresp')){
      this.props.setAddressData({
        field: 'adr_matka_koresp',
        value: '',
      })
    }
    if(['adr_ojciec_koresp', 'adr_matka_koresp'].includes(data.field)){
      this.copyAddress(data);
    }
    let newData = data;
    if(data.field.indexOf('.') >= 0){
      const fields = data.field.split('.');
      newData = {
        parent: fields[0],
        field: fields[1],
        value: data.value,
      }
    }
    this.props.setAddressData(newData);
    this.props.clearAddressErrors();
    this.props.clearAddressSuccess();
  }

  getField(field, parent) {
    const fields = this.props.addressData[parent].fields;
    return fields[field] || null;
  }

  getLabel(key, parent = null){
    if(!parent){
       return this.props.addressData && this.props.addressData[key] ? this.props.addressData[key].label : '';
    }
    if(this.props.addressData && this.props.addressData[parent]){
      const filtered = this.getField(key, parent);
      return filtered && filtered.label || '';
    }
    return '';
  }

  getHeadline(key, parent = null){
    if(!parent){
       return this.props.addressData && this.props.addressData[key] ? this.props.addressData[key].apigrp1 : '';
    }
    if(this.props.addressData && this.props.addressData[parent]){
      const filtered = this.getField(key, parent);
      return filtered && filtered.apigrp1 || '';
    }
    return '';
  }

  getGroupName(key, parent = null){
    if(!parent){
       return this.props.addressData && this.props.addressData[key] ? this.props.addressData[key].apigrp2 : '';
    }
    if(this.props.addressData && this.props.addressData[parent]){
      const filtered = this.getField(key, parent);
      return filtered && filtered.apigrp2 || '';
    }
    return '';
  }

  getValue(key, parent = null){
    if(!parent){
       return this.props.addressData && this.props.addressData[key] && this.props.addressData[key].value ? String(this.props.addressData[key].value) : '';
    }
    if(this.props.addressData && this.props.addressData[parent]){
      const filtered = this.getField(key, parent);
      return filtered && filtered.value && String(filtered.value) || '';
    }
    return '';
  }

  getValueContact(key) {

    const parent = this.props.addressData.adr_id_umowa_koresp.value ? ADDRESS_VALUES[this.props.addressData.adr_id_umowa_koresp.value] : null;
    if (parent === null) return '';
    if(this.props.addressData && this.props.addressData[parent]){
      const filtered = this.getField(key, parent);
      return filtered && filtered.value || '';
    }
    return '';
  }

  getError(key, parent = null){
    if(!parent){
       return this.props.addressData && this.props.addressData[key] ? this.props.addressData[key].error : '';
    }
    if(this.props.addressData && this.props.addressData[parent]){

      const filtered = this.getField(key, parent);
      return filtered && filtered.error || '';
    }
    return '';
  }
  
  getRequire(key, parent = null){
    if(!parent){
      return this.props.addressData && this.props.addressData[key] && (this.props.addressData[key].minlength > 0 || this.props.addressData[key].min > 0) ? true : false;
    }
    if(this.props.addressData && this.props.addressData[parent]){
      const filtered = this.getField(key, parent);
      return filtered && (filtered.minlength > 0 || filtered.min > 0);
    }
    return false;
  }

  get redirector(){
    if(typeof(this.props.orderStep1.rodzaj_ciazy) == 'undefined'){
      return (
        <Redirect to='/pregnant' />
      )
    }
    return '';
  }

  updateAddressData(){
    const data = {};
    const {addressData, updateAddressData: updateAddressData1} = this.props;

    for(let key in addressData){
      if(addressData.hasOwnProperty(key)){
        data[key] = addressData[key].value;
        if(typeof(addressData[key].fields) != 'undefined'){
          const address_number = ADDRESS_NUMBER[key];
          for(let k in addressData[key].fields){
            const {label, value} = addressData[key].fields[k];
            data[`adr_${address_number}_${label}`] = value;
          }
        }
      }
    }
    updateAddressData1(data, () => {
      this.setState({nextStep: true});
    });
  }

  get renderNextStep(){
    return this.state.nextStep && (
      <Redirect to='/medical_interview' />
    )
  }

  get error(){
    return this.props.orderErrors && this.props.orderErrors.map((error, i) => {
      return <ErrorBox key={i}>{ error.msg }</ErrorBox>;
    })
  }

  render(){
    return(
      <AddressPage className="content">
        <FormContent>
          <LogoComponent />
          <StepsComponent active="4" />

          <HeadlineComponent>Wypełnij dane adresowe</HeadlineComponent>
          <FormSection>
            <FormHeadlineComponent mother label={ this.getHeadline('fname') } />
            <Header5>
              { this.getGroupName('fname') }
              <SmallText fullline>W przypadku braku adresu zameldowania prosimy o wpisanie adresu stałego pobytu.</SmallText>
            </Header5>
            <InputComponent changeHandler={this.handleChange} id="fname" required={this.getRequire('fname')} error={this.getError('fname')} label={ this.getLabel('fname')} value={this.getValue('fname') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="lname" required={this.getRequire('lname')} error={this.getError('lname')} label={ this.getLabel('lname')} value={this.getValue('lname') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="PESEL" required={this.getRequire('PESEL')} error={this.getError('PESEL')} label={ this.getLabel('PESEL')} value={this.getValue('PESEL') || ''} type="text" />
          </FormSection>
          <FormSection>
            <Header5>{ this.getLabel('adr_id_matka_meldunek') }</Header5>
            <InputComponent changeHandler={this.handleChange} id="adr_id_matka_meldunek.2" required={this.getRequire('2', 'adr_id_matka_meldunek')} error={this.getError('2', 'adr_id_matka_meldunek')} label={ this.getLabel('2', 'adr_id_matka_meldunek')} value={this.getValue('2', 'adr_id_matka_meldunek') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_matka_meldunek.1" required={this.getRequire('1', 'adr_id_matka_meldunek')} error={this.getError('1', 'adr_id_matka_meldunek')} label={ this.getLabel('1', 'adr_id_matka_meldunek')} value={this.getValue('1', 'adr_id_matka_meldunek') || ''} type="text" valueType="postalCode" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_matka_meldunek.3" required={this.getRequire('3', 'adr_id_matka_meldunek')} error={this.getError('3', 'adr_id_matka_meldunek')} label={ this.getLabel('3', 'adr_id_matka_meldunek')} value={this.getValue('3', 'adr_id_matka_meldunek') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_matka_meldunek.4" required={this.getRequire('4', 'adr_id_matka_meldunek')} error={this.getError('4', 'adr_id_matka_meldunek')} label={ this.getLabel('4', 'adr_id_matka_meldunek')} value={this.getValue('4', 'adr_id_matka_meldunek') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_matka_meldunek.5" required={this.getRequire('5', 'adr_id_matka_meldunek')} error={this.getError('5', 'adr_id_matka_meldunek')} label={ this.getLabel('5', 'adr_id_matka_meldunek')} value={this.getValue('5', 'adr_id_matka_meldunek') || ''} type="text" />
          </FormSection>
          <FormSection>
            <Header5>{ this.getGroupName('telefon') }</Header5>
            <InputComponent changeHandler={this.handleChange} id="telefon" required={this.getRequire('telefon')} error={this.getError('telefon')} label={ this.getLabel('telefon')} value={this.getValue('telefon') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="email" required={this.getRequire('email')} error={this.getError('email')} label={ this.getLabel('email')} value={this.getValue('email') || ''} type="mail" />
          </FormSection>
          <FormSection>
            <Header5>{ this.getLabel('adr_id_matka_koresp') }</Header5>
            <CheckboxComponent oneOfRequired error={this.getError('adr_matka_koresp')} withoutMargins isChecked={this.getValue('adr_matka_koresp') === '3'} addChecked={() => this.handleChange({field: 'adr_matka_koresp', value: '3'})} removeChecked={() => {}} option={3} label="Taki sam jak adres zameldowania Matki" name={`checkbox-address-mother`}  />
            <CheckboxDivider/>
            <InputComponent changeHandler={this.handleChange} id="adr_id_matka_koresp.2" required={this.getRequire('2', 'adr_id_matka_koresp')} error={this.getError('2', 'adr_id_matka_koresp')} label={ this.getLabel('2', 'adr_id_matka_koresp')} value={this.getValue('2', 'adr_id_matka_koresp') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_matka_koresp.1" required={this.getRequire('1', 'adr_id_matka_koresp')} error={this.getError('1', 'adr_id_matka_koresp')} label={ this.getLabel('1', 'adr_id_matka_koresp')} value={this.getValue('1', 'adr_id_matka_koresp') || ''} type="text" valueType="postalCode" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_matka_koresp.3" required={this.getRequire('3', 'adr_id_matka_koresp')} error={this.getError('3', 'adr_id_matka_koresp')} label={ this.getLabel('3', 'adr_id_matka_koresp')} value={this.getValue('3', 'adr_id_matka_koresp') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_matka_koresp.4" required={this.getRequire('4', 'adr_id_matka_koresp')} error={this.getError('4', 'adr_id_matka_koresp')} label={ this.getLabel('4', 'adr_id_matka_koresp')} value={this.getValue('4', 'adr_id_matka_koresp') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_matka_koresp.5" required={this.getRequire('5', 'adr_id_matka_koresp')} error={this.getError('5', 'adr_id_matka_koresp')} label={ this.getLabel('5', 'adr_id_matka_koresp')} value={this.getValue('5', 'adr_id_matka_koresp') || ''} type="text" />
          </FormSection>

          <FormSection>
            <FormHeadlineComponent father label={ this.getHeadline('fname_ojca') } />
            <Header5>
              { this.getGroupName('fname_ojca') }
              <SmallText fullline>W przypadku braku adresu zameldowania prosimy o wpisanie adresu stałego pobytu.</SmallText>
            </Header5>
            <InputComponent changeHandler={this.handleChange} id="fname_ojca" required={this.getRequire('fname_ojca')} error={this.getError('fname_ojca')} label={ this.getLabel('fname_ojca')} value={this.getValue('fname_ojca') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="lname_ojca" required={this.getRequire('lname_ojca')} error={this.getError('lname_ojca')} label={ this.getLabel('lname_ojca')} value={this.getValue('lname_ojca') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="PESEL_ojca" required={this.getRequire('PESEL_ojca')} error={this.getError('PESEL_ojca')} label={ this.getLabel('PESEL_ojca')} value={this.getValue('PESEL_ojca') || ''} type="text" />
          </FormSection>
          <FormSection>
            <Header5>{ this.getLabel('adr_id_ojciec_meldunek') }</Header5>
            <InputComponent changeHandler={this.handleChange} id="adr_id_ojciec_meldunek.2" required={this.getRequire('2', 'adr_id_ojciec_meldunek')} error={this.getError('2', 'adr_id_ojciec_meldunek')} label={ this.getLabel('2', 'adr_id_ojciec_meldunek')} value={this.getValue('2', 'adr_id_ojciec_meldunek') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_ojciec_meldunek.1" required={this.getRequire('1', 'adr_id_ojciec_meldunek')} error={this.getError('1', 'adr_id_ojciec_meldunek')} label={ this.getLabel('1', 'adr_id_ojciec_meldunek')} value={this.getValue('1', 'adr_id_ojciec_meldunek') || ''} type="text" valueType="postalCode" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_ojciec_meldunek.3" required={this.getRequire('3', 'adr_id_ojciec_meldunek')} error={this.getError('3', 'adr_id_ojciec_meldunek')} label={ this.getLabel('3', 'adr_id_ojciec_meldunek')} value={this.getValue('3', 'adr_id_ojciec_meldunek') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_ojciec_meldunek.4" required={this.getRequire('4', 'adr_id_ojciec_meldunek')} error={this.getError('4', 'adr_id_ojciec_meldunek')} label={ this.getLabel('4', 'adr_id_ojciec_meldunek')} value={this.getValue('4', 'adr_id_ojciec_meldunek') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_ojciec_meldunek.5" required={this.getRequire('5', 'adr_id_ojciec_meldunek')} error={this.getError('5', 'adr_id_ojciec_meldunek')} label={ this.getLabel('5', 'adr_id_ojciec_meldunek')} value={this.getValue('5', 'adr_id_ojciec_meldunek') || ''} type="text" />
          </FormSection>
          <FormSection>
            <Header5>{ this.getGroupName('email_ojca') }</Header5>
            <InputComponent changeHandler={this.handleChange} id="telefon_ojca" required={this.getRequire('telefon_ojca')} error={this.getError('telefon_ojca')} label={ this.getLabel('telefon_ojca')} value={this.getValue('telefon_ojca') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="email_ojca" required={this.getRequire('email_ojca')} error={this.getError('email_ojca')} label={ this.getLabel('email_ojca')} value={this.getValue('email_ojca') || ''} type="mail" />
           </FormSection>
          <FormSection>
            <Header5>{ this.getLabel('adr_id_ojciec_koresp') }</Header5>
            <CheckboxComponent oneOfRequired error={this.getError('adr_ojciec_koresp')} withoutMargins isChecked={this.getValue('adr_ojciec_koresp') === '4'} addChecked={() => this.handleChange({field: 'adr_ojciec_koresp', value: '4'})} removeChecked={() => {}} option={4}  label="Taki sam jak adres zameldowania Ojca" name={`checkbox-address-father`}  />
            <CheckboxComponent oneOfRequired error={this.getError('adr_ojciec_koresp')} withoutMargins isChecked={this.getValue('adr_ojciec_koresp') === '1'} addChecked={() => this.handleChange({field: 'adr_ojciec_koresp', value: '1'})} removeChecked={() => {}} option={1} label="Taki sam jak adres korespondencyjny Matki" name={`checkbox-address-mother`}  />
            <CheckboxDivider/>
            <InputComponent changeHandler={this.handleChange} id="adr_id_ojciec_koresp.2" required={this.getRequire('2', 'adr_id_ojciec_koresp')} error={this.getError('2', 'adr_id_ojciec_koresp')} label={ this.getLabel('2', 'adr_id_ojciec_koresp')}value={this.getValue('2', 'adr_id_ojciec_koresp') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_ojciec_koresp.1" required={this.getRequire('1', 'adr_id_ojciec_koresp')} error={this.getError('1', 'adr_id_ojciec_koresp')} label={ this.getLabel('1', 'adr_id_ojciec_koresp')} value={this.getValue('1', 'adr_id_ojciec_koresp') || ''} type="text" valueType="postalCode" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_ojciec_koresp.3" required={this.getRequire('3', 'adr_id_ojciec_koresp')} error={this.getError('3', 'adr_id_ojciec_koresp')} label={ this.getLabel('3', 'adr_id_ojciec_koresp')} value={this.getValue('3', 'adr_id_ojciec_koresp') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_ojciec_koresp.4" required={this.getRequire('4', 'adr_id_ojciec_koresp')} error={this.getError('4', 'adr_id_ojciec_koresp')} label={ this.getLabel('4', 'adr_id_ojciec_koresp')} value={this.getValue('4', 'adr_id_ojciec_koresp') || ''} type="text" />
            <InputComponent changeHandler={this.handleChange} id="adr_id_ojciec_koresp.5" required={this.getRequire('5', 'adr_id_ojciec_koresp')} error={this.getError('5', 'adr_id_ojciec_koresp')} label={ this.getLabel('5', 'adr_id_ojciec_koresp')} value={this.getValue('5', 'adr_id_ojciec_koresp') || ''} type="text" />
          </FormSection>

          <FormSection>
            <FormHeadlineComponent address label={this.getLabel('adr_id_umowa_koresp')} />
            <Header5>
              Aktualny adres korespondencyjny do umowy (adres, na który Diagnostyka Bank Komórek Macierzystych wysyła dokumentacje/informacje związane z umową) jest to adres korespondencyjny Matki bądź Ojca. Przesłanie dokumentacji na wskazany adres korespondencyjny do umowy będzie oznaczało dostarczenie informacji do obojga rodziców.
              {/* <SmallText fullline>W przypadku braku adresu zameldowania prosimy o wpisanie adresu stałego pobytu.</SmallText> */}
            </Header5>
            <CheckboxDivider/>
            <CheckboxComponent oneOfRequired error={this.getError('adr_id_umowa_koresp')} withoutMargins isChecked={this.getValue('adr_id_umowa_koresp') === '1'} addChecked={() => this.handleChange({field: 'adr_id_umowa_koresp', value: 1})} removeChecked={() => {}} option={1} label={this.props.addressData.adr_id_umowa_koresp && this.props.addressData.adr_id_umowa_koresp.MAP[1] || ''} name={`checkbox-address-mother`}  />
            <CheckboxComponent oneOfRequired error={this.getError('adr_id_umowa_koresp')} withoutMargins isChecked={this.getValue('adr_id_umowa_koresp') === '2'} addChecked={() => this.handleChange({field: 'adr_id_umowa_koresp', value: 2})} removeChecked={() => {}} option={2}  label={this.props.addressData.adr_id_umowa_koresp && this.props.addressData.adr_id_umowa_koresp.MAP[2] || ''}name={`checkbox-address-father`}  />
            <CheckboxDivider/>
            <InputComponent disabled label={ this.getLabel('2', 'adr_id_ojciec_koresp')} value={this.getValueContact('2') || ''} type="text" />
            <InputComponent disabled label={ this.getLabel('1', 'adr_id_ojciec_koresp')} value={this.getValueContact('1') || ''} type="text" valueType="postalCode" />
            <InputComponent disabled label={ this.getLabel('3', 'adr_id_ojciec_koresp')} value={this.getValueContact('3') || ''} type="text" />
            <InputComponent disabled label={ this.getLabel('4', 'adr_id_ojciec_koresp')} value={this.getValueContact('4') || ''} type="text" />
            <InputComponent disabled label={ this.getLabel('5', 'adr_id_ojciec_koresp')} value={this.getValueContact('5') || ''} type="text" />
          </FormSection>
          { this.error }
          <ButtonComponent primary label="Przejdź dalej" click={this.updateAddressData} />
        </FormContent>
        <SummaryContainer/>
        { this.redirector }
        { this.renderNextStep }
      </AddressPage>
    )
  }
}

export default Address;