import React, { Component } from 'react';
import LogoComponent from '../../components/Logo';
import { Header2 } from '../../components/Styled';
import { SignInPage, SignInSections, Divider } from './Styles';
import NewPasswordComponent from '../../components/NewPassword';
import ResetComponent from '../../components/ResetPassword';

class NewPassword extends Component{
  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.props.clearAllErrors();
  }

  render(){
    return(
      <SignInPage className="content">
        <LogoComponent />
        <Header2>Wykorzystaj kod tymczasowy, aby utworzyć nowe hasło i zalogować się.</Header2>
        <SignInSections>
          <ResetComponent loginUser={this.props.loginUser} errors={this.props.errors} loggedUser={this.props.legged} />
        </SignInSections>
      </SignInPage>
    )
  }
}

export default NewPassword;