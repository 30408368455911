import styled from 'styled-components';
import { font } from './../../Styled/Fonts';
import {Header5, StrikeText} from './../../Styled';
import { media } from './../../Styled/helpers';

const RadioBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-color: ${props => props.theme.color.black};
  color: ${props => props.theme.color.black};
  opacity: ${props => props.disabled ? .4 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  margin: 24px 0;
  padding: 0 0 0 48px;
  width: calc(100% - 32px);
  ${ media.mobile`
    padding: 0 0 0 24px;
  `}
`;

const RadioFieldBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${Header5}{
    justify-self: flex-end;
    ${StrikeText}{
      padding: 0 8px;
    }
  }
`;

const RadioTitle = styled.h5`
  padding-left:24px;
  transform: translateY(12px);
`;
const RadioDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const RadioDescriptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  ${media.mobile`
    flex-direction: column;
  `}
`;

const RadioPriceDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  width: calc(100% - 24px);
  padding: 0 0 0 24px;
  color: #8c8c8c;
  ${ media.mobile`
    flex-direction: column;
    align-items: flex-start;
  `}
`;

const RadioLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  color: inherit;
  user-select: none;
  ${props => font(props.theme)}
  &:before{
    width: 16px;
    height: 16px;
    min-width: 16px;
    content: '';
    display: flex;
    border-width: 2px;
    border-style: solid;
    border-color: inherit;
    border-radius: 50%;
    box-sizing: border-box;
    margin-right: 8px;
    margin-top: 8px;
  }
  &:after{
    content: '';
    width: 6px;
    height: 6px;
    display: flex;
    position: absolute;
    left: 5px;
    top: 5px;
    border-radius: 50%;
    background-color: ${props => props.theme.color.black};
    opacity: 0;
    transition: opacity .1s ease-in;
    margin-top: 8px;
  }
}
`;

const RadioField = styled.input`
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
  &:checked{
    &+${RadioLabel}{
      &:after{
        opacity: 1;
      }
    }
  }
`;

const DividerHorizontal = styled.span`
  width: 100%;
  height: 1px; 
  background-color: ${props => props.theme.color.white3};
  ${ media.mobile`

  `}
`;

const RadioInputBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export {RadioBox, RadioFieldBox, RadioDescription, RadioDescriptionsWrapper, RadioTitle, RadioLabel, RadioField, DividerHorizontal, RadioInputBox, RadioPriceDescription};