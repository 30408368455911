import { connect } from 'react-redux';
import SignIn from './../../screens/SignIn';
import {  clearAllErrors, loginUser } from './../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    errors: state.get('loggedErrors'),
    logged: state.get('logged'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginUser: (data) => {
      dispatch(loginUser(data));
    },
    clearAllErrors: () => {
      dispatch(clearAllErrors());
    }
  }
}

const SignInContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignIn);

export default SignInContainer;
