import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Headline from './../Headline';
import {SignUpBox} from './Styles';
import ButtonComponent from './../Button';

class SignUpComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return ( 
      <SignUpBox>
        <Headline beige subheadline="Załóż konto i przejdź przez konfigurator później.">Zarejestruj<br /> konto</Headline>
        <Link to='/sign_up'>
          <ButtonComponent extra label="Przejdź dalej" />
        </Link>
      </SignUpBox>
    );
  }
}
 
export default SignUpComponent;