import { connect } from 'react-redux';
import MainScreen from './../../screens/Main';
import {  clearAllErrors, loginUser, clearError } from './../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    logged: state.get('logged'),
    registered: state.get('registered'),
    loggedErrors: state.get('loggedErrors'),
    loader: state.get('loader'),
    error: state.get('systemError'), 
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loginUser: (data) => {
      dispatch(loginUser(data));
    },
    clearAllErrors: () => {
      dispatch(clearAllErrors());
    },
    clearError: () => {
      dispatch(clearError());
    }
  }
}

const MainContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainScreen);

export default MainContainer;
