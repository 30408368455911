import styled from 'styled-components';
import {font, h4} from './../../Styled/Fonts';
import tick from './tick.svg';
import up from './up.svg';

const SelectBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 0px;
  opacity: ${props => props.disabled ? 0.4 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  color: ${props => props.error ? props.theme.color.vermillion : props.theme.color.black };
  border-color: ${props =>  props.error ? props.theme.color.vermillion : props.theme.color.pinkishGrey };
  position: relative;
  margin-top: 16px;
  &>div{
    width: 100%;
  }
  &:after{
    content: '';
    width: 50px;
    height: 50px;
    background-image: url(${up});
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0;
    right: 0;
    transition: transform .3s ease-in-out;
    transform: ${props => props.opened ? 'rotateZ(0deg)' : 'rotateZ(180deg)'};
    pointer-events: none;
  }
`;

const SelectLabel = styled.label`
  width: 100%;
  margin-bottom: 8px;
  color: inherit;
  ${props => h4(props.theme)}
`;

const SelectField = styled.input`
  width: calc(100% - 32px);
  padding: 8px 16px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.pinkishGrey};
  color: inherit;
  outline: none;
  ${props => font(props.theme)}
`;

const SelectList = styled.ul`
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  max-height: ${props => props.opened ? '300px' : '0px'};
  height: auto;
  transition: max-height .3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  overflow-y: auto;
  z-index: 9;
  border: 1px solid;
  border-color: ${props => props.opened ? props.theme.color.pinkishGrey : 'transparent'};
`;

const SelectListItem = styled.li`
  width: calc(100% - 32px);
  padding: 4px 16px;
  text-align: left;
  ${props => font(props.theme)};
  cursor: pointer;
  background-color: ${props => props.theme.color.white};
  position: relative;
  &:hover{
    background-color: ${props => props.theme.color.beige};
  }
  &:after{
    display: ${props => props.selected ? 'flex' : 'none'};
    content: '';
    position: absolute;
    top: 0;
    right: 8px;
    width: 40px;
    height: 40px;
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${tick});
  }
`;

export {SelectBox, SelectField, SelectLabel, SelectList, SelectListItem};