import Api from '../../../utils/api';
import {
  initUserData,
  setUserErrors,
  clearUserErrors,
  setUserSuccess,
  clearUserSuccess,
  setError,
} from '../index';

export function receiveUserData() {
  return (dispatch) => {
    dispatch(clearUserSuccess());
    new Api(`/api.php?action=profil${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            const data = {};
            json.OK.fields.map(field => {
              if(field.type == 'password'){
                field.value = '';
              }
              data[field.name] =  field;
            });
            dispatch(initUserData(data));
          }else{
            dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}

export function updateUserData(data) {
  return (dispatch) => {
  dispatch(clearUserErrors());
  dispatch(clearUserSuccess());
    new Api(`/api.php?action=profiledit${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post(data)
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0 && json.WARNING === 0){
            const data = {};
            json.OK.fields.map(field => {
              data[field.name] =  {
                key: field.name,
                label: field.label,
                value: field.value,
              }
            });
            dispatch(initUserData(data));
            dispatch(setUserSuccess('Zmiany zostały zapisane.'));
          }else{
            const errors = json.ERRORS.ERROR_USERINFO.map(er => {
              return Object.values(er);
            });
            const warnings = json.WARNINGS.WARNING_USERINFO.map(war => {
              return Object.values(war);
            });
            dispatch(setUserErrors(errors.concat(warnings)));
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}
