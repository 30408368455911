import styled from 'styled-components';
import {ButtonBox} from './../../../components/Button/Styles';
import {grid, media} from './../../../components/Styled/helpers';
import { Header4, Header5, Header6, BoldText } from '../../../components/Styled';
import { InputBox } from '../../../components/Input/Styles';
import { CheckboxBox } from '../../../components/Checkbox/Styles';

const AddressPage = styled.div`
   ${ButtonBox}{
    align-self: center;
    justify-content: center;
    margin-top: 56px;
    margin-bottom: 80px;
  }
  
  a{
    text-decoration: none;
    margin: 80px 0 56px; 
    color: ${props => props.theme.color.black}
    ${media.mobile`
      margin: 24px 0 56px;
    `}
  }
  ${BoldText}{
    margin: 16px 0 64px; 
    a{
      color: inherit;
      text-decoration: underline;
    }
  }
  ${CheckboxBox}{
    margin: 8px 0;
    width: 100%;
  }
`;

const ChangePasswordLink = styled.h4`
  color: ${props => props.theme.color.black};
  font-weight: bold;
  width: 100%;
  margin: 24px 0 0;
  text-decoration: underline;
  cursor: 
  pointer;
  ${Header4}
`

const InputPlaceholder = styled.div`
  display: flex;
  width: calc(50% - 8px);

  ${media.mobile`
    width: 100%;
  `}
  
`;

const FormSection = styled.div`
  width: ${grid(8)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: ${props => props.checkboxes ? '72px 0 0' : '0'}
  ${media.mobile`
    width: 100%;
    flex-direction: column;
    margin: 0;
  `}
  ${Header5}{
    width: 100%;
    margin: 40px 0 24px;
  }
  ${InputBox}{
    width: calc(50% - 8px);
    position: relative;
    ${media.mobile`
      width: 100%;
    `}
  }
  ${BoldText}{
    margin-bottom: 0;
    ${media.mobile`
      margin-top: 16px;
    `}
  }
`;

const ErrorBox = styled.span`
  ${ Header6 };
  color: ${props => props.theme.color.vermillion};
  margin-top: 16px;
`;

const SuccessBox = styled.span`
  ${ Header6 };
  color: ${props => props.theme.color.success};
  margin-top: 16px;
`;

export {AddressPage, FormSection, InputPlaceholder, ErrorBox, SuccessBox, ChangePasswordLink};