import styled from 'styled-components';
import {media} from './../../../components/Styled/helpers';
import {Header2} from './../../../components/Styled';

const SignInPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  padding-bottom: 80px;
  ${Header2}{
    text-align: center;
    margin-top: 102px;
    margin-bottom: 144px;
    ${media.mobile`
      margin: 16px 0 48px;
    `}
  }
  a{
    text-decoration: none;
  }
`;

const SignInSections = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  ${ media.mobile`
    flex-direction: column;
    margin-bottom: 48px;
  `}
`;

const Divider = styled.span`
  width: 1px;
  height: calc(100% - 64px);
  background-color: ${props => props.theme.color.white3};
  ${ media.mobile`
    display: none;
  `}
`;

export { SignInPage, SignInSections, Divider};