export const additionalPackageTypes = (state = [], action) => {
  switch(action.type){
    case 'SET_ADDITIONAL_PACKAGES_TYPES':
      return action.payload;
    default:
      return state;
  }
}

export const additionalPackagesTitle = (state = '', action) => {
  switch(action.type){
    case 'SET_ADDITIONAL_PACKAGES_TITLE':
      return action.payload;
    default:
      return state;
  }
}

export const additionalPackagesDescription = (state = '', action) => {
  switch(action.type){
    case 'SET_ADDITIONAL_PACKAGES_DESCRIPTION':
      return action.payload;
    default:
      return state;
  }
}