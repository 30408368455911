export const orderErrors = (state = [], action) => {
  switch(action.type){
    case 'CLEAR_ORDER_ERRORS':
      return [];
    case 'SET_ORDER_ERRORS':
      return action.payload;
    case 'ADD_ORDER_ERRORS':
      return state.concat(action.payload);
    default:
      return state;
  }
}