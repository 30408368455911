import styled from 'styled-components';
import { Header3 } from '../../Styled';
import {font} from '../../Styled/Fonts';

export const Error = styled.h3`
  color: ${props => props.theme.color.red};
`;

export const ErrorModal = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  width: 100%;
  height: 100%;
  opacity: ${props => props.visible ? 1 : 0};
  visibility: ${props => props.visible ? 'visible': 'hidden'};
  transition: all .3s ease;
  pointer-events: ${props => props.visible ? 'all' : 'none'};
  z-index: 99999;
  top: 0;
  left: 0;
  position: fixed;
  align-items: center;
  justify-content: center;
`;

export const ErrorMsg = styled.div`
  background-color: ${props => props.theme.color.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px;
  width: auto;
  max-width: 100%;
  height: auto;
  ${Header3}{
    color: ${props => props.theme.color.black};
    text-align: center;
    margin: 0 auto 24px;
  }
  p{
    color: ${props => props.theme.color.black};
    text-align: center;
    margin: 0 auto 24px;
  ${props => font(props.theme)}
  }
  a{
    color: ${props => props.theme.color.black};
    cursor: pointer;
  }
`