import styled from 'styled-components';
import { grid } from './../../Styled/helpers'; 

const LogoBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    width: ${grid(2)};
    display: block;
    margin: 32px auto;
  }
`;

export {LogoBox};