import Api from '../../../utils/api';
import {
  showLoader,
  hideLoader,
  checkErrors,
  clearOrderErrors,
  setError,
} from './../index';

export function saveContract(cb) {
  return (dispatch) => {
    dispatch(clearOrderErrors());
    dispatch(showLoader());
    new Api(`/api.php?action=UmowaZapisz${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0 && json.WARNING === 0){
            dispatch(clearOrderErrors());
            cb();
          }else{
            const errors = [];
            json.ERRORS.ERROR_USERINFO.map(er => {
              for(let key in er){
                errors.push({
                  field: key,
                  msg: er[key],
                });
              }
            });
            dispatch(checkErrors(errors));
            dispatch(hideLoader());
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
      dispatch(hideLoader());
    });
  };
}
