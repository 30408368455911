const dbkm = {
  fontHeader: "'Montserrat', sans-serif",
  fontBody: "'Roboto Condensed', sans-serif",
  color: {
    veryLightPink: '#fbe0e0',
    white: '#ffffff',
    white2: '#f2f2f2',
    white3: '#e8e8e8',
    border: '#e3e3e3',
    duckEggBlue: '#e7effa',
    pinkishGrey: '#c8c0b5',
    pinkishGrey2: '#cccccc',
    dustyLavender: '#a77da3',
    beige: '#f1e5dd',
    greyish: '#ababab',
    vermillion: '#f31a1a',
    black: '#000000',
    success: '#1af31a',
  },
  rwd: {
    desktop: "screen AND (min-width: 1440px)",
    tablet: "screen AND (max-width: 1024px)",
    mobile: 'screen AND (max-width: 640px)',
  }
}

export {dbkm};