import styled from 'styled-components';
import {ButtonBox} from './../../Button/Styles';
import {grid, media} from './../../Styled/helpers';
import { Header6 } from './../../Styled';
import {LinkBox} from './../../Link/Styles';

const SignInBox = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: ${grid(4)};
  padding: 0 ${grid(1)};
  ${ButtonBox}{
    margin-top: 56px;
  }
  ${media.mobile`
    width: 100%; 
    padding: 0 0 48px;
    margin-bottom: 48px;
    border-bottom: 1px solid ${props => props.theme.color.border}
  `}
  ${LinkBox}{
    margin-top: 24px;
  }
`;

const ErrorBox = styled.span`
  ${ Header6 };
  color: ${props => props.theme.color.vermillion};
  margin-top: 16px;
`;

export {SignInBox, ErrorBox};