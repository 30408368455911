import Api from '../../../utils/api';
import {
  initOrderStep1,
  hideLoader,
  receiveOrderStep2,
  receiveOrderStep3,
  receiveAdditionalPackages,
  receivePrices,
  receivePromotions,
  checkErrors,
  clearOrderErrors,
  setError,
  receiveServices
} from '../index';

export function initOrder(){
  return dispatch => {
    new Api(`/api.php?action=UmowaStart${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            dispatch(receiveOrderStep1(true));
          }else{
            console.error(json);
            dispatch(hideLoader());
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
      dispatch(hideLoader());
    });
  }
}

export function receiveOrderStep1(inducedFromInit = false) {
  return (dispatch) => {
    new Api(`/api.php?action=UmowaS1Get${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            const data = {};
            json.OK.map(field => {
              data[field.name] =  field;
            });
            dispatch(initOrderStep1(data));
            dispatch(receivePrices());
            dispatch(receiveOrderStep2());
            dispatch(receivePromotions());
            dispatch(receiveAdditionalPackages());
            dispatch(receiveOrderStep3());
            dispatch(receiveServices());
          }else if(json.ERROR === 1 && !inducedFromInit){
            dispatch(initOrder());
          }else{
            dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
            dispatch(hideLoader());
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
      dispatch(hideLoader());
    });
  };
}

export function sendOrderStep1(data){
  return (dispatch) => {
    if(data.rodzaj_ciazy === null || typeof(data.rodzaj_ciazy) == 'undefined'){
      const errors = [{field: 'rodzaj_ciazy', msg: 'Proszę wybrać rodzaj ciąży'}];
      dispatch(checkErrors(errors));
      dispatch(hideLoader());
      return;
    }
    dispatch(clearOrderErrors());
    new Api(`/api.php?action=UmowaS1Edit${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post(data)
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          const errors = [];
          json.ERRORS.ERROR_USERINFO.map(er => {
            for(let key in er){
              errors.push({
                field: key,
                msg: er[key],
              });
            }
          });
          dispatch(checkErrors(errors));
          
          dispatch(receivePrices());
          dispatch(hideLoader());
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
      dispatch(hideLoader());
    });
  };
}

export function checkOrderStep1(data, cb){
  return (dispatch) => {
    dispatch(clearOrderErrors());
    if(data.rodzaj_ciazy === null || typeof(data.rodzaj_ciazy) == 'undefined'){
      const errors = [{field: 'rodzaj_ciazy', msg: 'Proszę wybrać rodzaj ciąży'}];
      dispatch(checkErrors(errors));
      dispatch(hideLoader());
      return;
    }
    new Api(`/api.php?action=UmowaS1Edit${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post(data)
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            cb && cb();
          }else{
            const errors = [];
            json.ERRORS.ERROR_USERINFO.map(er => {
              for(let key in er){
                errors.push({
                  field: key,
                  msg: er[key],
                });
              }
            });
            dispatch(checkErrors(errors));
            dispatch(hideLoader());
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
      dispatch(hideLoader());
    });
  };
}