import { connect } from 'react-redux';
import SummaryComponent from './../../components/Summary';

const mapStateToProps = (state, ownProps) => {
  return {
    selectedAdditionalPackages: state.get('selectedAdditionalPackages'),
    selectedPromotions: state.get('selecterPromotions'),
    orderStep1: state.get('orderStep1'),
    orderStep2: state.get('orderStep2'),
    orderStep3: state.get('orderStep3'),
    services: state.get('services'),
    prices: state.get('prices'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {}
}

const SummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryComponent);

export default SummaryContainer;