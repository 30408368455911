import { connect } from 'react-redux';
import MedicalInterview from './../../screens/MedicalInterview';
import {  clearAllErrors, receiveOrderStep5, setOrderStep5, receiveOrderStep5Questions, setOrderStep5Questions, receiveKnownFrom, receiveHospitals, updateOrderStep5, updateOrderStep5Questions } from './../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    orderStep1: state.get('orderStep1'),
    orderStep5: state.get('orderStep5'),
    orderStep5Questions: state.get('orderStep5Questions'),
    hospitals: state.get('hospitals'),
    orderErrors: state.get('orderErrors'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    receiveOrderStep5: () => {
      dispatch(receiveOrderStep5());
      dispatch(receiveOrderStep5Questions());
      dispatch(receiveHospitals());
      dispatch(receiveKnownFrom());
    },
    receiveHospitals: () => {
      dispatch(receiveHospitals());
    },
    setOrderStep5: (value) => {
      dispatch(setOrderStep5(value));
    },
    updateOrderStep5: (data, cb) => {
      dispatch(updateOrderStep5(data, cb));
    },
    setOrderStep5Questions: (value, field) => {
      const payload = {
        field: field,
        value: value,
      }
      dispatch(setOrderStep5Questions(payload));
    },
    updateOrderStep5Questions: (data, cb) => {
      dispatch(updateOrderStep5Questions(data, cb));
    },
    clearAllErrors: () => {
      dispatch(clearAllErrors());
    }
  }
}

const OrderStep5Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalInterview);

export default OrderStep5Container;