import Api from '../../../utils/api';
import {
  initKnownFrom,
  setError,
} from '../index';

export function receiveKnownFrom() {
  return (dispatch) => {
    new Api(`/api.php?action=slownik${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({"slownik":"DOWIEDZIELI_FROM_MAP","maska":""})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            const data = [];
            for(let key in json.DOWIEDZIELI_FROM){
              const item = {
                id: key,
                value: key,
                label: json.DOWIEDZIELI_FROM[key]
              }
              data.push(item);
            }
            dispatch(initKnownFrom(data));
          }else{
            console.error(json);
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
      console.log(error.msg);
    });
  };
}

