import styled from 'styled-components';
import {h5} from './../../Styled/Fonts';
import buttonIcon from './button-icon-black.svg';

const ButtonLabel = styled.span`
  color: inherit;
  padding: 16px 66px 16px 32px;
  text-transform: uppercase;
  opacity: 1;
  transition: opacity .3s ease-in-out;
  user-select: none;
  text-decoration: none;
  ${props => h5(props.theme)};
`;

const ButtonIcon = styled.span`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.white};
  background-image: url(${buttonIcon});
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  position: absolute;
  right: 0;
  margin: 6px;
  text-decoration: none;
  transition: right .3s ease-in-out;
`;

const ButtonBox = styled.button`
  border-radius: 28px;
  border: 0;
  outline: 0;
  background-image: none;
  display: flex;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  opacity: ${props => props.disabled ? .4 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  &:hover ${ButtonLabel}{
    opacity: 0;
  }
  &:hover ${ButtonIcon}{
    right: calc(50% - 28px);
  }
  background-color: ${props => {
    if( props.primary ){
      return props.theme.color.veryLightPink;
    }
    if( props.secondary ){ 
      return props.theme.color.duckEggBlue;
    }
    if( props.tertiary){
      return props.theme.color.black;
    }
    if( props.extra){
      return props.theme.color.beige;
    }
    return props.theme.color.white;
  }};
  color: ${props => {
    if( props.primary || props.secondary || props.extra ){
      return props.theme.color.black;
    }
    if( props.tertiary){
      return props.theme.color.white;
    }
    return props.theme.color.black;
  }};
`;

export {ButtonBox, ButtonLabel, ButtonIcon};