import styled from 'styled-components';
import {ButtonBox} from './../../../components/Button/Styles';
import {grid, media} from './../../../components/Styled/helpers';
import { Header5, BoldText, Header1, Header2, Header6 } from '../../../components/Styled';
import { InputBox } from '../../../components/Input/Styles';
import { CheckboxBox } from '../../../components/Checkbox/Styles';

const SignUpPage = styled.form`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  padding-bottom: 80px;
  ${Header2}{
    text-align: center;
    margin-top: 102px;
    margin-bottom: 144px;
    ${media.mobile`
      margin: 16px 0 48px;
    `}
  }
  a{
    text-decoration: none;
  }
`;

const AddressPage = styled.div`
   ${ButtonBox}{
    align-self: center;
    justify-content: center;
    margin-top: 56px;
    margin-bottom: 80px;
  }
  
  ${BoldText}{
    margin: 0 0 64px; 
    a{
      color: inherit;
      text-decoration: underline;
    }
  }
  ${CheckboxBox}{
    margin: 8px 0;
    width: 100%;
  }
`;

const InputPlaceholder = styled.div`
  display: flex;
  width: calc(50% - 8px);
`;

const TotallySummary = styled.div`
  width: 100%;
  padding: 56px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.color.white};
  background-color: ${props => props.theme.color.black};
  margin: 80px 0 0;
  ${Header1}{
    margin: 8px 0 32px;
  }
`;

const TotallyCode = styled.div`
  width: ${grid(3)};
  padding: 20px 0;
  color: ${props => props.theme.color.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  &:before{
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.color.white};
    position: absolute;
    top: 0;
    left: 0;
    opacity: .2;
    content: '';
  }
`;

const FormSection = styled.div`
  width: ${grid(10)};
  display: flex;
  flex-direction: ${props => props.checkboxes ? 'column' : 'row'};
  align-items: ${props => props.checkboxes ? 'center' : 'flex-start'};
  justify-content: space-between;
  flex-wrap: wrap;
  margin: ${props => props.checkboxes ? '72px 0 0 48px' : '0 0 0 48px'};
  ${Header5}{
    width: 100%;
    margin: 40px 0 24px;
  }
  ${InputBox}{
    width: calc(50% - 8px);
    position: relative;
    
    ${ media.mobile`
      width: 100%;
    `}
  }
  ${BoldText}{
    margin-bottom: 0;
  }
  
  ${ media.mobile`
    width: 100%;
    margin: 0;
  `}
`;

const ErrorBox = styled.span`
  ${ Header6 };
  color: ${props => props.theme.color.vermillion};
  margin: 16px 0;
`;

const SuccessBox = styled.span`
  ${ Header6 };
  color: ${props => props.theme.color.success};
  margin: 16px 0;
`;

const TabBox = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.color.white3};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const TabHeader = styled.div`
  color: ${props => props.theme.color.black};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 0;
  cursor: pointer;
`;


export {AddressPage, SignUpPage, FormSection, InputPlaceholder, ErrorBox,TotallySummary, TotallyCode, TabBox, TabHeader, SuccessBox};