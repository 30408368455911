import { connect } from 'react-redux';
import { LogoutComponent } from './../../components/Logout';
import {  clearAllErrors, logoutUser } from './../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    logged: state.get('logged'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    logout: () => {
      dispatch(logoutUser());
    },
    clearAllErrors: () => {
      dispatch(clearAllErrors());
    }
  }
}

const LogoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutComponent);

export default LogoutContainer;
