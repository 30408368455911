import { connect } from 'react-redux';
import Summary from './../../screens/Summary';
import { registerUser, updateOrderStep5, clearAllErrors, setOrderStep2, setOrderStep5, sendOrderStep2, saveContract, hideLoader } from './../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    selectedAdditionalPackages: state.get('selectedAdditionalPackages'),
    selectedAdditionalPacks: state.get('selectedAdditionalPacks'),
    selectedPromotions: state.get('selectedPromotions'),
    orderStep1: state.get('orderStep1'),
    orderStep2: state.get('orderStep2'),
    orderStep3: state.get('orderStep3'),
    orderStep5: state.get('orderStep5'),
    orderStep5Questions: state.get('orderStep5Questions'),
    promotions: state.get('promotions'),
    prices: state.get('prices'),
    orderErrors: state.get('orderErrors'),
    userAddressData: state.get('userAddressData'),
    knownFrom: state.get('knownFrom'),
    logged: state.get('logged'),
    errors: state.get('loggedErrors'),
    registrationData: state.get('registrationData'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setOrderStep2: (data) => {
      dispatch(setOrderStep2(data));
      dispatch(hideLoader());
    },    
    setOrderStep5: (data) => {
      dispatch(setOrderStep5(data));
    },    
    sendOrderStep2: (data) => {
      dispatch(sendOrderStep2(data));
    },
    saveContract: (cb) => {
      dispatch(saveContract(cb));
    },
    clearAllErrors: () => {
      dispatch(clearAllErrors());
    },
    registerUser: (data, cb ) => {
      dispatch(registerUser(data, cb));
    },
    updateOrderStep5: (data, cb) => {
      dispatch(updateOrderStep5(data, cb));
    },
  }
}

const SummaryScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Summary);

export default SummaryScreenContainer;