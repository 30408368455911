export const orderStep5Questions = (state = [], action) => {
  switch(action.type){
    case 'SET_ORDER_STEP5_QUESTIONS': {
      return state.map(option => {
        if(option.name === action.payload.field){
          option.value = action.payload.value;
        } 
        return option;
      });
    }
    case 'INIT_ORDER_STEP5_QUESTIONS':
      return action.payload.map(question => {
        if(question.type === 'IntStringMap'){
          question.value = (question.value === null || typeof(question.value) == 'undefined') ? Object.keys(question.MAP)[0] : question.value; 
        }
        return question;
      });
    default:
      return state;
  }
}