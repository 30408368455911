import styled from 'styled-components';
import { grid, media } from '../../Styled/helpers';
import icon from './summary-icon.svg';
import tab from './tab.svg';
import { SmallText } from '../../Styled';

const SummaryBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 40px 32px 0;
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: ${grid(4)};
  z-index: 99;
  color: ${props => props.theme.color.black};
  background-color: ${props => props.theme.color.duckEggBlue};
  overflow-y: auto;
  ${ media.desktop`
    width: ${grid(3.5)}
  `}
  ${ media.mobile`
    display: flex;
    position: fixed;
    width: calc(100% - 40px);
    padding: 0 20px;
    height: 100%;
    top: auto;
    bottom: 0;
    transition: transform .3s ease-in-out;
    transform: ${props => props.small ? 'translateY(calc(100% - 80px))' : 'translateY(0)'};
    z-index: 999999;
  `}
`;

const SummaryIcon = styled.span`
  width: 56px;
  height: 56px;
  min-width: 56px;
  min-height: 56px;
  border-radius: 50%;
  background-color: ${props => props.theme.color.white};
  background-image: url(${icon});
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom:32px !important;
  display: flex;
  ${media.mobile`
    width: 40px;
    height: 40px;
    background-size: 24px;
    margin-bottom: 0 !important;
    margin-right: 16px;
  `}
`;

const SummaryHiddenIcon = styled.span`
  width: 24px;
  height: 24px;
  min-width: 24px;
  display: none;
  background-image: url(${tab});
  background-size: 16px;
  background-position: center;
  transition: transform .3s ease-in-out;
  transform: ${props => props.small ? 'rotateZ(0)' : 'rotateZ(180deg)'};
  position: absolute;
  right: 0;
  ${media.mobile`
    display: flex;
  `}
`

const SummaryHeader = styled.div`
  .price{
    display: none;  
    ${media.mobile`
      display: flex;
      visibility: ${props => props.small ? 'visible' : 'hidden'}
      opacity: ${props => props.small ? '1' : '0'}
      transition: all .3s ease-in-out;
      padding-left: 16px;
      flex: 1;
      margin-right: 24px;
    `}
  }
  ${media.mobile`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 80px;
    position: relative;
  `}
`;

const SummaryListBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: stretch;
  align-items: flex-start;
  margin-top: 32px;
`;

const SummaryListItemBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 32px;
  color: inherit;
  width: 100%;
`;

const SummaryData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: inherit;
`;

const SummaryOverallBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  justify-self: flex-end;
  padding: 56px 32px 72px;
  background-color: ${props => props.theme.color.black};
  color: ${props => props.theme.color.white};
  margin: 0 -32px;
  width: 100%;
  ${media.mobile`
    padding: 24px 20px;
    margin: 0 -20px;
  `}
`;

const SummaryContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`;

export {
  SummaryBox,
  SummaryIcon,
  SummaryHiddenIcon,
  SummaryListBox,
  SummaryListItemBox,
  SummaryData,
  SummaryOverallBox,
  SummaryContent,
  SummaryHeader
}