import { connect } from 'react-redux';
import {  clearAllErrors, addSelectedAdditionalPack, removeSelectedAdditionalPack, receiveAdditionalPackages, receiveOrderStep2, receivePromotions, setSelectedPromotion, setOrderStep2, addSelectedAdditionalPackage, removeSelectedAdditionalPackage, sendOrderStep2, sendOrderStep3, checkOrderStep2, checkOrderStep3, showLoader, hideLoader } from './../../redux/actions';
import Packages from './../../screens/Packages';

const mapStateToProps = (state, ownProps) => {
  return {
    orderStep1: state.get('orderStep1'),
    orderStep2: state.get('orderStep2'),
    orderStep3: state.get('orderStep3'),
    promotions: state.get('promotions'),
    additionalPackages: state.get('additionalPackageTypes'),
    additionalPackagesTitle: state.get('additionalPackagesTitle'),
    additionalPackagesDescription: state.get('additionalPackagesDescription'),
    selectedPromotions: state.get('selectedPromotions'),
    selectedAdditionalPackages: state.get('selectedAdditionalPackages'),
    selectedAdditionalPacks: state.get('selectedAdditionalPacks'),
    orderErrors: state.get('orderErrors'),
    prices: state.get('prices'),
    services: state.get('services'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    receiveOrderStep2: () => {
      dispatch(receiveOrderStep2());
      dispatch(receivePromotions());
      dispatch(receiveAdditionalPackages());
    },
    setOrderStep2: (value, name) => {
      const payload = {
        field: name,
        value: value,
      }
      dispatch(setOrderStep2(payload));
      dispatch(hideLoader());
    },
    setSelectedPromotion: (selected) => {
      dispatch(setSelectedPromotion(selected));
    },
    addSelectedAdditionalPackage: (selected) => {
      dispatch(addSelectedAdditionalPackage(selected));
    },
    removeSelectedAdditionalPackage: (selected) => {
      dispatch(removeSelectedAdditionalPackage(selected));
    },
    addSelectedAdditionalPack: (selected) => {
      dispatch(addSelectedAdditionalPack(selected));
    },
    removeSelectedAdditionalPack: (selected) => {
      dispatch(removeSelectedAdditionalPack(selected));
    },
    sendOrderStep2: (data, services, callback) => {
      dispatch(showLoader());
      dispatch(sendOrderStep2(data, services, callback));
    },
    sendOrderStep3: (data) => {
      dispatch(showLoader());
      dispatch(sendOrderStep3(data));
    },
    checkCurrentStep: (dataStep2, dataStep3, cb) => {
      dispatch(checkOrderStep2(dataStep2, () => {
        dispatch(checkOrderStep3(dataStep3, cb));
      }));
    },
    clearAllErrors: () => {
      dispatch(clearAllErrors());
    }
  }
}

const OrderStep2Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(Packages);

export default OrderStep2Container;
