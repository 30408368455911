import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import { StepBox, StepNumber } from './Styles';
import { SmallText, Header4 } from '../Styled';

const Step = ({id, label, active, complete, link}) => {
  const url = () => {
    return (active || complete) ? (
      <Link to={`/${link}`}>
        <StepNumber active={active} complete={complete}>
          <Header4>{id}</Header4>
        </StepNumber>
        <SmallText>{label}</SmallText>
      </Link>
    ) : (
      <Fragment>
        <StepNumber active={active} complete={complete}>
          <Header4>{id}</Header4>
        </StepNumber>
        <SmallText>{label}</SmallText>
      </Fragment>
    )
  }
  return(
    <StepBox>
     { url() }
    </StepBox>
  );
}

export default Step;