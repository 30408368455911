import React, { Component, Fragment } from 'react';
import LogoComponent from '../../components/Logo';
import { FormContent } from './../../components/Styled/Content';
import { PackagesPage, ErrorBox, PromoCodeBox } from './Styles';
import { Redirect } from 'react-router-dom'; 
import HeadlineComponent from '../../components/Headline';
import RadioListComponent from '../../components/Radio/RadioList';
import RadioComponent from './../../components/Radio';
import ButtonComponent from './../../components/Button';
import StepsComponent from '../../components/Steps';
import CheckboxComponent from '../../components/Checkbox';
import SeparatorComponent from '../../components/Separator';
import InputComponent from '../../components/Input';
import LinkComponent from './../../components/Link';
import { DividerHorizontal } from '../../components/Radio/Styles';
import SummaryContainer from '../../containers/summary';
 
const offerChangeCopy = "Jako jedyny Bank oferujemy dwie metody preparatyki krwi pępowinowej (przy użyciu różnych urządzeń). Brak możliwości zastosowania wybranej metody preparatyki dotyczy w tym przypadku kwestii technicznych oraz kwestii niezależnych od Banku. Sytuacje te są niezwykle rzadkie, jednak w dbałości o bezpieczeństwo pozyskanej krwi pępowinowej przewidujemy rozwiązania na każdą sytuację. Na tym etapie decydują Państwo czy w przypadku braku możliwości zastosowania wybranej preparatyki Bank powinien odstąpić od niej i dokonać utylizacji materiału, czy też wykonać preparatykę inną dostępną metodą. W przypadku braku możliwości wykonania preparatyki metodą tradycyjną, Bank może przystąpić do preparatyki metodą SEPAX – nie wiąże się to dla klienta ze zmianą kosztów preparatyki. W przypadku braku możliwości wykonania preparatyki metodą SEPAX, Bank może przystąpić do preparatyki metodą tradycyjną – klient reguluje „opłatę właściwą” zgodnie z cennikiem dla metody tradycyjnej.";

class Packages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nextStep: false,
      promoCode: typeof (this.props.orderStep2.promokod) != 'undefined' ? this.props.orderStep2.promokod.value : '',
      promoCodeValidated: typeof (this.props.orderStep2.promokod) != 'undefined',
      sellerName: typeof (this.props.orderStep2.parent_seller_name) != 'undefined' ? this.props.orderStep2.parent_seller_name.value : '',
    }
    this.setSelected = this.setSelected.bind(this);
    this.setSelectedChange = this.setSelectedChange.bind(this);
    this.setSelectedPromotion = this.setSelectedPromotion.bind(this);
    this.clearSelectedPromotion = this.clearSelectedPromotion.bind(this);
    this.addCheckedAdditionalPackage = this.addCheckedAdditionalPackage.bind(this);
    this.removeCheckedAdditionalPackage = this.removeCheckedAdditionalPackage.bind(this);
    this.addCheckedAdditionalPack = this.addCheckedAdditionalPack.bind(this);
    this.removeCheckedAdditionalPack = this.removeCheckedAdditionalPack.bind(this);
    this.sendOrderStep2 = this.sendOrderStep2.bind(this);
    this.sendOrderStep3 = this.sendOrderStep3.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.getDescrptions = this.getDescrptions.bind(this);
    this.getAdditionaPrice = this.getAdditionaPrice.bind(this);
    this.getPromoDescription = this.getPromoDescription.bind(this);
    this.getPromoDescription2 = this.getPromoDescription2.bind(this);
    this.getAdditionalPacksDescription = this.getAdditionalPacksDescription.bind(this);
    this.updatePromoCode = this.updatePromoCode.bind(this);
    this.savePromoCode = this.savePromoCode.bind(this);
    this.clearPromoCode = this.clearPromoCode.bind(this);
    this.updateSellerName = this.updateSellerName.bind(this);
    this.saveSellerName = this.saveSellerName.bind(this);
    this.clearSellerName = this.clearSellerName.bind(this);
    this.packagesTypes2 = this.packagesTypes2.bind(this);
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    this.props.clearAllErrors();
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(this.props.orderStep2) !== JSON.stringify(nextProps.orderStep2) || JSON.stringify(this.props.selectedAdditionalPacks) !== JSON.stringify(nextProps.selectedAdditionalPacks)) {
      this.sendOrderStep2(nextProps);
    }
    if (JSON.stringify(this.props.orderStep3) !== JSON.stringify(nextProps.orderStep3) || JSON.stringify(this.props.selectedAdditionalPackages) !== JSON.stringify(nextProps.selectedAdditionalPackages) || JSON.stringify(this.props.selectedPromotions) !== JSON.stringify(nextProps.selectedPromotions)) {
      this.sendOrderStep3(nextProps);
    }
  }

  get offer() {
    return this.props.orderStep2.oferta_banku
  }

  get offerChange() {
    return this.props.orderStep2.oferta_banku_zmiana
  }

  get addsPacks() {
    return this.props.orderStep2.uslugi_dodatkowe
  }

  get additionalPacks() {
    return this.props.orderStep2.pakiety_dodatkowe
  }

  get packages() {
    if (this.offer) {
      const values = {'-1': [-1], '1': [1, 11, 13], '2': [2, 12, 14]};
      const keys = Object.keys(values);
      const value = keys.find(k => values[k].includes(this.offer.value));
      return (
        <Fragment>
          <HeadlineComponent>{this.offer.dsLabel1}</HeadlineComponent>
          <RadioListComponent setSelected={this.setSelected} selectedValue={value} name={this.offer.dsName1}>
            {this.packagesTypes}
          </RadioListComponent>
        </Fragment>
      )
    }
    return '';
  }

  get packages2() {
    if (this.offer) {
      
      const values = {'-1': [-1],'0': [1, 2], '5': [11, 12], '6': [13, 14]};
      const keys = Object.keys(values);
      const value = keys.find(k => values[k].includes(this.offer.value));
      return (
        <Fragment>
          <HeadlineComponent tooltipCopy={"Pozyskanie sznura pępowinowego jest możliwe tylko wraz z usługą pozyskania krwi pępowinowej."}>{this.offer.dsLabel2}</HeadlineComponent>
          <RadioListComponent setSelected={this.setSelected} selectedValue={value} name={this.offer.dsName2}>
            {this.packagesTypes2(value)}
          </RadioListComponent>
        </Fragment>
      )
    }
    return '';
  }

  getDescrptions(index) {
    // const offerPrices = this.props.prices.oferty_banku.filter(offer => {
    //   return offer.nazwa == name;
    // });

    // console.log(name, offerPrices, this.props.prices.oferty_banku)

    const offerPrice = this.props.prices.oferty_banku[index] || null;
    if (offerPrice) {
      const prices = [
        {
          headline: offerPrice.cena1n,
          standardPrice: offerPrice.cena1k,
          promoPrice: null,
          description: "Płatna do 3 dni od podpisania umowy"
        },
        {
          headline: offerPrice.cena2n,
          standardPrice: offerPrice.cena2k,
          promoPrice: null,
          description: "Płatna do 56 dni od porodu"
        }
      ]
      return prices;
    }
    return false;
  }

  get packagesTypes() {
    const result = [];
    for (let k in this.offer.MAP) {
      // poprawka pod błąd ze zmienionymi indexami w dsMap1
      const key = +k + 1;
      if (this.offer.dsMAP1.hasOwnProperty(key)) {
        if (key !== '') {
          result.push(<RadioComponent withDescription key={`package-${key}`} option={key} id={`radio-offer-${key}`} name={this.offer.dsName1} value={key} descriptions={this.getDescrptions(k)} label={this.offer.dsMAP1[key]} />);
        }
      }
    }
    
    return result;
  }

  packagesTypes2(value) {
    const result = [];
    for (let key in this.offer.dsMAP2) {
      if (this.offer.dsMAP2.hasOwnProperty(key)) {
        if (key !== '') {
          result.push(<RadioComponent disabled={value === '-1' || value === undefined} withDescription key={`package-${key}`} option={key} id={`radio-offer-${key}`} name={this.offer.dsName2} value={key} descriptions={this.getDescrptions(this.offer.dsMAP2[key])} label={this.offer.dsMAP2[key]} />);
        }
      }
    }
    return result;
  }


  getPromoDescription(name) {
    let promo = null;
    const promos = this.props.prices.promocje;
    for (let key in promos) {
      if (promos.hasOwnProperty(key)) {
        if (promos[key].nazwa === name) {
          promo = promos[key];
        }
      }
    };
    if (promo) {
      const prices = [
        {
          headline: promo.cena1n,
          standardPrice: promo.cena1k,
          promoPrice: null,
          description: ''
        },
        {
          headline: promo.cena2n,
          standardPrice: promo.cena2k,
          promoPrice: null,
          description: ''
        },
        {
          headline: promo.cena3n,
          standardPrice: promo.cena3k,
          promoPrice: null,
          description: ''
        }
      ]
      return prices;
    }
    return false;
  }

  getPromoDescription2(name) {
    let promo = null;
    const promos = this.props.prices.promocje;
    for (let key in promos) {
      if (promos.hasOwnProperty(key)) {
        if (promos[key].nazwa === name) {
          promo = promos[key];
        }
      }
    };
    if (promo && promo.cena_sz_1n) {
      const prices = [
        {
          headline: promo.cena_sz_1n,
          standardPrice: promo.cena_sz_1k,
          promoPrice: null,
          description: ''
        },
        {
          headline: promo.cena_sz_2n,
          standardPrice: promo.cena_sz_2k,
          promoPrice: null,
          description: ''
        },
        {
          headline: promo.cena_sz_3n,
          standardPrice: promo.cena_sz_3k,
          promoPrice: null,
          description: ''
        }
      ]
      return prices;
    }
    return false;
  }

  clearSelectedPromotion() {
    this.props.setSelectedPromotion('');
  }

  get promotions() {
    const clearSelectedPromotion = this.props.selectedPromotions ? (
      <LinkComponent alone onClick={this.clearSelectedPromotion} label='Wyczyść wybór promocji' action={this.clearSelectedPromotion}></LinkComponent>
    ) : '';
    if (this.props.promotions) {
      return (
        <Fragment>
          <HeadlineComponent>{"WYBIERZ PROMOCJĘ"}</HeadlineComponent>
          <RadioListComponent setSelected={this.setSelectedPromotion} selectedValue={this.props.selectedPromotions} name={'promotion'}>
            {this.promotionsTypes}
          </RadioListComponent>
          {clearSelectedPromotion}
        </Fragment>
      )
    }
    return '';
  }

  get promotionsTypes() {
    return this.props.promotions.map((promo, index) => {
      return (<RadioComponent withDescription descriptionTitle="Krew pępowinowa" description2Title="Sznur pępowinowy" descriptions={this.getPromoDescription(promo.nazwa)} descriptions2={this.getPromoDescription2(promo.nazwa)} key={promo.id} option={promo.id} id={`radio-promo-${promo.id}`} name={'promo'} value={promo.id} label={promo.nazwa} tooltipCopy={promo.opis} />);
    });
  }

  get packagesChange() {
    if (this.offerChange) {
      return (
        <Fragment>
          <HeadlineComponent tooltipCopy={offerChangeCopy}>{this.offerChange.label}</HeadlineComponent>
          <RadioListComponent setSelected={this.setSelectedChange} selectedValue={this.offerChange.value} name={this.offerChange.name}>
            {this.packagesChangeTypes}
          </RadioListComponent>
        </Fragment>
      )
    }
    return '';
  }

  get packagesChangeTypes() {
    const result = [];
    for (let key in this.offerChange.MAP) {
      if (this.offerChange.MAP.hasOwnProperty(key)) {
        if (key !== '') {
          result.push(<RadioComponent key={key} option={key} id={`radio-change-${key}`} name={this.offerChange.name} value={key} label={this.offerChange.MAP[key]} />);
        }
      }
    }
    return result;
  }

  getAdditionaPrice(name) {
    const prices = this.props.prices[2][2].filter(pack => {
      return pack[0] === name;
    });
    const prices2 = this.props.prices['2sz'] ? this.props.prices['2sz'][2].filter(pack => pack[0] === name) : [{0: name, 1: "0"}];
    if (prices.length) {
      return {
        promo: null,
        standard: parseFloat(prices[0][1]) + parseFloat(prices2[0][1]),
      }
    }
    return false;
  }

  get additional() {
    return this.props.additionalPackages && this.props.additionalPackages.map((type, index) => {
      const isChecked = this.props.selectedAdditionalPackages && this.props.selectedAdditionalPackages.filter((promo) => {
        return promo.name == type.name
      }).length;

      const newAdditional = this.props.orderStep3.uslugi_dodatkowe_new.MAP[type.id];
      const additionalDescriptions = { description: newAdditional[1] || '', priceDescription: newAdditional[2] || '' };
      const tooltip = Object.values(this.props.services).filter(s => s[0].toUpperCase() === type.name.toUpperCase());
      const hidePayments = tooltip.length && typeof (tooltip[0][2]) != 'undefined' && tooltip[0][2] == 'hidePayments' ? true : false;
      const extraInfo = tooltip.length && typeof (tooltip[0][3]) != 'undefined' ? tooltip[0][3] : "Płatne bezpośrednio u dostawcy usługi";
      const tooltipCopy = tooltip.length ? tooltip[0][1] : false;
      type.hidePayments = hidePayments;
      if (!hidePayments) {
        const price =  newAdditional[3] !== null ? {standard: newAdditional[3].replace('zł', ''), promo: null} : this.getAdditionaPrice(type.name);
        return (
          <Fragment key={`promotion-${type.id}`}>
            <CheckboxComponent tooltipCopy={tooltipCopy} withDescription descriptions={additionalDescriptions} prices={price} isChecked={isChecked} addChecked={this.addCheckedAdditionalPackage} removeChecked={this.removeCheckedAdditionalPackage} option={type} label={type.name} name={`checkbox-promo-${type.id}`} />
            {(index + 1 < this.props.additionalPackages.length) ? <DividerHorizontal /> : ''}
          </Fragment>
        )
      } else {
        return (
          <Fragment key={`promotion-${type.id}`}>
            <CheckboxComponent tooltipCopy={tooltipCopy} withDescription descriptions={{ description: extraInfo, priceDescription: '' }} isChecked={isChecked} addChecked={this.addCheckedAdditionalPackage} removeChecked={this.removeCheckedAdditionalPackage} option={type} label={type.name} name={`checkbox-promo-${type.id}`} />
            {(index + 1 < this.props.additionalPackages.length) ? <DividerHorizontal /> : ''}
          </Fragment>
        )
      }

    })
  }


  addCheckedAdditionalPack(checked) {
    this.props.addSelectedAdditionalPack(checked);
  }

  removeCheckedAdditionalPack(checked) {
    this.props.removeSelectedAdditionalPack(checked);
  }

  get additionalPackages() {
    if (this.additionalPacks) {
      return (
        <Fragment>
          <HeadlineComponent uppercase={true}>{this.additionalPacks.label}</HeadlineComponent>
          {this.additionalPackagesTypes}
        </Fragment>
      )
    }
    return '';
  }

  getAdditionalPacksDescription(id) {
    const promos = this.props.prices.pakiety_dodatkowe.filter(p => p.id == id);
    const promo = promos.length > 0 ? promos[0] : false;
    if (promo) {
      const prices = { description: promo.opis2, priceDescription: '' };
      return prices;
    }
    return false;
  }


  get additionalPackagesTypes() {
    const result = [];
    for (let key in this.additionalPacks.MAP) {
      if (this.additionalPacks.MAP.hasOwnProperty(key)) {
        const isChecked = this.props.selectedAdditionalPacks && this.props.selectedAdditionalPacks.filter((pack) => {
          return pack === key;
        }).length;
        if (this.additionalPacks.MAP[key] !== '') {
          result.push(
            <Fragment key={`package-${key}`}>
              <CheckboxComponent withDescription descriptions={this.getAdditionalPacksDescription(key)} tooltipCopy={this.additionalPacks.DESCMAP[key]} isChecked={isChecked} addChecked={this.addCheckedAdditionalPack} removeChecked={this.removeCheckedAdditionalPack} option={key} label={this.additionalPacks.MAP[key]} name={`checkbox-package-${key}`} />
            </Fragment>
          );
        }
      }
    }
    return result;
  }
  // get promotions(){
  //   return this.props.promotions && this.props.promotions.map((type, index) => {
  //     const isChecked = this.props.selectedPromotions && this.props.selectedPromotions.filter((promo) => {
  //       return promo.id === type.id
  //     }).length;
  //     return (
  //       <Fragment key={`promotion-${type.id}`}>
  //         <CheckboxComponent isChecked={isChecked} addChecked={this.addCheckedPromotion} removeChecked={this.removeCheckedPromotion} option={type} label={type.nazwa} name={`checkbox-promo-${type.id}`} tooltipCopy={type.opis}  />
  //         {(index+1 < this.props.promotions.length) ? <DividerHorizontal /> : '' }
  //       </Fragment>
  //     )
  //   })
  // }

  setSelectedChange(selected, name) {
    this.props.setOrderStep2(selected, name);
  }

  setSelected(selected, name) {
    
    const values1 = {'-1': [-1], '1': [1, 11, 13], '2': [2, 12, 14]};
    const values2 = {'0': [-1, 1, 2], '5': [11, 12], '6': [13, 14]};
    const keys1 = Object.keys(values1);
    const keys2 = Object.keys(values2);
    const value1 = parseInt(keys1.find(k => values1[k].includes(this.offer.value)));
    const value2 = parseInt(keys2.find(k => values2[k].includes(this.offer.value)));
    const value = this.offer.value;
    let newValue = value;
    if(name === 'oferta_banku_sr_1'){
      newValue = !isNaN(value2) ? +selected + value2 * 2 : +selected;
    }else {
      newValue = !isNaN(value1) ? +value1 + +selected * 2 : +selected * 2;
    }
    this.props.setOrderStep2(newValue, 'oferta_banku');
  }

  setSelectedPromotion(selected) {
    this.props.setSelectedPromotion(selected);
  }

  addCheckedAdditionalPackage(checked) {
    this.props.addSelectedAdditionalPackage(checked);
  }

  removeCheckedAdditionalPackage(checked) {
    this.props.removeSelectedAdditionalPackage(checked);
  }

  getStep2Data(props) {
    return {
      parent_seller_name: props.orderStep2.parent_seller_name.value,
      promokod: props.orderStep2.promokod.value,
      oferta_banku: props.orderStep2.oferta_banku.value,
      oferta_banku_zmiana: props.orderStep2.oferta_banku_zmiana ? props.orderStep2.oferta_banku_zmiana.value : null,
      pakiety_dodatkowe: props.selectedAdditionalPacks.join('|'),
    }
  }

  getStep3Data(props) {
    const selectedPackages = props.selectedAdditionalPackages.map(pack => {
      return pack.id;
    }).join('|');
    const defaultPrzedplata = Object.keys(props.orderStep3.przedplata.MAP)[0];
    return {
      uslugi_dodatkowe_new: selectedPackages,
      przedplata: props.orderStep3.przedplata.value ? props.orderStep3.przedplata.value : defaultPrzedplata,
      promocja_id: props.selectedPromotions,
    }
  }

  sendOrderStep2(props) {
    const data = this.getStep2Data(props)
    this.props.sendOrderStep2(data, props.services);
  }

  sendOrderStep3(props) {
    const data = this.getStep3Data(props);
    this.props.sendOrderStep3(data);
  }

  get redirector() {
    if (typeof (this.props.orderStep1.rodzaj_ciazy) == 'undefined') {
      return (
        <Redirect to='/pregnant' />
      )
    }
    return '';
  }

  nextStep() {
    this.props.checkCurrentStep(this.getStep2Data(this.props), this.getStep3Data(this.props), () => {
      this.setState({ nextStep: true });
    });
  }

  get renderNextStep() {
    return this.state.nextStep && (
      <Redirect to='/storage' />
    )
  }

  get errors() {
    return this.props.orderErrors && this.props.orderErrors.map((error, i) => {
      return <ErrorBox key={i}>{error.msg}</ErrorBox>;
    })
  }

  savePromoCode() {
    this.props.setOrderStep2(this.state.promoCode, 'promokod');
    this.props.sendOrderStep2(this.getStep2Data(this.props), this.props.services, () => {
      this.setState({
        promoCodeValidated: true,
      })
    });
  }

  clearPromoCode() {
    this.setState({
      promoCode: ''
    }, () => {
      this.props.setOrderStep2(this.state.promoCode, 'promokod');
      this.props.sendOrderStep2(this.getStep2Data(this.props), this.props.services, () => {
        this.setState({
          promoCodeValidated: false,
        })
      });
    });
  }

  updatePromoCode(val) {
    this.setState({
      promoCode: val.value
    });
  }

  get promoCode() {
    const button = (typeof (this.props.orderStep2.promokod) != 'undefined' && this.props.orderStep2.promokod.value.length === 0) ? (
      <LinkComponent onClick={this.savePromoCode} label='Zatwierdź' action={this.savePromoCode}></LinkComponent>
    ) : (
      <LinkComponent onClick={this.clearPromoCode} label='Usuń' action={this.clearPromoCode}></LinkComponent>
    )
    const disabled = !(typeof (this.props.orderStep2.promokod) != 'undefined' && this.props.orderStep2.promokod.value.length === 0);
    
    const error = this.state.promoCodeValidated && typeof (this.props.orderStep2.promokod) != 'undefined' && this.props.orderStep2.promokod.value === undefined ? (
      <ErrorBox fieldError>Nieprawidłowy kod rabatowy</ErrorBox>
    ) : '';
    return (
      <Fragment>
        <HeadlineComponent
          subheadline="Jeśli otrzymali Państwo od nas indywidualną wycenę usług wraz z kodem rabatowym, prosimy o jego wprowadzenie w poniższym polu oraz zatwierdzenie. Po wypełnieniu całej deklaracji i wciśnięciu przycisku „ZAKOŃCZ” do Biura Obsługi Klienta wpłynie prośba o zatwierdzenie indywidualnej wyceny. Pracownik biura skontaktuje się z Państwem w celu zatwierdzenia spersonalizowanego cennika, który będzie stanowił załącznik do umowy. <strong>Prosimy o zwrócenie uwagi, iż wpisanie kodu rabatowego nie spowoduje zmiany cen widocznych na stronie podczas składania deklaracji.</strong>"
        >KOD RABATOWY</HeadlineComponent>
        <PromoCodeBox>
          <InputComponent disabled={disabled} changeHandler={this.updatePromoCode} label="Kod rabatowy" type="text" name="promokod" id="promokod" value={this.state.promoCode} />
          {button}
        </PromoCodeBox>
        {error}
      </Fragment>
    );
  }


  saveSellerName() {
    this.props.setOrderStep2(this.state.sellerName, 'parent_seller_name');
    this.props.sendOrderStep2(this.getStep2Data(this.props), this.props.services);
  }

  clearSellerName() {
    this.setState({
      sellerName: ''
    }, () => {
      this.props.setOrderStep2(this.state.sellerName, 'parent_seller_name');
      this.props.sendOrderStep2(this.getStep2Data(this.props), this.props.services);
    });
  }

  updateSellerName(val) {
    this.setState({
      sellerName: val.value
    });
  }

  get sellerName() {
    const button = (typeof (this.props.orderStep2.parent_seller_name) != 'undefined' && this.props.orderStep2.parent_seller_name.value.length === 0) ? (
      <LinkComponent onClick={this.saveSellerName} label='Zatwierdź' action={this.saveSellerName}></LinkComponent>
    ) : (
      <LinkComponent onClick={this.clearSellerName} label='Usuń' action={this.clearSellerName}></LinkComponent>
    )
    return (
      <Fragment>
        <HeadlineComponent subheadline={this.props.orderStep2 && this.props.orderStep2.parent_seller_name && this.props.orderStep2.parent_seller_name.extraText}>NA JĘZYKACH</HeadlineComponent>
        <PromoCodeBox>
          <InputComponent changeHandler={this.updateSellerName} label={this.props.orderStep2 && this.props.orderStep2.parent_seller_name && this.props.orderStep2.parent_seller_name.label} type="text" name="parent_seller_name" id="parent_seller_name" value={this.state.sellerName} />
          {button}
        </PromoCodeBox>
      </Fragment>
    );
  }

  render() {
    return (
      <PackagesPage className="content">
        <FormContent>
          <LogoComponent />
          <StepsComponent active="2" />

          {this.packages}
          <SeparatorComponent />

          {/* {this.packagesChange}
          <SeparatorComponent /> */}

          
          
          {/* {this.packages2}
          <SeparatorComponent /> */}

          {this.additionalPacks && this.additionalPacks.MAP && this.additionalPacks.MAP.length && (
            <>
            {this.additionalPackages}
            <SeparatorComponent />
            </>
          )}

          <HeadlineComponent uppercase>{this.props.additionalPackagesTitle} {this.props.additionalPackagesDescription}</HeadlineComponent>
          {this.additional}
          <SeparatorComponent />

          {/* {this.promotions}
          <SeparatorComponent /> */}

          {this.promoCode}
          <SeparatorComponent />

          {this.sellerName}
          <SeparatorComponent />

          {this.errors}
          <ButtonComponent primary label="Przejdź dalej" click={this.nextStep} />
        </FormContent>
        <SummaryContainer />
        {this.redirector}
        {this.renderNextStep}
      </PackagesPage>
    )
  }
}

export default Packages;