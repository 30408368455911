import { connect } from 'react-redux';
import Address from './../../screens/Address';
import {clearAllErrors, setAddressData, receiveAddressData, updateAddressData, clearAddressErrors, clearAddressSuccess } from './../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    orderStep1: state.get('orderStep1'),
    addressData: state.get('userAddressData'),
    success: state.get('addressSuccess'),
    orderErrors: state.get('orderErrors'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    receiveAddressData: () => {
      dispatch(receiveAddressData());
    },
    setAddressData: (data) => {
      dispatch(setAddressData(data));
    },
    updateAddressData: (data, cb) => {
      dispatch(updateAddressData(data, cb));
    },
    clearAddressErrors: () => {
      dispatch(clearAddressErrors());
    },
    clearAddressSuccess: () => {
      dispatch(clearAddressSuccess());
    },
    clearAllErrors: () => {
      dispatch(clearAllErrors());
    }
  }
}

const AddressContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Address);

export default AddressContainer;