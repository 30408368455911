import Api from '../../../utils/api';
import {
  setPrices,
  hideLoader,
  addSelectedAdditionalPackage,
  setError,
} from '../index';

export function receivePrices(checkPackages = false, services = false, callback = false) {
  return (dispatch) => {
    new Api(`/api.php?action=UmowaKwota${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).get()
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            dispatch(setPrices(json.OK));
            dispatch(hideLoader());
            // AUTO CHECKING FREE SERVICES
            // if(checkPackages && services){
            //   let freePackages = json.OK[2][2].map((pack, index) => {
            //     pack[2] = index+1;
            //     return pack;
            //   });
            //   freePackages = freePackages.filter((pack) => {
            //     const service = Object.values(services).filter(s => s[0] === pack[0]);
            //     const hidePayments = service.length && typeof(service[0][2]) != 'undefined' && service[0][2] == 'hidePayments' ? true : false;
            //     return parseFloat(pack[1]) === 0 && !hidePayments;
            //   });
            //   freePackages.map((pack, index) => {
            //     dispatch(addSelectedAdditionalPackage( {
            //       id: pack[2],
            //       name: pack[0]
            //     }));
            //   });
            // }
            callback && callback();
            setTimeout(() => {
              dispatch(hideLoader());
            }, 1000);
          }else{
            dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}

