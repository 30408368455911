import { connect } from 'react-redux';
import ContractsList from './../../screens/Contracts';
import {  clearAllErrors, receiveUsersContracts } from './../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    userContracts: state.get('userContracts'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    receiveUsersContracts: () => {
      dispatch(receiveUsersContracts());
    },
    clearAllErrors: () => {
      dispatch(clearAllErrors());
    }
  }
}

const ContractsListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractsList);

export default ContractsListContainer;
