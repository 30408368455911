import React, { Component } from 'react';
import LogoComponent from '../../components/Logo';
import ListComponent from '../../components/List';
import { FormContent } from '../../components/Styled/Content'; 
import { Link } from 'react-router-dom';

import { SignInPage } from './Styles';
import BackButtonConponent from '../../components/BackButton';
import LogoutContainer from '../../containers/logout';

class ContractsList extends Component{
  constructor(props){
    super(props);

    this.clickHandler = this.clickHandler.bind(this);
    window.scrollTo(0, 0);
  }

  componentWillMount(){
    this.props.clearAllErrors();
    this.props.receiveUsersContracts();
  }

  clickHandler(id){
    const win = window.open(`/umowa_view_pdf.php?id=${id}`, '_blank');
    win.focus()
  }

  render(){
    return(
      <SignInPage className="content">
      <LogoutContainer />
        <FormContent centered>
          <LogoComponent />
          <Link to="/panel">
            <BackButtonConponent color="beige" label="Umowy online" />
          </Link>
          <ListComponent items={this.props.userContracts} onClick={this.clickHandler} />
        </FormContent>
      </SignInPage>
    )
  }
}

export default ContractsList;