import Api from '../../../utils/api';
import {
  setErrors,
  clearErrors, 
  registrationSuccess,
  registrationFail,
  loginSuccess,
  setError,
  setRegistrationData,
} from '../index';

export function registerUser(data, cb) {
  return (dispatch) => {
    new Api(`/api.php?action=register${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post(data)
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0 && json.WARNING === 0){
            dispatch(clearErrors());
            dispatch(registrationSuccess());
            dispatch(loginSuccess());
            cb && cb();
          }else{
            const errors = json.ERRORS.ERROR_USERINFO.map(er => {
              return Object.values(er);
            });
            const warnings = json.WARNINGS.WARNING_USERINFO.map(war => {
              return Object.values(war);
            });

            dispatch(setErrors(errors.concat(warnings)));
            dispatch(registrationFail());
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
      dispatch(registrationFail());
    });
  };
}


export function receiveRegistrationData() {
  return (dispatch) => {
    new Api(`/api.php?action=getregister${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            dispatch(setRegistrationData(json.OK.labels));
          }else{
            dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}

