import React from 'react';
import { Header1, Header5 } from '../Styled';
import { SummaryOverallBox } from './Styles';

const SummaryOverallComponent = ({options}) =>{
  const getOverall = () =>{
    const sum = options.reduce((sum, item) => {
      const itemSum = item.selected.reduce((is, i) => {
        return is + parseInt(i.price);
      }, 0);
      return sum + parseInt(itemSum);
    }, 0)
    return `${sum} zł`;
  }

  return ( 
    <SummaryOverallBox>
      <Header5>Całkowity koszt</Header5>
      <Header1>{getOverall()}</Header1>
    </SummaryOverallBox>
  );
}
 
export default SummaryOverallComponent;