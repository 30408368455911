import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import LogoComponent from '../../components/Logo';
import NewPasswordComponent from '../../components/NewPassword';
import { Header2 } from '../../components/Styled';
import { SignInPage, SignInSections } from './Styles';

class ResetPassword extends Component{
  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.props.clearAllErrors();
  }

  get renderNextStep(){
    return this.props.reset && (
      <Redirect to='/new_password' />
    )
  }

  render(){
    return(
      <SignInPage className="content">
        <LogoComponent />
        <Header2>Nie pamiętasz hasła?<br /> Wygeneruj kod tymczasowy i wykorzystaj go do stworzenia nowego hasła.</Header2>
        <SignInSections>
          <NewPasswordComponent resetPassword={this.props.resetPassword} resetErrors={this.props.resetErrors} reset={this.props.reset} />
        </SignInSections>
        { this.renderNextStep }
      </SignInPage>
    )
  }
}

export default ResetPassword;