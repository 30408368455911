const font = (theme) => {
  return `
    font-size: 18px;
    line-height: 32px;
    font-family: ${theme.fontBody};
    font-weight: 400;
  `;
}

const fontSmall = (theme) => {
  return `
    font-size: 12px;
    line-height: 16px;
    @extend ${theme.fontBody};
    font-weight: 400;
  `;
}

const fontMiddle = (theme) => {
  return `
    font-size: 14px;
    line-height: 16px;
    @extend ${theme.fontBody};
    font-weight: 400;
  `;
}

const h0 = (theme) => {
  return `
    font-size: 80px;
    line-height: 88px;
    font-family: ${theme.fontHeader};
    font-weight: 300;
    text-transform: uppercase;
    @media ${theme.rwd.mobile}{
      font-size: 35px;
      line-height: 48px;
    }
  `
}

const h1 = (theme) => {
  return `
    font-size: 45px;
    line-height: 56px;
    font-weight: 300;
    text-transform: uppercase;
    font-family: ${theme.fontHeader};
    @media ${theme.rwd.mobile}{
      font-size: 28px;
      line-height: 40px;
    }
  `
}

const h2 = (theme) => {
  return `
  font-size: 32px;
  line-height: 48px;
  font-weight: 300;
  font-family: ${theme.fontHeader};
  @media ${theme.rwd.mobile}{
    font-size: 24px;
    line-height: 32px;
  }
  `
}

const h3 = (theme) => {
  return `
  font-size: 23px;
  line-height: 32px;
  font-weight: 300;
  font-family: ${theme.fontHeader};
  @media ${theme.rwd.mobile}{
    font-size: 20px;
    line-height: 24px;
  }
  `
}

const h4 = (theme) => {
  return `
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  font-family: ${theme.fontHeader};
  `
}

const h5 = (theme) => {
  return `
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: ${theme.fontHeader};
  `
}

const h6 = (theme) => {
  return `
  font-size: 18px;
  line-height: 32px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: ${theme.fontBody};
  `
}

export {
  font,
  fontSmall,
  fontMiddle,
  h0,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6
}