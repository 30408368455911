export const orderStep5 = (state = {}, action) => {
  switch(action.type){
    case 'SET_ORDER_STEP5': {
      const medicalData = Object.assign({}, state);
      medicalData[action.payload.field].value = action.payload.value;
      return medicalData;
    }
    case 'INIT_ORDER_STEP5':{
      if(action.payload.dowiedzieli_from_int.name === 'dowiedzieli_from_int' && (action.payload.dowiedzieli_from_int.value === null || typeof(action.payload.dowiedzieli_from_int.value) == 'undefined')){
        action.payload.dowiedzieli_from_int.value = Object.keys(action.payload.dowiedzieli_from_int.MAP)[0];
      }
      return action.payload;
    }
    default:
      return state;
  }
}