import Api from '../../../utils/api';
import {
  setUserContracts,
  setError,
} from '../index';

export function receiveUsersContracts() {
  return (dispatch) => {
    new Api(`/api.php?action=LUmow${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          const contracts = [];
          for(let key in json.OK){
            const contract = {
              version: '1.0',
              date: json.OK[key].data_zlozenia,
              id: json.OK[key].nr_umowy,
              url: key,
            }
            contracts.push(contract);
          }
          dispatch(setUserContracts(contracts));
        })
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}
