import {
  setOrderErrors,
  setError,
} from '../index';

export function checkErrors(errors){
  return dispatch => {
    let sessionError = false;
    errors.map(e => {
      if(e.msg === 'Umowa nie została zainicjowana!'){
        sessionError = true;    
        dispatch(setError('Twoja sesja wygasła. Zostaniesz przekierowany do strony początkowej.'));
      }
    });
    if(!sessionError){
      dispatch(setOrderErrors(errors));
    }
  }
}