import React from 'react';
import {Header5, SmallText, StrikeText} from './../Styled';
import {DescriptionBox} from './Styles';


const Description = ({desc}) => {
  const headline = () => {
    return desc.headline ? desc.headline : '';
  }
  const standardPrice = () => {
    return desc.standardPrice && desc.promoPrice ? <StrikeText>{` ${desc.standardPrice}zł `}</StrikeText> : '';
  }
  const promoPrice = () => {
    return desc.promoPrice ? `${desc.promoPrice}zł` : ` ${desc.standardPrice}zł`;
  }
  const description = () => {
    return desc.description ? desc.description : '';
  }
  return (
    <DescriptionBox>
      <Header5>
        {headline()} 
        {standardPrice()}
        {promoPrice()}
      </Header5>
      <SmallText>{description()}</SmallText>
    </DescriptionBox>
  );
}

export default Description;