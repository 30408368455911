import { connect } from 'react-redux';
import Pregnant from './../../screens/Pregnant';
import {  clearAllErrors, initOrder, setOrderStep1, sendOrderStep1, checkOrderStep1, receiveOrderStep1, addSelectedAdditionalPackage, setSelectedPromotion } from './../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    orderStep1: state.get('orderStep1'),
    orderStep2: state.get('orderStep2'),
    orderStep3: state.get('orderStep3'),
    selectedAdditionalPackages: state.get('selectedAdditionalPackages'),
    additionalPackages: state.get('additionalPackageTypes'),
    orderErrors: state.get('orderErrors'),
    prices: state.get('prices'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    initOrder: () => {
      dispatch(initOrder());
    },
    setOrderStep1: (value, name) => {
      const payload = {
        field: name,
        value: value,
      }
      dispatch(setOrderStep1(payload));
    },
    sendOrderStep1: (data) => {
      dispatch(sendOrderStep1(data));
    },
    receiveOrderStep1: () => {
      dispatch(receiveOrderStep1());
    },
    checkCurrentStep: (data, cb) => {
      dispatch(checkOrderStep1(data, cb));
    },
    addSelectedAdditionalPackage: (data) => {
      dispatch(addSelectedAdditionalPackage(data));
    },
    setSelectedPromotion: (data) => {
      dispatch(setSelectedPromotion(data));
    },
    clearAllErrors: () => {
      dispatch(clearAllErrors());
    }
  }
}

const OrderStep1Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(Pregnant);

export default OrderStep1Container;
