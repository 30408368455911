import Api from '../../../utils/api';
import {
  initOrderStep2,
  setPromotions,
  setAdditionalPackagesDescription,
  setAdditionalPackagesTitle,
  setAdditionalPackagesTypes,
  hideLoader,
  receivePrices,
  checkErrors,
  clearOrderErrors,
  setError,
} from '../index';

export function receiveOrderStep2() {
  return (dispatch) => {
    new Api(`/api.php?action=UmowaS2Get${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            const data = {};
            json.OK.map(field => {
              const f = Object.assign({}, field);
              f.value = typeof(f.value) != 'undefined' ? f.value : '';
              data[field.name] =  f;
            });
            dispatch(initOrderStep2(data));
          }else{
            dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}

export function receivePromotions() {
  return (dispatch) => {
    new Api(`/api.php?action=slownik${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({"slownik":"promocje","maska":""})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            const data = [];
            for(let key in json.promocje){
              data.push(json.promocje[key]);
            }
            dispatch(setPromotions(data));
          }else{
            dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}


export function receiveAdditionalPackages() {
  return (dispatch) => {
    new Api(`/api.php?action=slownik${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({"slownik":"pakiety","maska":""})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            dispatch(setAdditionalPackagesTitle(json.pakiety[0]));
            dispatch(setAdditionalPackagesDescription(json.pakiety[1]));
            const data = [];
            for(let key in json.pakiety[2]){
              data.push({
                id: key,
                name: json.pakiety[2][key]
              });
            }
            // what the hack?
            // const p1 = data.pop();
            // const sortedData = [p1, ...data];
            dispatch(setAdditionalPackagesTypes(data));
            
            dispatch(hideLoader());
          }else{
            dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}

export function sendOrderStep2(data, services, callback){
  return (dispatch) => {
    dispatch(clearOrderErrors());
    const errors = [];
    if(data.oferta_banku === null || typeof(data.oferta_banku) == 'undefined'){
      errors.push({field: 'oferta_banku', msg: 'Proszę wybrać ofertę banku.'});
      dispatch(checkErrors(errors));
      dispatch(hideLoader());
      return;
    }
    new Api(`/api.php?action=UmowaS2Edit${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post(data)
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          const errors = [];
          json.ERRORS.ERROR_USERINFO.map(er => {
            for(let key in er){
              errors.push({
                field: key,
                msg: er[key],
              });
            }
          });
          dispatch(checkErrors(errors));
          
          dispatch(receivePrices(true, services, callback));
        });
        
        dispatch(hideLoader());
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}

export function checkOrderStep2(data, cb){
  return (dispatch) => {
    dispatch(clearOrderErrors());
    const errors = [];
    if(data.oferta_banku === null || typeof(data.oferta_banku) == 'undefined'){
      errors.push({field: 'oferta_banku', msg: 'Proszę wybrać ofertę banku.'});
      dispatch(checkErrors(errors));
      dispatch(hideLoader());
      return;
    }
    // if(data.oferta_banku_zmiana === null || typeof(data.oferta_banku_zmiana) == 'undefined'){
    //   errors.push({field: 'oferta_banku_zmiana', msg: 'Proszę określić możliwość zmiany metody preparatyki.'});
    //   dispatch(checkErrors(errors));
    //   dispatch(hideLoader());
    //   return;
    // }
    new Api(`/api.php?action=UmowaS2Edit${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post(data)
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            cb && cb();
          }else{
            const errors = [];
            json.ERRORS.ERROR_USERINFO.map(er => {
              for(let key in er){
                errors.push({
                  field: key,
                  msg: er[key],
                });
              }
            });
            dispatch(checkErrors(errors));
            
            dispatch(hideLoader());
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}