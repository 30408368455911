import styled from 'styled-components';
import { media } from './../../Styled/helpers';
import icon from './back.svg';

const BackButtonBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  ${media.mobile}{
    margin: 0;
  }
`;

const BackButtonIcon = styled.span`
  width: 56px;
  height: 56px;
  min-width: 56px;
  border-radius: 50%;
  background-color: ${props => props.theme.color[props.color]};
  background-image: url(${icon});
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 16px;
`;

export {BackButtonBox, BackButtonIcon};