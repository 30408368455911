import { combineReducers } from 'redux-immutable';
import { orderStep1 } from './orderStep1';
import { orderStep2 } from './orderStep2';
import { orderStep3 } from './orderStep3';
import { orderStep5 } from './medicalInterview';
import { orderStep5Questions } from './medicalInterview/questions';
import { additionalPackageTypes, additionalPackagesTitle, additionalPackagesDescription } from './packages/additional';
import { packageTypes } from './packages';
import { selectedAdditionalPackages } from './selected/additionalPackages';
import { selectedAdditionalPacks } from './selected/additionalPacks';
import { promotions } from './promotions';
import { selectedPromotions } from './selected/promotions';
import { userContracts } from './userContracts';
import { logged } from './logged';
import { loggedErrors } from './logged/Errors';
import { registered } from './registered';
import { userData } from './userData';
import { userDataErrors } from './userData/Errors';
import { userDataSuccess } from './userData/Success';
import { userAddressData } from './address';
import { addressErrors } from './address/Errors';
import { addressSuccess } from './address/Success';
import { hospitals } from './hospitals';
import { loader } from './loader';
import { prices } from './prices';
import { orderErrors } from './order/errors';
import { resetErrors } from './resetPassword/Errors';
import { reset } from './resetPassword';
import { knownFrom } from './knownFrom';
import { systemError } from './error';
import { services } from './services';
import { registrationData } from './registrationData';

const configuratorApp = combineReducers({
  orderStep1,
  orderStep2,
  orderStep3,
  orderStep5,
  orderStep5Questions,
  packageTypes,
  additionalPackageTypes,
  selectedAdditionalPackages,
  promotions,
  selectedPromotions,
  userContracts,
  logged,
  loggedErrors,
  registered,
  userData,
  userDataErrors,
  userDataSuccess,
  userAddressData,
  addressErrors,
  addressSuccess,
  hospitals,
  additionalPackagesTitle,
  additionalPackagesDescription,
  loader,
  prices,
  orderErrors,
  resetErrors,
  reset,
  knownFrom,
  systemError,
  selectedAdditionalPacks,
  services,
  registrationData,
});

export default configuratorApp;