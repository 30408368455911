import styled from 'styled-components';
import {ButtonBox} from './../../../components/Button/Styles';
import { media } from './../../../components/Styled/helpers';
import { Header6 } from './../../../components/Styled';

const PregnantPage = styled.div`
  ${ButtonBox}{
    align-self: center;
    justify-content: center;
    margin-top: 56px;
    margin-bottom: 80px;
    text-decoration: none;
    ${ media.mobile`
      margin: 16px 0 calc(20vh + 48px);
    `}
  }
`;

const ErrorBox = styled.span`
  ${ Header6 };
  color: ${props => props.theme.color.vermillion};
  margin-top: 16px;
`;

export {PregnantPage, ErrorBox};