import React, { Component, Fragment } from 'react';
import LogoComponent from '../../components/Logo';
import { FormContent } from './../../components/Styled/Content';
import { Redirect } from 'react-router-dom';
import { AddressPage, SummaryColumn, FormSection, InputPlaceholder, TabContentBox, TotallySummary, TabBox, TabHeader, TabIcon, ErrorBox, TabContent } from './Styles';
import HeadlineComponent from '../../components/Headline';
import ButtonComponent from './../../components/Button';
import StepsComponent from '../../components/Steps';
import InputComponent from '../../components/Input';
import LinkComponent from './../../components/Link';
import CheckboxComponent from '../../components/Checkbox';
import PromoInputComponent from '../../components/Input/Promo';
import {BoldText, Header5, Header1, SmallBoldText, SmallBoldText2, Text, Header4} from './../../components/Styled';
import SeparatorComponent from '../../components/Separator';
import FormHeadlineComponent from '../../components/FormHeadline';
import {YesNo, YesNoBox} from '../../components/YesNo/Styles';
import SelectComponent from '../../components/SelectKnown';
import {CheckboxDivider} from "../../components/Checkbox/Styles";

const options = [
  {
    name: 'Rodzaj ciąży',
    description: '',
    selected: [],
  },
  {
    name: 'Pakiety',
    description: '',
    selected: [],
  },
  {
    name: 'Przechowywanie',
    description: '',
    selected: [],
  },
  {
    name: 'Dane adresowe',
    description: '',
    selected: [],
  },
  {
    name: 'Dane medyczne',
    description: '',
    selected: [],
  }
]

class Summary extends Component{
  constructor(props){
    super(props);
    this.state = {
      nextStep: false,
      showPromoCode: false,
      promoCode: '',
      summaryOptions: options, 
      opened: [],
      user: {
        login:"",
        password:"",
        password2:"",
        imie:"",
        nazwisko:"",
        telefon:"",
        email:"",
        zgoda_m1:0,
        zgoda_m2:0,
        zgoda_m3:0
      }
    }
    this.handleChange = this.handleChange.bind(this);
    this.removeCheckedAgreement = this.removeCheckedAgreement.bind(this);
    this.addCheckedAgreement = this.addCheckedAgreement.bind(this);
    this.getStep2Data = this.getStep2Data.bind(this);
    this.saveContract = this.saveContract.bind(this);
    this.openTab = this.openTab.bind(this);
    this.getInputLabel = this.getInputLabel.bind(this);
    this.getInputValue = this.getInputValue.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.getValue = this.getValue.bind(this);
    this.goToContractsPage = this.goToContractsPage.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.setField = this.setField.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.updateStep5 = this.updateStep5.bind(this);
    window.scrollTo(0, 0);
  }

  componentDidMount(){
    this.props.clearAllErrors();
    this.setState({summaryOptions: this.options(this.props)});
  }

  componentWillReceiveProps(props){
    this.setState({summaryOptions: this.options(props)});
  }
  
  removeCheckedAgreement(checked){
    const stateCopy = Object.assign({}, this.state);
    stateCopy.user[checked] = 0;
    this.setState(stateCopy);
  }

  addCheckedAgreement(checked){
    const stateCopy = Object.assign({}, this.state);
    stateCopy.user[checked] = 1;
    this.setState(stateCopy);
  }
  
  setField(data){
    const stateCopy = Object.assign({}, this.state);
    stateCopy.user[data.field] = data.value;
    this.setState(stateCopy);
  }

  options(props){
    return [
      this.pregnantType(props),
      this.bankOffer(props),
      // this.bankOfferChange(props),
      null,
      this.promotion(props),
      this.addtionalPackages(props),
      this.contracts(props),
    ]
  }

  pregnantType(props){
    const pregnantType = props.orderStep1.rodzaj_ciazy;
    if(pregnantType != undefined && pregnantType.value != undefined){
      const name = pregnantType.MAP[pregnantType.value];
      const result = {
        name: name,
      }
      return result;
    }
  }

  get pregnantSummary(){
    return this.state.summaryOptions && this.state.summaryOptions.length > 0 ? (
      <TabContentBox>
        <FormSection summary>
          <BoldText>{this.state.summaryOptions[0].name}</BoldText>
        </FormSection>  
      </TabContentBox>
    ) : '';
  }

  bankOffer(props){
    const bankOffer = props.orderStep2.oferta_banku;
    const prices = props.prices['1'] || null;
    const pricesOffer = props.prices['oferty_banku'];

    const mapValueToName = {
      1: 0,
      2: 1,
      11: 2,
      13: 4,
      12: 3,
      14: 5
    }

    if(bankOffer != undefined && bankOffer.value != undefined && prices != null){
      const description = bankOffer.descriptions && bankOffer.descriptions[0].description || '';
      const name = bankOffer.MAP.filter((offer, index) => index == mapValueToName[bankOffer.value]);
      const price = prices['2'][0][1];
      const price2 = prices['2'][1][1];

      const bankOfferValue = bankOffer.value;
      let priceInit = 0;
      let priceMain = 0;

      if(bankOfferValue === 1 || bankOfferValue === 11 || bankOfferValue === 13) {
        priceInit += parseFloat(pricesOffer[0].cena1k)
        priceMain += parseFloat(pricesOffer[0].cena2k)
      }
      
      if(bankOfferValue === 2 || bankOfferValue === 12 || bankOfferValue === 14) {
        priceInit += parseFloat(pricesOffer[1].cena1k)
        priceMain += parseFloat(pricesOffer[1].cena2k)
      }
      if(bankOfferValue === 11 || bankOfferValue === 12){
        priceInit += parseFloat(pricesOffer[2].cena1k)
        priceMain += parseFloat(pricesOffer[2].cena2k)
      }
      if(bankOfferValue === 13 || bankOfferValue === 14){
        priceInit += parseFloat(pricesOffer[3].cena1k)
        priceMain += parseFloat(pricesOffer[3].cena2k)
      }

      return {
        name: name,
        description: description,
        selected: [
          {
            name: prices['2'][0].nazwa, 
            price: priceInit,
          },
          {
            name: prices['2'][1].nazwa, 
            price: priceMain,
          },
        ]
      }
    }else{
      return {
        name: 'Opłata wstępna',
        description: '',
        selected: []
      }
    }
  }

  get bankOfferSummary(){
    if(this.state.summaryOptions &&
      this.state.summaryOptions.length > 0 &&
      this.state.summaryOptions[1] &&
      this.state.summaryOptions[1].selected){
        const selected = this.state.summaryOptions[1].selected.map((s, index) => {
          return (<BoldText key={index}>{s.name} - {s.price} zł</BoldText>);
        });
        return (
          <TabContentBox>
            <FormSection summary>
              <BoldText>{`Wybrane pakiety`}</BoldText>
              <Text>{this.state.summaryOptions[1].name}</Text>
              { selected }
            </FormSection>
          </TabContentBox>
        )
      }else{
        return '';
      }
  }

  bankOfferChange(props){
    const bankOFfer = props.orderStep2.oferta_banku_zmiana;
    if(bankOFfer != undefined && bankOFfer.value != undefined){
      const name = bankOFfer.MAP[bankOFfer.value];
      const result = {
        name: name,
      }
      return result;
    }else{
      return null;
    }
  }

  get bankOfferChangeSummary(){
    return this.state.summaryOptions && this.state.summaryOptions.length >= 2 && this.state.summaryOptions[2] !== null ? (
      <TabContentBox>
        <FormSection summary>
          <BoldText>{this.props.orderStep2.oferta_banku_zmiana ? this.props.orderStep2.oferta_banku_zmiana.label : ''}</BoldText>
          {this.state.summaryOptions[2].name}
        </FormSection>
      </TabContentBox>
    ) : '';
  }

  get promoCodeSummary() {
    return this.props.orderStep2.promokod && this.props.orderStep2.promokod.value && this.props.orderStep2.promokod.value.length ? (
      <TabContentBox>
        <FormSection summary>
          <BoldText>Kod rabatowy - Pracownik Biura skontaktuje się z Państwem w celu zatwierdzenia spersonalizowanego cennika.</BoldText>
          <Text>{ this.props.orderStep2.promokod.value}</Text>
        </FormSection>
      </TabContentBox>
    ) : '';
  }

  
  promotion(props){
    const promotion = props.promotions.filter(promo => promo.id == props.selectedPromotions);
    if(promotion != undefined && promotion.length === 1){
      const name = promotion[0].nazwa;
      const result = {
        name: name,
      }
      return result;
    }
  }

  get promotionSummary(){
    return this.state.summaryOptions && this.state.summaryOptions.length >= 3 && typeof(this.state.summaryOptions[3]) != 'undefined' && this.state.summaryOptions[3].name &&  typeof(this.props.prices.PromocjaInfo) != 'undefined'  && this.props.prices.PromocjaInfo.uzyte !== 'N' && this.props.prices.PromocjaInfo.nazwa.length > 0 ? (
      <TabContentBox>
        <FormSection summary>
        <BoldText>{this.props.orderStep3.promocja_id ? this.props.orderStep3.promocja_id.label : ''}</BoldText>
         {this.state.summaryOptions[3].name}
        </FormSection>
      </TabContentBox>
    ) : '';
  }
  
  addtionalPackages(props){
    if(props.selectedAdditionalPackages != undefined && props.selectedAdditionalPackages.length){
      const prices = props.prices['2'];
      const prices2 = props.prices['2sz'];
      const selected = props.selectedAdditionalPackages.length ? props.selectedAdditionalPackages.map(pack => {
        const price = (name) => {
          return prices[2].filter(p => {
            return p[0] === name;
          })[0][1];
        }
        const price2 = (name) => prices2 ? prices2[2].filter(pack => pack[0] === name) : [{0: name, 1: "0"}];
        return {
          name: pack.name,
          price: `${parseFloat(price(pack.name)) + parseFloat(price2(pack.name)[0][1])}`,
          hidePayments: pack.hidePayments
        }
      }) : [];
    
      return {
        name: prices[0],
        description: '',
        selected: selected
      }
    }else{
      return {
        name: 'Usługi dodatkowe',
        description: '',
        selected: []
      }
    }
  }

  get additionalPackagesSummary(){
    if(this.state.summaryOptions &&
      this.state.summaryOptions.length > 0 &&
      this.state.summaryOptions[4] &&
      this.state.summaryOptions[4].selected){
        const selected = this.state.summaryOptions[4].selected.map((s, index) => {
          if(s.hidePayments){
            return (<Text key={index}>{s.name} - Płatne bezpośrednio u dostawcy usługi</Text>);
          }
          const price = s.price !== '0' ?  `- ${s.price} zł` : '';
          return (<Text key={index}>{`${s.name} ${price}`}</Text>);
        });
        return (
          <TabContentBox>
            <FormSection summary>
             <BoldText>{this.props.orderStep3.uslugi_dodatkowe_new ? this.props.orderStep3.uslugi_dodatkowe_new.label : ''}</BoldText> 
              { selected }
            </FormSection>
          </TabContentBox>
        )
      }else{
        return '';
      }
  }

  contracts(props){
    const contract = props.orderStep3.przedplata;
    const prices = props.prices['3'] || null;
    const prices2 = props.prices['3sz'] || null;
    if(contract != undefined && contract.value != undefined && prices != null){
      const contracts = [];
      for(let key in contract.MAP){
        contracts.push(parseInt(key));
      }
      const contractIndex = contracts.indexOf(parseInt(contract.value));
      const description = contract.descriptions && contract.descriptions[0].description || '';
      const name = prices['2'][contractIndex][0]
      const price = prices['2'][contractIndex][1];
      const price2 = prices2 ? prices2['2'][contractIndex][1] : 0;
      return {
        name: prices[0],
        description: description,
        selected: [
          {
            name: name, 
            price: parseFloat(price) + parseFloat(price2),
          }
        ]
      }
    }else{
      return {
        name: 'Przechowywanie',
        description: '',
        selected: []
      }
    }
  }

  get contractsSummary(){
    if(this.state.summaryOptions &&
      this.state.summaryOptions.length > 0 &&
      this.state.summaryOptions[5] &&
      this.state.summaryOptions[5].selected){
        const selected = this.state.summaryOptions[5].selected.map((s, index) => {
          return (<BoldText key={index}>{s.name} - {s.price} zł</BoldText>);
        })
        const pricing =  this.props.orderStep3.przedplata.value === 1 ? (
          <BoldText> {this.props.prices['4'][0]} - {this.props.prices['4'][2][0][1]}</BoldText>
        ) : '';
        return (
          <TabContentBox>
            <FormSection summary>
              { selected }
            </FormSection>
          </TabContentBox>
        )
      }else{
        return '';
      }
  }

  getInputLabel(key, parent = null){
    if(!parent){
      return this.props.userAddressData && this.props.userAddressData[key] ? this.props.userAddressData[key].label : '';
    }
    if(this.props.userAddressData && this.props.userAddressData[parent]){
      const filtered = this.getField(key, parent);
      return filtered && filtered.label || '';
    }
    return '';
  }

  getField(field, parent) {
    const fields = this.props.userAddressData[parent].fields;
    return fields[field] || null;
  }

  getInputValue(key, parent = null){
    if(!parent){
      return this.props.userAddressData && this.props.userAddressData[key] ? this.props.userAddressData[key].value : '';
    }
    if(this.props.userAddressData && this.props.userAddressData[parent]){
      const filtered = this.getField(key, parent);
      return filtered && filtered.value || '';
    }
    return '';
  }

  getValueContact(key) {
    const adrContactValue = {
      1: 'adr_id_matka_koresp',
      2: 'adr_id_ojciec_koresp',
    };
    const parent = this.props.userAddressData.adr_id_umowa_koresp.value ? adrContactValue[this.props.userAddressData.adr_id_umowa_koresp.value] : null;
    if (parent === null) return '';
    if(this.props.userAddressData && this.props.userAddressData[parent]){
      const filtered = this.getField(key, parent);
      return filtered && filtered.value || '';
    }
  }

  get addressSummary(){
    return (
      <TabContentBox>
        <FormSection>
          <FormHeadlineComponent mother label="Dane matki dziecka" />
            <Header5>Dane personalne</Header5>
            <InputComponent disabled id="fname" label={ this.getInputLabel('fname')} value={this.getInputValue('fname')} type="text" />
            <InputComponent disabled id="lname" label={ this.getInputLabel('lname')} value={this.getInputValue('lname')} type="text" />
            <InputComponent disabled id="PESEL" label={ this.getInputLabel('PESEL')} value={this.getInputValue('PESEL')} type="text" />
          </FormSection>
          <FormSection>
            <Header5>Adres zamieszkania</Header5>
            <InputComponent id="adr_id_matka_meldunek.2" disabled label={ this.getInputLabel('2', 'adr_id_matka_meldunek')}value={this.getInputValue('2', 'adr_id_matka_meldunek')} type="text" />
            <InputComponent id="adr_id_matka_meldunek.1" disabled label={ this.getInputLabel('1', 'adr_id_matka_meldunek')} value={this.getInputValue('1', 'adr_id_matka_meldunek')} type="text" valueType="postalCode" />
            <InputComponent id="adr_id_matka_meldunek.3" disabled label={ this.getInputLabel('3', 'adr_id_matka_meldunek')} value={this.getInputValue('3', 'adr_id_matka_meldunek')} type="text" />
            <InputComponent id="adr_id_matka_meldunek.4" disabled label={ this.getInputLabel('4', 'adr_id_matka_meldunek')} value={this.getInputValue('4', 'adr_id_matka_meldunek')} type="text" />
            <InputComponent id="adr_id_matka_meldunek.5" disabled label={ this.getInputLabel('5', 'adr_id_matka_meldunek')} value={this.getInputValue('5', 'adr_id_matka_meldunek')} type="text" />
          </FormSection>
          <FormSection>
            <Header5>Kontakt</Header5>
            <InputComponent disabled id="telefon" label={ this.getInputLabel('telefon')} value={this.getInputValue('telefon')} type="text" />
            <InputComponent disabled id="email" label={ this.getInputLabel('email')} value={this.getInputValue('email')} type="mail" />
          </FormSection>
          <FormSection>
            <Header5>{ this.getLabel('adr_id_matka_koresp') }</Header5>
            <InputComponent id="adr_id_matka_koresp.2" disabled label={ this.getInputLabel('2', 'adr_id_matka_koresp')}value={this.getInputValue('2', 'adr_id_matka_koresp')} type="text" />
            <InputComponent id="adr_id_matka_koresp.1" disabled label={ this.getInputLabel('1', 'adr_id_matka_koresp')} value={this.getInputValue('1', 'adr_id_matka_koresp')} type="text" valueType="postalCode" />
            <InputComponent id="adr_id_matka_koresp.3" disabled label={ this.getInputLabel('3', 'adr_id_matka_koresp')} value={this.getInputValue('3', 'adr_id_matka_koresp')} type="text" />
            <InputComponent id="adr_id_matka_koresp.4" disabled label={ this.getInputLabel('4', 'adr_id_matka_koresp')} value={this.getInputValue('4', 'adr_id_matka_koresp')} type="text" />
            <InputComponent id="adr_id_matka_koresp.5" disabled label={ this.getInputLabel('5', 'adr_id_matka_koresp')} value={this.getInputValue('5', 'adr_id_matka_koresp')} type="text" />
          </FormSection>
          
          <FormSection>
            <FormHeadlineComponent father label="Dane ojca dziecka" />
            <Header5>Dane personalne</Header5>
            <InputComponent disabled id="fname_ojca" label={ this.getInputLabel('fname_ojca')} value={this.getInputValue('fname_ojca')} type="text" />
            <InputComponent disabled id="lname_ojca" label={ this.getInputLabel('lname_ojca')} value={this.getInputValue('lname_ojca')} type="text" />
            <InputComponent disabled id="PESEL_ojca" label={ this.getInputLabel('PESEL_ojca')} value={this.getInputValue('PESEL_ojca')} type="text" />
          </FormSection>
          <FormSection>
            <Header5>Adres zamieszkania</Header5>
            <InputComponent id="adr_id_ojciec_meldunek.2" disabled label={ this.getInputLabel('2', 'adr_id_ojciec_meldunek')}value={this.getInputValue('2', 'adr_id_ojciec_meldunek')} type="text" />
            <InputComponent id="adr_id_ojciec_meldunek.1" disabled label={ this.getInputLabel('1', 'adr_id_ojciec_meldunek')} value={this.getInputValue('1', 'adr_id_ojciec_meldunek')} type="text" valueType="postalCode" />
            <InputComponent id="adr_id_ojciec_meldunek.3" disabled label={ this.getInputLabel('3', 'adr_id_ojciec_meldunek')} value={this.getInputValue('3', 'adr_id_ojciec_meldunek')} type="text" />
            <InputComponent id="adr_id_ojciec_meldunek.4" disabled label={ this.getInputLabel('4', 'adr_id_ojciec_meldunek')} value={this.getInputValue('4', 'adr_id_ojciec_meldunek')} type="text" />
            <InputComponent id="adr_id_ojciec_meldunek.5" disabled label={ this.getInputLabel('5', 'adr_id_ojciec_meldunek')} value={this.getInputValue('5', 'adr_id_ojciec_meldunek')} type="text" />
          </FormSection>
          <FormSection>
            <Header5>Kontakt</Header5>
            <InputComponent disabled id="telefon_ojca" label={ this.getInputLabel('telefon_ojca')} value={this.getInputValue('telefon_ojca')} type="text" />
            <InputComponent disabled id="email_ojca" label={ this.getInputLabel('email_ojca')} value={this.getInputValue('email_ojca')} type="mail" />
          </FormSection>
        <FormSection>
          <Header5>{ this.getLabel('adr_id_matka_koresp') }</Header5>
          <InputComponent id="adr_id_ojciec_koresp.2" disabled label={ this.getInputLabel('2', 'adr_id_ojciec_koresp')}value={this.getInputValue('2', 'adr_id_ojciec_koresp')} type="text" />
          <InputComponent id="adr_id_ojciec_koresp.1" disabled label={ this.getInputLabel('1', 'adr_id_ojciec_koresp')} value={this.getInputValue('1', 'adr_id_ojciec_koresp')} type="text" valueType="postalCode" />
          <InputComponent id="adr_id_ojciec_koresp.3" disabled label={ this.getInputLabel('3', 'adr_id_ojciec_koresp')} value={this.getInputValue('3', 'adr_id_ojciec_koresp')} type="text" />
          <InputComponent id="adr_id_ojciec_koresp.4" disabled label={ this.getInputLabel('4', 'adr_id_ojciec_koresp')} value={this.getInputValue('4', 'adr_id_ojciec_koresp')} type="text" />
          <InputComponent id="adr_id_ojciec_koresp.5" disabled label={ this.getInputLabel('5', 'adr_id_ojciec_koresp')} value={this.getInputValue('5', 'adr_id_ojciec_koresp')} type="text" />
        </FormSection>

        <FormSection>
          <FormHeadlineComponent address label={ this.getInputLabel('adr_id_umowa_koresp') } />
          <CheckboxDivider/>
          <CheckboxComponent withoutMargins isChecked={this.getInputValue('adr_id_umowa_koresp') === 1} disabled option={1} label={this.props.userAddressData.adr_id_umowa_koresp && this.props.userAddressData.adr_id_umowa_koresp.MAP[1] || ''} name={`checkbox-address-mother`}  />
          <CheckboxComponent withoutMargins isChecked={this.getInputValue('adr_id_umowa_koresp') === 2} disabled option={2}  label={this.props.userAddressData.adr_id_umowa_koresp && this.props.userAddressData.adr_id_umowa_koresp.MAP[2] || ''}name={`checkbox-address-father`}  />
          <CheckboxDivider/>
          <InputComponent disabled label={ this.getInputLabel('2', 'adr_id_ojciec_koresp')} value={this.getValueContact('2')} type="text" />
          <InputComponent disabled label={ this.getInputLabel('1', 'adr_id_ojciec_koresp')} value={this.getValueContact('1')} type="text" valueType="postalCode" />
          <InputComponent disabled label={ this.getInputLabel('3', 'adr_id_ojciec_koresp')} value={this.getValueContact('3')} type="text" />
          <InputComponent disabled label={ this.getInputLabel('4', 'adr_id_ojciec_koresp')} value={this.getValueContact('4')} type="text" />
          <InputComponent disabled label={ this.getInputLabel('5', 'adr_id_ojciec_koresp')} value={this.getValueContact('5')} type="text" />
        </FormSection>
      </TabContentBox>
    )
  } 

  get medicalInterviewSmmary(){
    return (
      <TabContentBox>
        <FormSection>
          { this.illnesses }
        </FormSection>
      </TabContentBox>
    )
  }

  
  get illnesses(){
    const illnessesList = [];
    for(let i = 0; i < this.props.orderStep5Questions.length; i++){
      const question = this.props.orderStep5Questions[i];
      if(question.type === 'hidden'){
        continue;
      }
      if(question.type === 'IntStringMap'){
        const options = [];
        for(let key in question.MAP){
          if(question.MAP[key] !== ' '){
            options.push({
              value: key,
              label: question.MAP[key],
            })
          }
        }
        const radios = typeof(options[question.value]) != 'undefined' ? options[question.value].label : '';
        const isInputAvailable = i+1 < this.props.orderStep5Questions.length && this.props.orderStep5Questions[i+1].name === `${question.name}_data`;
        const input = ((question.value == "1" && typeof(question.notRelated) == 'undefined') || (question.value == "0" && question.notRelated == 1)) && isInputAvailable ? <InputComponent disabled type="text" label={this.props.orderStep5Questions[i+1].label} value={this.props.orderStep5Questions[i+1].value} id={this.props.orderStep5Questions[i+1].name} /> : '';
        const inputs = (
          <Fragment>
            {question.apigrp1 !== null && question.apigrp2 !== null ? <SeparatorComponent half /> : ''}
            <YesNo>
              {question.apigrp1 !== null && typeof(question.apigrp1) != 'undefined' ? <Header4 dangerouslySetInnerHTML={{ __html: question.apigrp1.split("&nbsp;").join(" ")}}></Header4> : ''}
              {question.apigrp2 !== null && typeof(question.apigrp2) != 'undefined' ? <Header4 dangerouslySetInnerHTML={{ __html: question.apigrp2.split("&nbsp;").join(" ")}}></Header4> : ''}
              {question.label !== question.apigrp1 && question.label !== question.apigrp2 && typeof(question.label) != 'undefined' ? <Header5 dangerouslySetInnerHTML={{ __html: question.label.split("&nbsp;").join(" ") }}></Header5> :  <SeparatorComponent half /> }
              <YesNoBox>
                {radios}
              </YesNoBox>
              {input}
            </YesNo>
          </Fragment>
        )
        illnessesList.push(inputs);
        if(isInputAvailable){
          i++;
        }
      }
    }
    return illnessesList;
  }
  
  getLabel(key){
    return this.props.orderStep5 && this.props.orderStep5[key] ? this.props.orderStep5[key].label : '';
  }

  getValue(key){
    return this.props.orderStep5 && this.props.orderStep5[key] ? this.props.orderStep5[key].value : '';
  }

  handleChange(data, name){
    this.props.setOrderStep5(data, name);
  }

  getStep2Data(props){
    return {
      promokod: this.state.promoCode,
      oferta_banku: props.orderStep2.oferta_banku.value,
      oferta_banku_zmiana: props.orderStep2.oferta_banku_zmiana ? props.orderStep2.oferta_banku_zmiana.value : null,
      pakiety_dodatkowe: props.selectedAdditionalPacks.join('|'),
    }
  }

  get redirector(){
    if(typeof(this.props.orderStep1.rodzaj_ciazy) == 'undefined'){
      window.location =  document.getElementById('root').dataset.baseUrl;
      return '';
    }
    return '';
  }
 
  get error(){
    const orderErrors = this.props.orderErrors && this.props.orderErrors.map((error, i) => {
      return <ErrorBox key={i}>{ error.msg }</ErrorBox>;
    });

    const loggedErrors = this.props.errors && this.props.errors.length > 0 ? (
      <ErrorBox key={0} dangerouslySetInnerHTML={{__html: this.props.errors[0]}}></ErrorBox>
    ) : '';

    return orderErrors.concat(loggedErrors);
  }

  get renderNextStep(){
    return this.state.nextStep && (
      <Redirect to='/contracts' />
    )
  }

  handleButtonClick(){
    this.updateStep5(() => {
      if(!this.props.logged){
        this.registerUser()
      }else{
        this.saveContract();
      }
    });

  }

  updateStep5(callback){
    const data = {};
    for(let key in this.props.orderStep5){
      if(this.props.orderStep5.hasOwnProperty(key)){
        data[key] = this.props.orderStep5[key].value;
      }
    }
    this.props.updateOrderStep5(data, () => {
      callback && callback();
    });
  }

  registerUser(){
    this.props.registerUser(this.state.user, this.saveContract);
  }

  saveContract(){
    this.props.saveContract(this.goToContractsPage);
  }
  
  goToContractsPage(){
    const baseUrl = document.getElementById('root').dataset.baseUrl;
    window.location = `${baseUrl}/contracts` 
  }

  openTab(id){
    const include = this.state.opened.indexOf(id);
    const state = include >= 0 ? this.state.opened.filter(arrayItem => arrayItem !== id) : [ ...this.state.opened, id ];
    this.setState({opened: state});
  }

  get overallPrice(){
    const sum = this.state.summaryOptions.reduce((sum, item) => {
      const itemSum = item !== null && item.selected ? item.selected.reduce((is, i) => {
        const price = i.price ? i.price : 0;
        return is + parseInt(price);
      }, 0) : 0;
      return sum + parseInt(itemSum);
    }, 0);
    return `${sum} zł`;
  }

  get registrationForm(){
    return !this.props.logged && (
      <Fragment>
        <HeadlineComponent>Rejestracja</HeadlineComponent>
        <FormSection>
          <InputComponent required changeHandler={this.setField} id="login" label="Login" value={this.state.login} type="text" />
          <InputPlaceholder />
          <InputComponent required changeHandler={this.setField} id="imie" label="Imię" value={this.state.imie} type="text" />
          <InputComponent required changeHandler={this.setField} id="nazwisko" label="Nazwisko" value={this.state.nazwisko} type="text" />
          <InputComponent required changeHandler={this.setField} id="email" label="Email" value={this.state.email} type="text" />
          <InputComponent required changeHandler={this.setField} id="telefon" label="Telefon" value={this.state.telefon} type="text" />
          <InputComponent required changeHandler={this.setField} id="password" label="Hasło" value={this.state.password} type="password" />
          <InputComponent required changeHandler={this.setField} id="password2" label="Powtórz hasło" value={this.state.password2} type="password" />
        </FormSection>

        <FormSection checkboxes>
          <CheckboxComponent registration isChecked={this.state.user.zgoda_m1} addChecked={this.addCheckedAgreement} removeChecked={this.removeCheckedAgreement} option="zgoda_m1" smallFont label={this.props.registrationData && typeof(this.props.registrationData) != 'undefined' ? this.props.registrationData.zgoda_m1 : ''} />
          <CheckboxComponent registration isChecked={this.state.user.zgoda_m2} addChecked={this.addCheckedAgreement} removeChecked={this.removeCheckedAgreement} option="zgoda_m2"  smallFont label={this.props.registrationData && typeof(this.props.registrationData) != 'undefined' ? this.props.registrationData.zgoda_m2 : ''} />
          <CheckboxComponent registration isChecked={this.state.user.zgoda_m3} addChecked={this.addCheckedAgreement} removeChecked={this.removeCheckedAgreement} option="zgoda_m3"  smallFont label={this.props.registrationData && typeof(this.props.registrationData) != 'undefined' ? this.props.registrationData.zgoda_m3 : ''} />
        </FormSection>
      </Fragment>
    )
  }

  get summary() {
    if(this.state.summaryOptions && this.state.summaryOptions[5]){
      const contracts = () => {
        if(this.props.orderStep3.przedplata.value != 1){
          return <SmallBoldText>Abonament: {this.props.orderStep3.przedplata.MAP[this.props.orderStep3.przedplata.value]}</SmallBoldText>
        }
        return '';
      }
      const contractsPrice = this.state.summaryOptions[5].selected[0].price
      const additionalServices = this.state.summaryOptions[4].selected.map(s => <SmallBoldText>{s.name}</SmallBoldText>);
      const additionalServicesPrice = this.state.summaryOptions[4].selected.reduce((sum, selected) => { return sum + parseInt(selected.price)}, 0) + parseInt(this.state.summaryOptions[1].selected[1].price);
      
      const overallPrice = additionalServicesPrice + parseInt(this.state.summaryOptions[1].selected[0].price) + parseInt(contractsPrice);
  
      const contract = () => {
        return this.props.orderStep3.przedplata.value == 1 ? (
          <SummaryColumn>
            <Header5>Opłata za pierwszy rok</Header5>
            <SmallBoldText2>Płatność po 12 miesiącach od porodu</SmallBoldText2>
            <SmallBoldText>{ this.props.prices['4'][0]}</SmallBoldText>
            <Header5>{this.props.prices['4'][2][0][1] } zł</Header5>
          </SummaryColumn>  
        ) : <SummaryColumn>
            <Header5>Opłata za przechowywanie</Header5>
            <SmallBoldText2>Płatność do 70 dni po porodzie</SmallBoldText2>
            { contracts() }
            <Header5>{contractsPrice} zł</Header5>
          </SummaryColumn> ;
      }
      
      return (
        <TotallySummary>
          <SummaryColumn>
            <Header5>Opłata wstępna</Header5>
            <SmallBoldText>Płatna do 3 dni od podpisania uwowy</SmallBoldText>
            <Header5>{this.state.summaryOptions[1].selected[0].price} zł</Header5>
          </SummaryColumn>
          <SummaryColumn>
            <Header5>Opłata właściwa</Header5>
            <SmallBoldText2>Płatność do 56 dni po porodzie</SmallBoldText2>
            { additionalServices }
            <Header5>{additionalServicesPrice} zł</Header5>
          </SummaryColumn>
          { contract() }
          <SummaryColumn black>
            <Header5>Całkowity koszt</Header5>
            <Header1>{ overallPrice } zł</Header1>
          </SummaryColumn>
        </TotallySummary>
      )
    }
    return '';
  }

  render(){
    return(
      <AddressPage className="content">
        <FormContent centred full>
          <LogoComponent />
          <StepsComponent active="6" />

          <HeadlineComponent>Podsumowanie</HeadlineComponent>
          <FormSection>
            <TabBox>
              <TabHeader onClick={() =>{ this.openTab(0) }}>
                <Text>Rodzaj ciąży</Text>
                <TabIcon open={this.state.opened.indexOf(0) >= 0} />
              </TabHeader>
              <TabContent open={this.state.opened.indexOf(0) >= 0}>
                { this.pregnantSummary }
              </TabContent>
            </TabBox>
            <TabBox>
              <TabHeader onClick={() =>{ this.openTab(1) }}>
                <Text>Pakiety</Text>
                <TabIcon open={this.state.opened.indexOf(1) >= 0} />
              </TabHeader>
              <TabContent open={this.state.opened.indexOf(1) >= 0}>
                { this.bankOfferSummary }
                {/* { this.bankOfferChangeSummary } */}
                { this.promotionSummary }
                { this.promoCodeSummary }
                { this.additionalPackagesSummary }
              </TabContent>
            </TabBox>
            <TabBox>
              <TabHeader onClick={() =>{ this.openTab(2) }}>
                <Text>Przechowywanie</Text>
                <TabIcon open={this.state.opened.indexOf(2) >= 0} /> 
              </TabHeader>
              <TabContent open={this.state.opened.indexOf(2) >= 0}>
                { this.contractsSummary }
              </TabContent>
            </TabBox>
            <TabBox>
              <TabHeader onClick={() =>{ this.openTab(3) }}>
                <Text>Dane adresowe</Text>
                <TabIcon open={this.state.opened.indexOf(3) >= 0} />
              </TabHeader>
              <TabContent open={this.state.opened.indexOf(3) >= 0}>
                { this.addressSummary }
              </TabContent>
            </TabBox>
            <TabBox>
              <TabHeader onClick={() =>{ this.openTab(4) }}>
                <Text>Wywiad medyczny</Text>
                <TabIcon  open={this.state.opened.indexOf(4) >= 0} />
              </TabHeader>
              <TabContent  open={this.state.opened.indexOf(4) >= 0}>
                { this.medicalInterviewSmmary }
              </TabContent>
            </TabBox>
          </FormSection>
          <SeparatorComponent />
          { this.registrationForm }
          <SeparatorComponent />
          <HeadlineComponent subheadline="Pola są nieobowiązkowe">Pozostałe informacje</HeadlineComponent>
          <FormSection>
            <SelectComponent changeHandler={this.handleChange} id="dowiedzieli_from_int" label={ this.getLabel('dowiedzieli_from_int')} name="dowiedzieli_from_int" options={this.props.knownFrom} value={this.getValue('dowiedzieli_from_int')} />
            <InputPlaceholder />
            <InputComponent changeHandler={this.handleChange} id="dowiedzieli_from" label={this.getLabel('dowiedzieli_from')} value={this.getValue('dowiedzieli_from')} type="text" />
            <InputPlaceholder />
            <InputComponent changeHandler={this.handleChange} id="uwagi" label={this.getLabel('uwagi')} value={this.getValue('uwagi')} type="text" />
            <InputPlaceholder />
          </FormSection>
            {this.summary}
          { this.error }
          <ButtonComponent primary label="Zakończ" click={this.handleButtonClick} />
        </FormContent>
        { this.redirector }
        { this.renderNextStep }
      </AddressPage>
    )
  }
}

export default Summary;