import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {Header2} from './../../../components/Styled';
import { grid, media } from '../../../components/Styled/helpers';


const SignInPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: flex-start;
  width: ${props => grid(8)};
  ${Header2}{
    text-align: center;
    margin-top: 102px;
    margin-bottom: 144px;
  }
  a{
    text-decoration: none;
    margin: 80px 0 56px;     
    color: ${props => props.theme.color.black}
    ${media.mobile`
      margin: 24px 0 56px;
    `}
  }
`;


export {SignInPage}