import styled from 'styled-components';
import tooltip from './tooltip.svg';
import { SmallText } from '../../Styled';

const TooltipInfo = styled.span`
  width: max-content;
  height: auto;
  padding: 8px 24px;
  background-color: ${props => props.theme.color.black};
  color: ${props => props.theme.color.white};
  position: absolute;
  display: none;
  top: 28px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 3px;
  text-align: center;
  flex-wrap: wrap;
  max-width: 300px;
  &:before{
    width: 12px;
    height: 12px;
    content: '';
    top: -1px;
    left: calc(50% - 6px);
    position: absolute;
    border-radius: 0;
    transform: rotateZ(45deg);
    background-color: ${props => props.theme.color.black};
  }
  ${SmallText}{
    white-space: normal;
    display: inline-flex;
    display: flex;
    flex-direction: column;
  }
`;

const Tooltip = styled.div`
  display: flex;
  position: relative;
  margin-left: 16px;
  &:hover{
    ${TooltipInfo}{
      display: flex;
      z-index: 99999;
    }
  }
`;

const TooltipIcon = styled.span`
  background-image: url(${tooltip});
  width: 24px;
  height: 24px;
  display: flex;
`;

export {Tooltip, TooltipIcon, TooltipInfo}
