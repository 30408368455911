import styled from 'styled-components';
import {h0, h1, h2, h3, h4, h5, h6, font, fontSmall, fontMiddle} from './Fonts';

const Header0 = styled.h1`
  ${props => h0(props.theme)}
`;

const Header1 = styled.h1`
  ${props => h1(props.theme)}
`;

const Header2 = styled.h2`
  ${props => h2(props.theme)}
`;

const Header3 = styled.h3`
  ${props => h3(props.theme)}
`;

const Header4 = styled.h4`
  ${props => h4(props.theme)}
`;

const Header5 = styled.h5`
  ${props => h5(props.theme)}
  strong {
    font-weight: bold;
  }
`;

const Header6 = styled.h6`
  ${props => h6(props.theme)}
`;

const Text = styled.span`
  ${props => font(props.theme)}
`;

const BoldText = styled.span`
  ${props => font(props.theme)};
  font-weight: bold;
  background-color: ${props => props.backgroundDuckEggBlue ? props.theme.color.duckEggBlue : 'transparent'};
  padding: ${props => props.backgroundDuckEggBlue ? '16px 32px' : '0px'};
`;

const SmallText = styled.span`
  ${props => fontSmall(props.theme)};
  text-decoration: ${props => props.underline ? 'underline' : ''};
  text-transform: ${props => props.uppercase ? 'uppercase' : ''};
  display: ${props => props.fullline ? 'flex': 'inline-flex'};
  justify-content:  ${props => props.fullline ? 'flex-start' : 'center'};
`;

const MiddleText = styled.span`
  ${props => fontMiddle(props.theme)}
`;


const SmallBoldText = styled.span`
  ${props => fontSmall(props.theme)};
  font-weight: bold;
`;

const SmallBoldText2 = styled.span`
  ${props => fontSmall(props.theme)};
  font-weight: bold;
  margin-bottom: 8px;
`;

const StrikeText = styled.span`
  text-decoration: line-through;
`

export {
  Header0, 
  Header1, 
  Header2, 
  Header3,
  Header4,
  Header5,
  Header6,
  Text,
  BoldText,
  SmallText,
  SmallBoldText,
  SmallBoldText2,
  StrikeText,
  MiddleText
}