import styled from 'styled-components';
import {ButtonBox} from './../../../components/Button/Styles';
import {grid, media} from './../../../components/Styled/helpers';
import { Header5, Header6, BoldText, Header1, SmallText, SmallBoldText } from '../../../components/Styled';
import { InputBox, InputLabel } from '../../../components/Input/Styles';
import { CheckboxBox } from '../../../components/Checkbox/Styles';
import { SelectBox } from '../../../components/SelectKnown/Styles';
import { LinkBox } from '../../../components/Link/Styles';
import code from './code.svg';
import tab from './tab.svg';

const AddressPage = styled.div`
  width: 100%;
   ${ButtonBox}{
    align-self: center;
    justify-content: center;
    margin-top: 56px;
    margin-bottom: 80px;
  }
  
  ${BoldText}{
    margin: 0 0 64px; 
    a{
      color: inherit;
      text-decoration: underline;
    }
  }
  ${CheckboxBox}{
    margin: 8px 0;
    width: 100%;
  }

`;

const InputPlaceholder = styled.div`
  display: flex;
  width: calc(50% - 8px);
`;

const TotallySummary = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
  background-color: ${props => props.theme.color.duckEggBlue};
  color: ${props => props.theme.color.white};
  margin: 80px 0 0;

  ${Header1}{
    margin: 8px 0 32px;
  }
  ${ media.mobile`
    width: 100%;
    flex-direction: column;
  `}
`;

const SummaryColumn = styled.div`
  flex: 1 1 auto;
  background-color: ${props => props.black ? props.theme.color.black : props.theme.color.duckEggBlue};
  color: ${props => props.black ? props.theme.color.white : props.theme.color.black};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${props => props.black ? 'center' : 'flex-start'};
  padding: 56px 16px;
  text-align: center;
  ${Header5}{
    margin: 8px 0;
  }
  ${SmallBoldText}{
    margin: ${props => props.marginBottom ? '0px 0px 8px' : '0px'};
  }
  ${ media.mobile`
    width: 100%;
    padding: 40px 24px;
    box-sizing: border-box;
  `}
`

const TotallyCode = styled.div`
  width: ${grid(4)};
  padding: 20px 0;
  color: ${props => props.theme.color.white};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  &:before{
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.color.white};
    position: absolute;
    top: 0;
    left: 0;
    opacity: .2;
    content: '';
  }
  ${LinkBox}{
    color: ${props => props.theme.color.white};
    background-color: transparent;
    height: 50px;
    padding: 0 24px;
  }
  ${InputLabel}{
    color: ${props => props.theme.color.white}
  }
  ${SmallText}{
    cursor: pointer;
  }
`;

const CodeIcon = styled.span`
  width: 24px;
  height: 24px;
  min-width: 24px;
  background-image: url(${code});
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 8px;
`

const FormSection = styled.div`
  width: calc(${grid(10)} - 48px);
  display: flex;
  flex-direction: ${props => props.summary ? 'column' : 'row'}
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: ${props => props.checkboxes ? '72px 0 0 48px' : '0 0 0 48px'};
  padding: ${props => props.summary ? '0 0 32px 0' : '0'};
  ${SelectBox}{
    width: calc(50% - 8px) !important;
    ${ media.mobile`
      width: 100% !important;
    `}
  }
  ${Header5}{
    width: 100%;
    margin: 40px 0 24px;
  }
  ${InputBox}{
    width: calc(50% - 8px);
    position: relative;
    
    ${ media.mobile`
      width: 100%;
    `}
  }
  ${BoldText}{
    margin-bottom: 0;
  }
  
  ${ media.mobile`
    width: 100%;
    margin: 0;
  `}
`;

const TabBox = styled.div`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.color.white3};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const TabHeader = styled.div`
  color: ${props => props.theme.color.black};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 0;
  cursor: pointer;
`;

const TabIcon = styled.span`
  width: 24px;
  height: 24px;
  min-width: 24px;
  margin-left: 8px;
  background-image: url(${tab});
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  transition: transform .3s ease;
  transform: ${props => props.open ? `rotateZ(180deg)` : 'none'};

`

const TabContent = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  padding: ${props => props.open ? '0 0 32px 0' : '0'};
  transition: all .3s linear;
  max-height: ${props => props.open ? `100%` : '0'};
`

const TabContentBox = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  overflow: hidden;
`

const ErrorBox = styled.span`
  ${ Header6 };
  color: ${props => props.theme.color.vermillion};
  margin-top: 32px;
  width: 100%;
  text-align: center;
`;


export {AddressPage, SummaryColumn, ErrorBox, FormSection, InputPlaceholder, TotallySummary, TotallyCode, CodeIcon, TabBox, TabContentBox, TabHeader, TabIcon, TabContent};