import React, { Component, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';

import ContractContainer from '../../containers/contracts';
import AddressContainer from '../../containers/address';
import MedicalInterviewContainer from '../../containers/medicalInterview';
import ContractsListContainer from '../../containers/contractsList';
import UserDataContainer from '../../containers/userData';
import SummaryScreenContainer from '../../containers/summary/screen.jsx';
import UserPanel from '../../screens/Panel';
import SignInContainer from '../../containers/signIn';
import SignUpContainer from '../../containers/signUp';
import PregnantTypesContainer from '../../containers/pregnantTypes';
import PackagesContainer from '../../containers/packages';
import PrivateRoute from '../../components/Private';
import ResetPasswordContainer from '../../containers/resetPassword';
import NewPasswordContainer from '../../containers/newPassword';
import LoaderComponent from '../../components/Loader';
import {ErrorModalComponent} from '../../components/Error/Modal';

class MainScreen extends Component{
  constructor(props){
    super(props);
    this.baseUrl = document.getElementById('root').dataset.baseUrl;
    this.close = this.close.bind(this);
  }

  componentDidMount(){
    this.props.clearAllErrors();
    this.props.loginUser({});
  }

  get redirector() {
    if(this.props.logged === true && (window.location.pathname === `${this.baseUrl}/` || window.location.pathname === `${this.baseUrl}/reset_password` || window.location.pathname === `${this.baseUrl}/new_password` || window.location.pathname === `${this.baseUrl}/sign_up`)){
      return (
        <Redirect to='/panel' />
      )
    }
    return '';
  }

  close(){
    if(this.props.error === 'Twoja sesja wygasła. Zostaniesz przekierowany do strony początkowej.'){
      window.location = this.baseUrl;
    }else{
      this.props.clearAllErrors();
    }
  }

  render(){
    return (
      <Router basename={this.baseUrl}>
        <Fragment>
          <Route exact path="/" component={SignInContainer} />
          <Route exact path="/sign_up" component={SignUpContainer} />
          <Route exact path="/reset_password" component={ResetPasswordContainer} />
          <Route exact path="/new_password" component={NewPasswordContainer} />
          <Route exact path="/pregnant" component={PregnantTypesContainer} />
          <Route exact path="/packages" component={PackagesContainer} />
          <Route exact path="/storage" component={ContractContainer} />
          <Route exact path="/address" component={AddressContainer} />
          <Route exact path="/medical_interview" component={MedicalInterviewContainer} />
          <Route exact path="/summary" component={SummaryScreenContainer} />
          <PrivateRoute exact path={`${this.baseUrl}/panel`} Component={UserPanel} logged={this.props.logged} />
          <PrivateRoute exact path={`${this.baseUrl}/contracts`} Component={ContractsListContainer} logged={this.props.logged} />
          <PrivateRoute exact path={`${this.baseUrl}/userData`} Component={UserDataContainer} logged={this.props.logged} />
          { this.redirector }
          <LoaderComponent visible={this.props.loader} />
          <ErrorModalComponent error={this.props.error} close={this.close} />
        </Fragment>
    </Router>
    )
  }
}

export default MainScreen;
