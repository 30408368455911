import React from 'react';
import { Link } from 'react-router-dom';
import logo from './Styles/logo.png';
import {LogoBox} from './Styles';

const LogoComponent = () => {
  return (
    <LogoBox>
      <Link to={`/panel`}>
        <img src={logo} />
      </Link>
    </LogoBox>
  );
}

export default LogoComponent;