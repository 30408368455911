import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import Headline from './../Headline';
import {SignInBox, ErrorBox} from './Styles';
import InputComponent from './../Input';
import ButtonComponent from './../Button';

class ResetComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      login: '',
      password: '',
      new_password: '',
      new_password2: '',
    }
    this.setField = this.setField.bind(this);
    this.loginUser = this.loginUser.bind(this);
  }

  loginUser(e){
    e.preventDefault();
    this.props.loginUser(this.state);
  }

  setField(data){
    this.setState({[data.field]: data.value});
  }

  get error(){
    return this.props.errors && this.props.errors.map((error, i) => {
      return <ErrorBox key={i}>{ error }</ErrorBox>;
    })
  }

  render() { 
    return ( 
      <SignInBox onSubmit={this.loginUser}>
        <Headline beige subheadline="Wykorzystaj kod tymczasowy, który otrzymałeś na adres e-mail przypisany do konta, aby utworzyć nowe hasło.">Tworzenie nowego hasła</Headline>
        <InputComponent changeHandler={this.setField} id="login" label="Login" value={this.state.login} type="text" />
        <InputComponent changeHandler={this.setField} id="password" label="Kod tymczasowy" value={this.state.password} type="password" />
        <InputComponent changeHandler={this.setField} id="new_password" label="Nowe hasło" value={this.state.new_password} type="password" />
        <InputComponent changeHandler={this.setField} id="new_password2" label="Powtórz nowe hasło" value={this.state.new_password2} type="password" />
        <Fragment>
          { this.error }
        </Fragment>
        <ButtonComponent click={this.loginUser} primary label="Zaloguj się" />
      </SignInBox>
    );
  }
}
 
export default ResetComponent;

