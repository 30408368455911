import Api from '../../../utils/api';
import {
  setServices,
  hideLoader,
  setError,
} from '../index';

export function receiveServices(checkPackages = false) {
  return (dispatch) => {
    new Api(`/api.php?action=slownik${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({"slownik":"USLUGI_DODATKOWE_MAP","maska":""})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            dispatch(setServices(json.USLUGI_DODATKOWE_EXT_4API));
          }else{
            dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}

