import React from 'react';
import { SummaryListBox } from './Styles';
import SummaryListItemComponent from './SummaryListItem';

const SummaryListComponent = ({options}) => {
  const getSummaryListItems = () => {
    return options.map((optionsItem, index) => {
      return <SummaryListItemComponent key={index} {...optionsItem} index={index} />
    });
  }

  return (
    <SummaryListBox>
      { getSummaryListItems() }
    </SummaryListBox>
  );
}

export default SummaryListComponent;