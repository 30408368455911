import React, { Component } from 'react';
import {Header5} from './../Styled';
import SummaryListComponent from './SummaryList';
import SummaryOverallComponent from './SummaryOverall';
import { SummaryBox, SummaryIcon, SummaryContent, SummaryHeader,SummaryHiddenIcon } from './Styles';

const options = [
  {
    name: 'Opłata wstępna',
    description: '',
    selected: [],
  },
  {
    name: 'Opłata właściwa',
    description: '',
    selected: [],
  },
  {
    name: 'Pakiety dodatkowe',
    description: '',
    selected: [],
  },
  {
    name: 'Przechowywanie',
    description: '',
    selected: [],
  }
]

class SummaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      summaryOptions: options,
      hidden: true,
    }

    this.toggleHidden = this.toggleHidden.bind(this);
  }

  componentDidMount(){
    this.setState({summaryOptions: this.options(this.props)});
  }

  componentWillReceiveProps(props){
    this.setState({summaryOptions: this.options(props)});
  }

  get hidden(){
    return this.state.hidden;
  }

  get getOverall(){
    const sum = this.state.summaryOptions.reduce((sum, item) => {
      const itemSum = item.selected.reduce((is, i) => {
        return is + parseInt(i.price);
      }, 0);
      return sum + parseInt(itemSum);
    }, 0)
    return `${sum} zł`;
  }

  options(props){
    return [
      this.entryFee(props),
      this.fee(props),
      this.packages(props),
      this.contract(props)
    ]
  }

  entryFee(props){
    const bankOffer = props.orderStep2.oferta_banku;
    const prices = props.prices['oferty_banku'] || null;
    if(bankOffer != undefined && bankOffer.value != undefined && bankOffer.value !== '' && prices != null){
      
      const values1 = {'-1': [-1], '1': [1, 11, 13], '2': [2, 12, 14]};
      const values2 = {'0': [-1, 1, 2], '5': [11, 12], '6': [13, 14]};
      const keys1 = Object.keys(values1);
      const keys2 = Object.keys(values2);
      const value1 = keys1.find(k => values1[k].includes(bankOffer.value));
      const value2 = keys2.find(k => values2[k].includes(bankOffer.value));
      const price1 = prices.find(p => p.id == value1);
      const price2 = prices.find(p => p.id == value2);

      const selected1 = price1 ? { name: price1.nazwa, price: price1.cena1k} : null;
      const selected2 = price2 ? { name: price2.nazwa, price: price2.cena1k}: null;

      const selected = [selected1, selected2];

      const description = bankOffer.descriptions && bankOffer.descriptions[0].description || '';
      // const name = bankOffer.MAP.filter((offer, index) => index == mapValueToOffer[bankOffer.value]);
      // const price = prices['2'][0][1];

      return {
        name: price1?.cena1n || 'Opłata wstępna',
        description: description,
        selected: selected.filter(Boolean)
      }
    }else{
      return {
        name: 'Opłata wstępna',
        description: '',
        selected: []
      }
    }
  }

  fee(props){
    const bankOffer = props.orderStep2.oferta_banku;
    const prices = props.prices['oferty_banku'] || null;
    if(bankOffer != undefined && bankOffer.value != undefined && bankOffer.value !== '' && prices != null){
        
      const values1 = {'-1': [-1], '1': [1, 11, 13], '2': [2, 12, 14]};
      const values2 = {'0': [-1, 1, 2], '5': [11, 12], '6': [13, 14]};
      const keys1 = Object.keys(values1);
      const keys2 = Object.keys(values2);
      const value1 = keys1.find(k => values1[k].includes(bankOffer.value));
      const value2 = keys2.find(k => values2[k].includes(bankOffer.value));
      const price1 = prices.find(p => p.id == value1);
      const price2 = prices.find(p => p.id == value2);

      const selected1 = price1 ? { name: price1.nazwa, price: price1.cena2k} : null;
      const selected2 = price2 ? { name: price2.nazwa, price: price2.cena2k} : null;

      const selected = [selected1, selected2];

      const description = bankOffer.descriptions && bankOffer.descriptions[0].description || '';
      // const name = bankOffer.MAP.filter((offer, index) => index == mapValueToOffer(bankOffer.value));
      // const price = prices['2'][1][1];
      return {
        name: price1?.cena2n || 'Opłata właściwa',
        description: description,
        selected: selected.filter(Boolean)
      }
    }else{
      return {
        name: 'Opłata właściwa',
        description: '', 
        selected: []
      }
    }
  }

  packages(props){
    if(props.selectedAdditionalPackages != undefined && props.selectedAdditionalPackages.length && typeof(props.prices['2']) != 'undefined'){
      const prices = props.prices['2'];
      const prices2 = props.prices['2sz'];
      const selected = props.selectedAdditionalPackages.length ? props.selectedAdditionalPackages.filter(pack => !pack.hidePayments).map(pack => {
        const price = (name) => {
          const price = prices[2].filter(p => {
            return p[0] == name;
          });
          if(price.length){
            return price[0][1];
          }
        }
        const price2 = (name) => {
          if(prices2){
            const price = prices2[2].filter(p => {
              return p[0] == name;
            });
            if(price.length){
              return price[0][1];
            }
          }
          return 0;
        }
        return {
          name: pack.name,
          price: parseFloat(price(pack.name)) + parseFloat(price2(pack.name))
        }
      }) : [];
    
      return {
        name: prices[0] || 'Usługi dodatkowe (po porodzie)',
        description: '',
        selected: selected
      }
    }else{
      return {
        name: 'Usługi dodatkowe (po porodzie)',
        description: '',
        selected: []
      }
    }
   
  }

  contract(props){
    const contract = props.orderStep3.przedplata;
    const prices = props.prices['3'] || null;
    const prices2 = props.prices['3sz'] || null;
    const nextYear = props.prices['4'] || null;
    const nextYear2 = props.prices['4sz'] || null;
    if(contract != undefined && contract.value != undefined && prices != null){
      const contracts = [];
      for(let key in contract.MAP){
        contracts.push(parseInt(key));
      }
      const contractIndex = contracts.indexOf(parseInt(contract.value));
      const description = contract.descriptions && contract.descriptions[0].description || '';
      const name = prices && prices['2'][contractIndex][0]
      const price = prices['2'][contractIndex][1];
      const price2 = prices2 && prices2['2'][contractIndex][1] || 0;
      const priceNextYear = nextYear && nextYear[2][0][1] || null;
      const priceNextYear2 = nextYear2 && nextYear2[2][0][1] || 0;
      return {
        name: prices[0] || 'Opłata za przechowywanie (po porodzie)',
        description: description,
        selected: [
          {
            name: name, 
            price: parseFloat(price) + parseFloat(price2),
            price2: priceNextYear !== null && contractIndex === 0 ? `(kolejna ${parseFloat(priceNextYear) + parseFloat(priceNextYear2)} zł)` : null,
          }
        ]
      }
    }else{
      return {
        name: 'Opłata za przechowywanie (po porodzie)',
        description: '',
        selected: []
      }
    }
  }

  toggleHidden(){
    this.setState({hidden: !this.state.hidden});
  }

  render() { 
    return ( 
      <SummaryBox small={ this.hidden }>
        <SummaryContent>
          <SummaryHeader small={ this.hidden } onClick={this.toggleHidden}>
            <SummaryIcon />
            <Header5>Podsumowanie</Header5>
            <Header5 className="price">{ this.getOverall}</Header5>
            <SummaryHiddenIcon small={ this.hidden } />
          </SummaryHeader>
          <SummaryListComponent options={this.state.summaryOptions} />
        </SummaryContent>
        <SummaryOverallComponent options={this.state.summaryOptions} />
      </SummaryBox>
     );
  }
}
 
export default SummaryComponent;