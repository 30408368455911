const apiUrl = document.getElementById('root').dataset.apiUrl;

class Api{
  constructor(path){
    this.path = path;
  }

  get fullUrl(){
    return `${apiUrl}${this.path}`;
  }

  get(params = {}) {
    return fetch(this.fullUrl, {
      method: 'get',
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
        'cache-control': 'no-cache',
      },
    });
  }

  post(params) {
    return fetch(this.fullUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    });
  }
}

export default Api;