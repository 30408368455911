import React, { Component } from 'react';
import { Header3 } from '../Styled';
import {BackButtonBox, BackButtonIcon} from './Styles';

class BackButtonConponent extends Component{
  constructor(props){
    super(props);
  }

  render(){
    return(
      <BackButtonBox onClick={this.props.action}>
        <BackButtonIcon  color={this.props.color}></BackButtonIcon>
        <Header3>{this.props.label}</Header3>
      </BackButtonBox>
    );
  }
}

export default BackButtonConponent;