import React, { Component } from 'react';
import {CheckboxBox, CheckboxLabel, CheckboxLabelContent, CheckboxField, CheckboxFieldBox, CheckboxInput, CheckboxDescription} from './Styles';
import { Header5, StrikeText, SmallText } from '../Styled';
import { RadioDescription } from '../Radio/Styles';
import Description from '../Description';
import TooltipComponent from './../Tooltip';

class CheckboxComponent extends Component{
  constructor(props){
    super(props);
    this.state = {
      checked: false,
    }

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount(){
    this.setState({checked: this.props.isChecked});
  }

  componentWillReceiveProps(props){
    if(props.isChecked != this.state.checked){
      this.setState({checked: props.isChecked});
    }
  }

  get isChecked(){
    return this.state.checked ? true : false;
  }

  handleClick(e){
    e.stopPropagation();
    if(this.props.oneOfRequired && this.state.checked) {
      return false;
    }
    this.setState({checked: !this.state.checked}, () => {
      if(this.state.checked){
        this.props.addChecked(this.props.option);
      }else{
        this.props.removeChecked(this.props.option);
      }
    });
  }

  get checkboxPrices(){
    return this.props.prices && (
      <Header5>
        {this.props.prices.promo != null ? <StrikeText>{` ${this.props.prices.standard}zł `}</StrikeText> : ''}
        {this.props.prices.promo != null && this.props.prices.standard != null ? `${this.props.prices.promo}zł` : `${this.props.prices.standard}zł`}
      </Header5>
    )
  }

  get descriptions(){
    if(this.props.withDescription && this.props.descriptions){
      return (
        <CheckboxDescription>
          <SmallText dangerouslySetInnerHTML={{__html: this.props.descriptions.description}}></SmallText>
          <SmallText dangerouslySetInnerHTML={{ __html: this.props.descriptions.priceDescription}}></SmallText>
        </CheckboxDescription>)
    }
  }

  
  get pricesDescriptions(){
    if(this.props.withDescription && this.props.pricesDescription){
      return (
        <RadioDescription>
          {
            this.props.pricesDescription.map((desc, index) =>{
              return <Description key={index} desc={desc} />;
            })
          }
        </RadioDescription>)
    }
  }

  get tooltip(){
    return this.props.tooltipCopy && <TooltipComponent copy={this.props.tooltipCopy} />;
  }

  render(){
    return (
      <CheckboxBox {...this.props}>
        <CheckboxFieldBox>
          <CheckboxInput>
            <CheckboxField type="checkbox" name={this.props.name} id={this.props.name} checked={this.isChecked} />
            <CheckboxLabel smallFont={this.props.smallFont} class="checkbox__label" onClick={this.handleClick} for={this.props.name}>
              <CheckboxLabelContent smallFont={this.props.smallFont} class="checkbox__label__content" onClick={this.handleClick} for={this.props.name} dangerouslySetInnerHTML={{__html: this.props.label}}></CheckboxLabelContent>
            </CheckboxLabel>
            { this.tooltip }
          </CheckboxInput>
          { this.checkboxPrices }
        </CheckboxFieldBox>
        { this.descriptions }
        { this.pricesDescriptions }
      </CheckboxBox>
    )
  }
}

export default CheckboxComponent;