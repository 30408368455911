import React, { Component } from 'react';
import LogoComponent from '../../components/Logo';
import { Header2, Header3 } from '../../components/Styled';
import { Link } from 'react-router-dom';
import Button from './../../components/Button';

import { SignInPage, PanelMain, PanelNavItem, PanelNavIcon, PanelNavText } from './Styles';
import { FormContent } from '../../components/Styled/Content';
import LogoutContainer from '../../containers/logout';

const baseUrl = document.getElementById('root').dataset.baseUrl;

class UserPanel extends Component{
  constructor(props){
    super(props);
  }
  
  render(){
    return(
      <SignInPage className="content">
        <LogoutContainer />
        <FormContent centered>
          <LogoComponent />
          <Header2>Witaj w Panelu Użytkownika</Header2>
          <PanelMain>
            <a href={`${baseUrl}/pregnant`}>
              <PanelNavItem>
                <PanelNavIcon new />
                <PanelNavText>
                  <Header3>Nowa umowa</Header3>
                    <Button label="Zobacz więcej"></Button>
                </PanelNavText>
              </PanelNavItem>
            </a>
            <Link to='userData'>
              <PanelNavItem>
                <PanelNavIcon contact />
                <PanelNavText>
                  <Header3>Dane kontaktowe</Header3>
                    <Button label="Zobacz więcej"></Button>
                </PanelNavText>
              </PanelNavItem>
            </Link>
            <Link to='/contracts'>
              <PanelNavItem>
                <PanelNavIcon contract />
                <PanelNavText>
                  <Header3>Umowy online</Header3>
                    <Button label="Zobacz więcej"></Button>
                </PanelNavText>
              </PanelNavItem>
            </Link>
          </PanelMain>
        </FormContent>
      </SignInPage>
    )
  }
}

export default UserPanel;