import styled from 'styled-components';
import {ButtonBox} from './../../../components/Button/Styles';
import {grid, media} from './../../../components/Styled/helpers';
import { Header5, Header4, Header6 } from '../../../components/Styled';
import { InputBox } from '../../../components/Input/Styles';
import { SelectBox } from '../../../components/Select/Styles';

const MedicalInverviewPage = styled.div`
   ${ButtonBox}{
    align-self: center;
    justify-content: center;
    margin-top: 56px;
    margin-bottom: 80px;
    ${ media.mobile`
      margin: 16px 0 calc(20vh + 48px);
    `}
  }
  ${SelectBox}{
    width: 100%;
  }
`;

const FormSection = styled.div`
  width: ${grid(7)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 0 48px;
  ${Header4}{
    margin-top: ${props => props.main ? '48px' : '24px'};
  }
  ${Header5}{
    width: 100%; 
    margin: 40px 0 24px;
  }
  ${InputBox}{
    width: calc(50% - 8px);
    position: relative;
    
    ${ media.mobile`
      width: 100%;
    `}
  }
  
  ${ media.mobile`
    width: 100%;
    margin: 0;
  ` }
`;

const ErrorBox = styled.span`
  ${ Header6 };
  color: ${props => props.theme.color.vermillion};
  margin-top: 16px;
`;

export {MedicalInverviewPage, FormSection, ErrorBox};