import React, { Component } from 'react';
import {ButtonBox, ButtonIcon, ButtonLabel } from './Styles';

class ButtonComponent extends Component {
  constructor(props) {
    super(props);
  }
  render() { 
    return ( 
      <ButtonBox {...this.props} onClick={this.props.click}>
        <ButtonLabel>{this.props.label}</ButtonLabel>
        <ButtonIcon />
      </ButtonBox>
     );
  }
}
 
export default ButtonComponent;