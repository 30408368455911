import styled from 'styled-components';
import {grid, media} from './../helpers';

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: ${props => props.full ? grid(10) : grid(8)};
  margin: ${props => props.centered ? '0px auto' : '0px 0px'};
  padding: 0 24px;
  ${ media.desktop`
    width: ${props => props.full ? grid(10) : grid(7)}
  `}
  ${ media.mobile`
    width: 100%;
    padding: 0;
  `}
`;

export {FormContent};