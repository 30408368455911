import { createAction } from 'redux-actions';

export const initOrderStep1 = createAction('INIT_ORDER_STEP1');
export const setOrderStep1 = createAction('SET_ORDER_STEP1');
export const initOrderStep2 = createAction('INIT_ORDER_STEP2');
export const setOrderStep2 = createAction('SET_ORDER_STEP2');
export const initOrderStep3 = createAction('INIT_ORDER_STEP3');
export const setOrderStep3 = createAction('SET_ORDER_STEP3');
export const initOrderStep5 = createAction('INIT_ORDER_STEP5');
export const setOrderStep5 = createAction('SET_ORDER_STEP5');
export const initOrderStep5Questions = createAction('INIT_ORDER_STEP5_QUESTIONS');
export const setOrderStep5Questions = createAction('SET_ORDER_STEP5_QUESTIONS');

export const setSelectedPackage = createAction('SET_SELECTED_PACKAGE');
export const addSelectedAdditionalPackage = createAction('ADD_SELECTED_ADDITIONAL_PACKAGE');
export const removeSelectedAdditionalPackage = createAction('REMOVE_SELECTED_ADDITIONAL_PACKAGE');

export const addSelectedAdditionalPack = createAction('ADD_SELECTED_ADDITIONAL_PACK');
export const removeSelectedAdditionalPack = createAction('REMOVE_SELECTED_ADDITIONAL_PACK');

export const setContracts = createAction('SET_CONTRACTS');
export const setSelectedContract = createAction('SET_SELECTED_CONTRACT');
export const setAddressInfo = createAction('SET_ADDRESS_DATA');
export const setAddressDataErrors = createAction('SET_ADDRESS_DATA_ERRORS');
export const clearAddressDataErrors = createAction('CLEAR_ADDRESS_DATA_ERRORS');
export const setPromotions = createAction('SET_PROMOTIONS');
export const setSelectedPromotion = createAction('SET_SELECTED_PROMOTION');
export const setMedicalInterviewData = createAction('SET_MEDICAL_INTERVIEW_DATA');
export const updateIllnessData = createAction('UPDATE_ILLNESS_DATA');
export const setUserContracts = createAction('SET_USER_CONTRACTS_LIST');

export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginFail = createAction('LOGIN_FAIL');
export const logout = createAction('LOGOUT');

export const registrationSuccess = createAction('REGISTRATION_SUCCESS');
export const registrationFail = createAction('REGISTRATION_FAIL');

export const setErrors = createAction('SET_ERRORS');
export const clearErrors = createAction('CLEAR_ERRORS');

export const setUserData = createAction('SET_USER_DATA');
export const initUserData = createAction('INIT_USER_DATA');
export const setUserErrors = createAction('SET_USER_ERRORS');
export const clearUserErrors = createAction('CLEAR_USER_ERRORS');
export const setUserSuccess = createAction('SET_USER_SUCCESS');
export const clearUserSuccess = createAction('CLEAR_USER_SUCCESS');

export const setAddressData = createAction('SET_ADDRESS_DATA');
export const initAddressData = createAction('INIT_ADDRESS_DATA');
export const setAddressErrors = createAction('SET_ADDRESS_ERRORS');
export const clearAddressErrors = createAction('CLEAR_ADDRESS_ERRORS');
export const setAddressSuccess = createAction('SET_ADDRESS_SUCCESS');
export const clearAddressSuccess = createAction('CLEAR_ADDRESS_SUCCESS');

export const initHospitals = createAction('INIT_HOSPITALS');

export const initKnownFrom = createAction('INIT_KNOWN_FROM');

export const setAdditionalPackagesTypes = createAction('SET_ADDITIONAL_PACKAGES_TYPES');
export const setAdditionalPackagesTitle = createAction('SET_ADDITIONAL_PACKAGES_TITLE');
export const setAdditionalPackagesDescription = createAction('SET_ADDITIONAL_PACKAGES_DESCRIPTION');

export const showLoader = createAction('SHOW_LOADER');
export const hideLoader = createAction('HIDE_LOADER');

export const setPrices = createAction('SET_PRICES');

export const setOrderErrors = createAction('SET_ORDER_ERRORS');
export const addOrderErrors = createAction('ADD_ORDER_ERRORS');
export const clearOrderErrors = createAction('CLEAR_ORDER_ERRORS');

export const setResetErrors = createAction('SET_RESET_ERRORS');
export const clearResetErrors = createAction('CLEAR_RESET_ERRORS'); 
export const resetSuccess = createAction('RESET_SUCCESS');
export const resetFail = createAction('RESET_FAIL');

export const setError = createAction('SET_ERROR');
export const clearError = createAction('CLEAR_ERROR');

export const setServices = createAction('SET_SERVICES');

export const setRegistrationData = createAction('SET_REGISTRATION_DATA')

export * from './login';
export * from './register';
export * from './orderStep1';
export * from './orderStep2';
export * from './orderStep3';
export * from './orderStep4';
export * from './orderStep5';
export * from './orderStep5/questions';
export * from './userContracts';
export * from './userData';
export * from './hospitals';
export * from './prices';
export * from './resetPassword';
export * from './contract';
export * from './knownFrom';
export * from './clearAllErrors';
export * from './errors';
export * from './services';