import React from 'react';
import TooltipComponent from './../Tooltip';
import { Header3, Header5 } from './../Styled';
import { Headline, ColorBox } from './Styles';

const HeadlineComponent = ({beige, children, subheadline, tooltipCopy, uppercase}) => {
  const getSubheadline = () => {
    return subheadline && (
      <Header5 dangerouslySetInnerHTML={{__html: subheadline}}></Header5>
    )
  }
  
  const tooltip = () => {
    return tooltipCopy && <TooltipComponent copy={tooltipCopy} />;
  }

  return ( 
    <Headline uppercase={Boolean(uppercase)}>
      <ColorBox beige={beige} />
      <Header3>
      { children }
      { tooltip() }
      </Header3>
      { getSubheadline() }
    </Headline>
  );
}
 
export default HeadlineComponent; 