import styled from 'styled-components';
import { font, fontMiddle } from './../../Styled/Fonts';
import tick from './tick-white.svg';
import { Header5, StrikeText } from '../../Styled';
import { media } from '../../Styled/helpers';

const CheckboxDivider = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
`;

const CheckboxBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-color: ${props => props.error ? props.theme.color.vermillion : props.theme.color.black};
  color: ${props => props.error ? props.theme.color.vermillion : props.theme.color.black};
  opacity: ${props => props.disabled ? .4 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  margin: ${props => props.withoutMargins ? '0 0 0 12px' : props.registration ? '0 48px 24px' : '24px 48px'};
  width: calc(100% - 96px);
  ${media.mobile`
    margin: 24px 0;
    width: 100%;
    `
  }
`;

const CheckboxFieldBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${Header5}{
    justify-self: flex-end;
    ${ media.mobile`
      padding-left: 24px;
    `}
    ${StrikeText}{
      padding: 0 8px;
      ${ media.mobile`
        padding-left: 24px;
      `}
    }
  }
  ${ media.mobile`
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `}
`;

const CheckboxDescription = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  width: calc(100% - 24px);
  padding: 0 0 0 24px;
  color: #8c8c8c;
  ${ media.mobile`
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  `}
`;

const CheckboxInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

const CheckboxLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  color: inherit;
  user-select: none;
  ${props => props.smallFont ? fontMiddle(props.theme) : font(props.theme)};
  &:before{
    width: 16px;
    min-width: 16px;
    height: 16px;
    content: '';
    display: flex;
    border-width: 2px;
    border-style: solid;
    border-color: inherit;
    box-sizing: border-box;
    margin-top: ${props => props.smallFont ? '0' : '8px'};
    margin-right: 8px;
    border-radius: 3px;
  }
  &:after{
    content: '';
    width: 16px;
    min-width: 16px;
    height: 16px;
    display: flex;
    position: absolute;
    left: 0px;
    top: ${props => props.smallFont ? '0' : '8px'};
    border-radius: 3px;
    background-color: ${props => props.theme.color.black};
    opacity: 0;
    transition: opacity .1s ease-in;
    background-image: url(${tick});
    background-size: auto;
    background-position: center;
    background-repeat: no-repeat;
  }
}`;

const CheckboxLabelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  color: inherit;
  user-select: none;
  ${props => props.smallFont ? fontMiddle(props.theme) : font(props.theme)};
  a{
    color: inherit;
    text-decoration: underline;
  }
`;

const CheckboxField = styled.input`
visibility: hidden;
opacity: 0;
width: 0;
height: 0;
display: none;
&:checked{
  &+${CheckboxLabel}{
    &:after{
      opacity: 1;
    }
  }
}
`;

export {CheckboxDivider, CheckboxBox, CheckboxLabel, CheckboxLabelContent,  CheckboxField, CheckboxFieldBox, CheckboxInput, CheckboxDescription};