import { css } from 'styled-components';

const gridColWidth = 76.7;
const gridSpaceWidth = 40;
const mobileBreakpoint = '';

const grid = (val) => {
  return `${val * gridColWidth + (val - 1) * gridSpaceWidth}px`;
}

const media = {
  desktop: (...args) => css`
    @media (max-width: 1440px){
      ${ css(...args) } 
    }
  `,
  mobile: (...args) => css`
    @media (max-width: 768px){
      ${ css(...args) } 
    }
  `
}

export { grid, media };