import styled from 'styled-components';
import { Header5, MiddleText } from '../../Styled';
import { RadioInputBox } from '../../Radio/Styles';

const YesNo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  ${Header5}{
    margin: 24px 0 8px;
    color: ${props => props.subLabel ? props.theme.color.greyish : props.theme.color.black};
  }
  ${MiddleText}{
    margin: 24px 0;
  }
`;  

const YesNoBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  ${RadioInputBox}{
    margin-right: 24px;
  }
`;

export {YesNo, YesNoBox};