import React, { Component } from 'react';
import {RadioBox, RadioFieldBox, RadioDescription, RadioPriceDescription,RadioTitle, RadioDescriptionsWrapper} from './Styles';
import Description from './../Description';
import RadioInputComponent from './Input';
import {Header5, StrikeText, Text, SmallText} from './../Styled';

class RadioComponent extends Component{
  constructor(props){
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  get descriptions(){
    if(this.props.withDescription && this.props.descriptions){
      return (
        <RadioDescription>
          {this.props.descriptionTitle && <RadioTitle>{this.props.descriptionTitle}</RadioTitle>}
          <RadioDescriptionsWrapper>
          {
            this.props.descriptions.filter(Boolean).map((desc, index) =>{
              return <Description key={index} desc={desc} />;
            })
          }</RadioDescriptionsWrapper>
        </RadioDescription>)
    }
  }
  get descriptions2(){
    if(this.props.withDescription && this.props.descriptions2){
      return (
        <RadioDescription>
          {this.props.description2Title && <RadioTitle>{this.props.description2Title}</RadioTitle>}
          <RadioDescriptionsWrapper>
          {
            this.props.descriptions2.filter(Boolean).map((desc, index) =>{
              return <Description key={index} desc={desc} />;
            })
          }
          </RadioDescriptionsWrapper>
        </RadioDescription>)
    }
  }

  get radioPrices(){
    return this.props.prices && (
      <Header5>
        {this.props.prices.promo != null ? <StrikeText>{` ${this.props.prices.standard}zł `}</StrikeText> : ''}
        {this.props.prices.promo != null && this.props.prices.standard != null ? `${this.props.prices.promo}zł` : `${this.props.prices.standard}zł`}
      </Header5>
    )
  }

  get pricesDescriptions(){
    if(this.props.withPrices && this.props.descriptions){
      return this.props.descriptions.filter(Boolean).map((desc) => (
        <RadioPriceDescription>
          <SmallText>{desc.description}</SmallText>
          <SmallText>{desc.priceDescription}</SmallText>
        </RadioPriceDescription>)
      )}
  }

  handleChange(){
    if(!this.props.disabled){
      this.props.changeSelectedValue(this.props.option, this.props.name);
    } 
  }

  render(){
    return(
      <RadioBox disabled={this.props.disabled}>
        <RadioFieldBox>
          <RadioInputComponent tooltipCopy={this.props.tooltipCopy} selectedValue={this.props.selectedValue} changeHandler={this.handleChange} id={this.props.id} value={this.props.value} name={this.props.name} label={this.props.label} />
          { this.radioPrices }
        </RadioFieldBox>
        { this.descriptions }
        { this.descriptions2 }
        { this.pricesDescriptions }
      </RadioBox>
    )
  }
}

export default RadioComponent;