import React, {Component} from 'react';
import {SelectListItem} from './Styles';

class ListItem extends Component{
  constructor(props){
    super(props);

    this.handleClick= this.handleClick.bind(this);
  }

  get label(){
    return `${this.props.item.label}`;
  }

  handleClick = () => {
    this.props.changeSelectedValue(this.props.item);
  }

  render(){
    return(
      <SelectListItem selected={this.props.selectedValue.id === this.props.item.id} onClick={this.handleClick}>{this.label}</SelectListItem>
    )
  }
}

export default ListItem