const seed = [
  {
    id: 0,
    type: 'deluxe',
    label: 'Pakiet deluxe',
    descriptions: [
      {
        headline: 'Opłata wstępna',
        standardPrice: 380,
        promoPrice: 300,
        description: 'Płatna do 3 dni od podpisania umowy'
      },
      {
        headline: 'Opłata właściwa',
        standardPrice: 1300,
        promoPrice: 1000,
        description: 'Płatna do 56 dni od porodu'
      },
    ],
    tooltip: 'Dodatkowe informacje'
  },
  {
    id: 1,
    type: 'standard',
    label: 'Pakiet Standardowy',
    descriptions: [
      {
        headline: 'Opłata wstępna',
        standardPrice: 250,
        promoPrice: 200,
        description: 'Płatna do 3 dni od podpisania umowy'
      },
      {
        headline: 'Opłata właściwa',
        standardPrice: 1000,
        promoPrice: 800,
        description: 'Płatna do 56 dni od porodu'
      },
    ],
    tooltip: 'Dodatkowe informacje'
  }
]

export const packageTypes = (state = seed, action) => {
  switch(action.type){
    case 'SET_PACKAGES_TYPES':
      return action.payload;
    default:
      return state;
  }
}