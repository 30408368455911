import styled from 'styled-components';
import {media} from './../../Styled/helpers';

export const LogoutButton = styled.div`
  position: absolute;
  top: 32px;
  right: 48px;
  ${media.mobile`
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: fixed;
    display: flex;
    padding: 16px 0;
    background-color: ${props => props.theme.color.white};
    z-index: 9;
  `}
`;