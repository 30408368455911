import styled from 'styled-components';
import { grid } from '../../Styled/helpers';
import { Text } from '../../Styled'; 

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: center;
  width: 100%;
  margin-bottom: 64px;
`;

const ListBoxItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.color.pinkishGrey2};
  &:last-of-type{
    border-bottom: 0;
  }
`;

const ListBoxCell = styled.div`
  display: flex;
  padding: 16px 0;
  margin-right: 40px;
  width: ${props => grid(2)};
  align-items: flex-start;
  justify-content: flex-start;
  &:last-of-type{
    margin-right: 0;
  }
  ${Text}{
    cursor: ${props => props.link ? 'pointer' : 'default'};
  }
`;

export {ListBox, ListBoxItem, ListBoxCell};