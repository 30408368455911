import React, {Fragment} from 'react';
import LinkComponent from './../Link';
import { LogoutButton } from './Styles';

export const LogoutComponent = ({logged, logout}) => {
  const isLogged = () => {
    return logged && (
      <LinkComponent action={logout} label="Wyloguj" />
    )
  }
  return (
    <LogoutButton>
      {isLogged()}
    </LogoutButton>
  );
} 