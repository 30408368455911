import styled from 'styled-components';
import {ButtonBox} from './../../../components/Button/Styles';
import {media} from './../../../components/Styled/helpers';
import { Header6 } from './../../../components/Styled';
import { InputBox } from '../../../components/Input/Styles';
import { LinkBox } from '../../../components/Link/Styles';

const PackagesPage = styled.div`
   ${ButtonBox}{
    align-self: center;
    justify-content: center;
    margin-top: 56px;
    margin-bottom: 80px;
    ${ media.mobile`
      margin: 16px 0 calc(20vh + 48px);
    `}
  }
`;

const PromoCodeBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-left: 48px;
  width: 70%;
  ${ media.mobile`
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  `};
  ${InputBox}
    width: 60%;
    ${ media.mobile`
      width: 100%;
    `}
  }
  ${LinkBox}{
    padding: 12px 24px;
    font-weight: bold;
    text-transform: capitalize;
  }
`

const ErrorBox = styled.span`
  ${ Header6 };
  color: ${props => props.theme.color.vermillion};
  margin-top: 16px;
  margin-left: ${props => props.fieldError ? '48px' : 0}
`;

export {PackagesPage, ErrorBox, PromoCodeBox};