import { connect } from 'react-redux';
import UserData from './../../screens/UserData';
import {  clearAllErrors, receiveUserData, setUserData, updateUserData } from './../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    userData: state.get('userData'),
    errors: state.get('userDataErrors'),
    success: state.get('userDataSuccess'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    receiveUserData: () => {
      dispatch(receiveUserData());
    },
    setUserData: (data) => {
      dispatch(setUserData(data));
    },
    updateUserData: (data) => {
      dispatch(updateUserData(data));
    },
    clearAllErrors: () => {
      dispatch(clearAllErrors());
    }
  }
}

const UserDataContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserData);

export default UserDataContainer;