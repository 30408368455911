import React from 'react';
import { ErrorModal, ErrorMsg } from './Styles';
import { Header3 } from '../Styled';

export const ErrorModalComponent = ({error, close}) => {
  return (
    <ErrorModal visible={ error !== null}>
      <ErrorMsg>
        <Header3>Wystąpił błąd.</Header3>
        <p>{ error }</p>
        <a onClick={close}>Zamknij</a>
      </ErrorMsg>
    </ErrorModal>
  )
}