import React, { Component } from 'react';
import LogoComponent from '../../components/Logo';
import { Header2 } from '../../components/Styled';
import { SignInPage, SignInSections, Divider } from './Styles';
import SignInComponent from '../../components/SignIn';
import SignUpComponent from '../../components/SignUp';
import FirstVisitComponent from '../../components/FirstVisit';

class SignIn extends Component{
  constructor(props){
    super(props);
  }

  componentDidMount(){
    this.props.clearAllErrors();
  }

  render(){
    return(
      <SignInPage className="content">
        <LogoComponent />
        <Header2>Witaj w konfiguratorze umowy online</Header2>
        <SignInSections>
          <FirstVisitComponent />
          <Divider />
          <SignInComponent loginUser={this.props.loginUser} errors={this.props.errors} loggedUser={this.props.legged} />
          <Divider />
          <SignUpComponent />
        </SignInSections>
      </SignInPage>
    )
  }
}

export default SignIn;