import styled from 'styled-components';
import { grid, media } from '../../Styled/helpers';
import { SmallText, Header4 } from '../../Styled';
import complete from './complete.svg';

const StepsBox = styled.div`
  display: flex;
  flex-direction:row;
  align-items: flex-start;
  justify-content: space-between;
  width: ${grid(6)};
  margin: 0 0 48px;
  position: sticky;
  top: 0;
  padding: 32px;
  z-index: 99999;
  width: 100%;
  background-color: ${props => props.theme.color.white};
  box-sizing: border-box;

  ${ media.mobile`
    width: 100%;
    padding: 0;
    position: relative;
  `}
`;

const StepBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  a{
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
  }
  ${SmallText}{
    width: ${grid(1)};
    text-align: center;
    margin-top: 8px;
    ${ media.mobile`
      display: none;
    `}
  }
`;

const StepNumber = styled.div`
  display: flex;
  min-width: 56px;
  min-height: 56px;
  width: 56px;
  height: 56px;
  justify-content: center;
  align-items: center;
  position: relative;
  ${Header4}{
    visibility: ${props => props.complete ? 'hidden' : 'visible'};
  }
  ${ media.mobile`
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
  `}
  &:before{
    content: '';
    display: flex;
    position: absolute;
    top: 8px;
    left: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border-width: 4px;
    border-color: ${props => props.theme.color.beige};
    border-style: solid;
    background-color: ${props => props.active || props.complete ? props.theme.color.beige : props.theme.color.white};
    z-index: -1;
    background-image: ${props => props.complete ? `url(${complete})` : ''};
    background-size: 24px;
    background-position: center;
    background-repeat: no-repeat;
    ${ media.mobile`
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      min-width: 24px;
      min-height: 24px;
      background-size: 16px;
    `}
  }
  &:after{
    content: '';
    display: flex;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-width: 4px;
    border-color: ${props => props.active ? props.theme.color.beige : props.theme.color.white};
    border-style: solid;
    z-index: -1;
    ${ media.mobile`
      display: none
    `}
  }
`;

export {StepsBox, StepBox, StepNumber};