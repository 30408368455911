import React, { Component, Fragment } from 'react';
import LogoComponent from '../../components/Logo';
import { FormSection, InputPlaceholder, SignUpPage, ErrorBox, SuccessBox } from './Styles';
import ButtonComponent from './../../components/Button';
import HeadlineComponent from '../../components/Headline';
import InputComponent from '../../components/Input';
import CheckboxComponent from '../../components/Checkbox';


export default class SignUpScreen extends Component{
  constructor(props){
    super(props);

    this.state = {
      'login':"",
      'password':"",
      'password2':"",
      'imie':"",
      'nazwisko':"",
      'telefon':"",
      'email':"",
      'zgoda_m1':0,
      'zgoda_m2':0,
      'zgoda_m3':0
    }

    this.removeCheckedAgreement = this.removeCheckedAgreement.bind(this);
    this.addCheckedAgreement = this.addCheckedAgreement.bind(this);
    this.registerUser = this.registerUser.bind(this);
    this.setField = this.setField.bind(this);
  }
  
  componentDidMount(){
    this.props.clearAllErrors();
  }

  removeCheckedAgreement(checked){
    this.setState({[checked]: 0});
  }

  addCheckedAgreement(checked){
    this.setState({[checked]: 1});
  }
  
  registerUser(e){
    e.preventDefault();
    this.props.registerUser(this.state);
  }

  setField(data){
    this.setState({[data.field]: data.value});
  }

  get error(){
    return this.props.errors && this.props.errors.length > 0 ? (
      <ErrorBox key={0} dangerouslySetInnerHTML={{__html: this.props.errors[0]}}></ErrorBox>
    ) : '';
  }

  get success(){
    return this.props.registered ? <SuccessBox>Twoje konto zostało utworzone, możesz przejść do logowania.</SuccessBox> : '';
  }

  render(){ 
    return (
      <SignUpPage className="content" onSubmit={this.registerUser}>
        <LogoComponent />
        <HeadlineComponent>Rejestracja</HeadlineComponent>
        <FormSection>
          <InputComponent required changeHandler={this.setField} id="login" label="Login" value={this.state.login} type="text" />
          <InputPlaceholder />
          <InputComponent required changeHandler={this.setField} id="imie" label="Imię" value={this.state.imie} type="text" />
          <InputComponent required changeHandler={this.setField} id="nazwisko" label="Nazwisko" value={this.state.nazwisko} type="text" />
          <InputComponent required changeHandler={this.setField} id="email" label="Email" value={this.state.email} type="text" />
          <InputComponent required changeHandler={this.setField} id="telefon" label="Telefon" value={this.state.telefon} type="text" />
          <InputComponent required changeHandler={this.setField} id="password" label="Hasło" value={this.state.password} type="password" />
          <InputComponent required changeHandler={this.setField} id="password2" label="Powtórz hasło" value={this.state.password2} type="password" />
        </FormSection>

        <FormSection checkboxes>
          <CheckboxComponent registration isChecked={this.state.zgoda_m1} addChecked={this.addCheckedAgreement} removeChecked={this.removeCheckedAgreement} option="zgoda_m1" smallFont label={ this.props.registrationData && typeof(this.props.registrationData.zgoda_m1) != 'undefined' ? this.props.registrationData.zgoda_m1 : ''} />
          <CheckboxComponent registration isChecked={this.state.zgoda_m2} addChecked={this.addCheckedAgreement} removeChecked={this.removeCheckedAgreement} option="zgoda_m2"  smallFont label={ this.props.registrationData && typeof(this.props.registrationData.zgoda_m2) != 'undefined' ? this.props.registrationData.zgoda_m2 : ''} />
          <CheckboxComponent registration isChecked={this.state.zgoda_m3} addChecked={this.addCheckedAgreement} removeChecked={this.removeCheckedAgreement} option="zgoda_m3"  smallFont label={ this.props.registrationData && typeof(this.props.registrationData.zgoda_m3) != 'undefined' ? this.props.registrationData.zgoda_m3 : ''} />
          

        <Fragment>
          { this.error }
          { this.success }
        </Fragment>
        <ButtonComponent click={this.registerUser} secondary label="Zarejestruj się" />
        </FormSection>
        
      </SignUpPage>
    )
  }
}
