import Api from '../../../utils/api';
import {
  initAddressData,
  clearAddressErrors,
  clearAddressSuccess,
  checkErrors,
  clearOrderErrors,
  setAddressDataErrors,
  setError,
} from '../index';

export function receiveAddressData() {
  return (dispatch) => {
    new Api(`/api.php?action=UmowaS4Get${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            const data = {};
            json.OK.map(field => {
              data[field.name] = field;
            });
            dispatch(initAddressData(data));
          }else{
            console.error(json);
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}

export function updateAddressData(data, cb) {
  return (dispatch) => {
    dispatch(clearOrderErrors());
    dispatch(clearAddressErrors());
    dispatch(clearAddressSuccess());
    new Api(`/api.php?action=UmowaS4Edit${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post(data)
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0 && json.WARNING === 0){
            cb && cb();
          }else{
            const errors = [];
            json.ERRORS.ERROR_USERINFO.map(er => {
              for(let key in er){
                errors.push({
                  field: key,
                  msg: er[key],
                });
              }
            });
            json.WARNINGS.WARNING_USERINFO.map(er => {
              for(let key in er){
                errors.push({
                  field: key,
                  msg: er[key],
                });
              }
            });
            console.log(errors, response);
            dispatch(setAddressDataErrors(errors));
            dispatch(checkErrors(errors));
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
    });
  };
}
