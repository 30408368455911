import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import LogoComponent from '../../components/Logo';
import { FormContent } from './../../components/Styled/Content';
import { AddressPage, FormSection, InputPlaceholder, ErrorBox, SuccessBox, ChangePasswordLink } from './Styles';
import ButtonComponent from './../../components/Button';
import BackButtonComponent from './../../components/BackButton';
import InputComponent from '../../components/Input';
import FormHeadlineComponent from '../../components/FormHeadline';
import {Header5, Header4, BoldText} from './../../components/Styled';
import SeparatorComponent from '../../components/Separator';
import CheckboxComponent from '../../components/Checkbox';
import LogoutContainer from '../../containers/logout';

const preventUpdateKeys = ['password', 'fname', 'lname', 'login', 'zgoda_m1', 'zgoda_m2', 'zgoda_m3'];

class UserData extends Component{
  constructor(props){
    super(props);
    this.state = {
      changePassword: false,
      userData: {
        login: '',
        fname: '',
        lname: '',
        email: '',
        telefon: '',
        miasto: '',
        kod: '',
        ulica: '',
        ulica_nr: '',
        adr_koresp_szp_imie: '',
        adr_koresp_szp_nazwisko: '',
        adr_koresp_szp_miast: '',
        adr_koresp_szp_kod: '',
        adr_koresp_szp_ulica: '',
        adr_koresp_szp_nr: '',
        password: '',
        zgoda_m1: false,
        zgoda_m2: false,
        zgoda_m3: false,
      }
    }
    this.baseUrl = document.getElementById('root').dataset.baseUrl;
    this.handleChange = this.handleChange.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.getValue = this.getValue.bind(this);
    this.getDisabled = this.getDisabled.bind(this);
    this.updateUserData = this.updateUserData.bind(this);
    this.showPasswordForm = this.showPasswordForm.bind(this);
  }

  componentDidMount(){
    this.props.clearAllErrors();
    this.props.receiveUserData();
  }

  componentWillReceiveProps(props){
    if(typeof(props.userData.login) != 'undefined'){
      this.setState({
        ...this.state,
        userData: {
          login: props.userData.login.value,
          fname: props.userData.fname.value,
          lname: props.userData.lname.value,
          email: props.userData.email.value,
          telefon: props.userData.telefon.value,
          miasto: props.userData.miasto.value,
          kod: props.userData.kod.value,
          ulica: props.userData.ulica.value,
          ulica_nr: props.userData.ulica_nr.value,
          adr_koresp_szp_imie: props.userData.adr_koresp_szp_imie.value,
          adr_koresp_szp_nazwisko: props.userData.adr_koresp_szp_nazwisko.value,
          adr_koresp_szp_miasto: props.userData.adr_koresp_szp_miasto.value,
          adr_koresp_szp_kod: props.userData.adr_koresp_szp_kod.value,
          adr_koresp_szp_ulica: props.userData.adr_koresp_szp_ulica.value,
          adr_koresp_szp_nr: props.userData.adr_koresp_szp_nr.value,
          zgoda_m1: props.userData.zgoda_m1.value,
          zgoda_m2: props.userData.zgoda_m2.value,
          zgoda_m3: props.userData.zgoda_m3.value,
        }
      });
    }
  }

  get error(){
    return this.props.errors && this.props.errors.map((error, i) => {
      return <ErrorBox key={i}>{ error }</ErrorBox>;
    })
  }

  get success(){
    return this.props.success !== '' ?  (<SuccessBox>{ this.props.success }</SuccessBox>) : '';
  }

  handleChange(data){
    // this.props.setUserData(data);
    this.setState({
      ...this.state,
      userData: {
        ...this.state.userData,
        [data.field]: data.value,
      }
    });
  }

  getLabel(key){
    return this.props.userData && this.props.userData[key] ? this.props.userData[key].label : '';
  }

  getValue(key){
    return this.state.userData && this.state.userData[key] ? this.state.userData[key] : '';
  }

  getError(key){
    return this.props.userData && this.props.userData[key] ? this.props.userData[key].error : '';
  }  

  getRequire(key){
    return this.props.userData && this.props.userData[key] && (this.props.userData[key].minlength > 0 || this.props.userData[key].min > 0 || this.props.userData[key].isReq) ? true : false;
  }

  getDisabled(key){
    return this.props.userData && this.props.userData[key] && this.props.userData[key].value && this.props.userData[key].value.length ? true : false;
  }

  checkKey(key){
    return preventUpdateKeys.indexOf(key) < 0;
  }

  updateUserData(){
    const data = {};
    for(let key in this.state.userData){
      if(this.state.userData.hasOwnProperty(key)){
        if(this.checkKey(key)){
          data[key] = this.state.userData[key];
        }
      }
    }
    this.props.updateUserData(data);
  }

  showPasswordForm(){
    this.setState({changePassword: true});
  }

  get passwordForm(){
    return this.state.changePassword ? 
    (<InputComponent changeHandler={this.handleChange} id="password" label={this.getLabel('password')} value={this.getValue('password')} type="password" />) : (<ChangePasswordLink onClick={this.showPasswordForm}>Zmień hasło</ChangePasswordLink>)
  }

  render(){
    return(
      <AddressPage className="content">
        <LogoutContainer />
        <FormContent centered>
          <LogoComponent />
          
          <Link to="/panel">
            <BackButtonComponent color="duckEggBlue" label="Dane kontakowe" />
          </Link>  
          
          <BoldText backgroundDuckEggBlue>
          Jeśli chcesz zmienić pola, których edycja jest zablokowana prosimy o <a download="ZMIANA_ADRESÓW_KORESPONDENCYJNYCH.doc" target="_blank" href={`${this.baseUrl}/ZMIANA_ADRESÓW_KORESPONDENCYJNYCH.doc`}>POBRANIE FORMULARZA</a>, wypełnienie, podpisanie i przesłanie na adres firmy poczta tradycyjną lub elektroniczną.
          </BoldText>
          <FormSection>
            <InputComponent disabled changeHandler={this.handleChange} id="login" label={this.getLabel('login')}  error={this.getError('login')} value={this.getValue('login')} type="text" />
            <InputPlaceholder />
            <InputComponent disabled changeHandler={this.handleChange} id="fname" label={this.getLabel('fname')} error={this.getError('fname')} value={this.getValue('fname')} type="text" />
            <InputComponent disabled changeHandler={this.handleChange} id="lname" label={this.getLabel('lname')}  error={this.getError('lname')} value={this.getValue('lname')} type="text" />
            <InputComponent  required={this.getRequire('email')} changeHandler={this.handleChange} id="email" label={this.getLabel('email')}  error={this.getError('email')} value={this.getValue('email')} type="text" />
            <InputComponent required={this.getRequire('telefon')} changeHandler={this.handleChange} id="telefon" label={this.getLabel('telefon')}  error={this.getError('telefon')} value={this.getValue('telefon')} type="text" />
            { this.passwordForm }
          </FormSection>
          <FormSection>
            <FormHeadlineComponent address label="Adres zameldowania" />
            <SeparatorComponent />
            <InputComponent required={this.getRequire('miasto')} changeHandler={this.handleChange} id="miasto" label={this.getLabel('miasto')}  error={this.getError('miasto')} value={this.getValue('miasto')} type="text" />
            <InputComponent required={this.getRequire('kod')} changeHandler={this.handleChange} id="kod"  label={this.getLabel('kod')} error={this.getError('kod')} value={this.getValue('kod')} type="text" valueType="postalCode" />
            <InputComponent required={this.getRequire('ulica')} changeHandler={this.handleChange} id="ulica"  label={this.getLabel('ulica')}  error={this.getError('ulica')} value={this.getValue('ulica')} type="text" />
            <InputComponent required={this.getRequire('ulica_nr')} changeHandler={this.handleChange} id="ulica_nr" label={this.getLabel('ulica_nr')} error={this.getError('ulica_nr')} value={this.getValue('ulica_nr')} type="text" />
          </FormSection>
          <FormSection>
            <FormHeadlineComponent address label="Adres Korespondencji dla Umowy" />
            <SeparatorComponent />
            <BoldText backgroundDuckEggBlue>
            Adres korespondencyjny do UMOWY to adres, na który Diagnostyka Bank Komorek Macierzystych wysyła dokumentacje oraz wszystkie informacje związane z umową. Przesłanie dokumentacji na wskazany adres korespondencyjny do UMOWY będzie skutkowało dostarczeniem informacji do obojga rodziców. Zmiana adresu korespondencyjnego dla umowy jest możliwa tylko poprzez bezpośredni kontakt z Biurem Obsługi Klienta. Prosimy o <a download="ZMIANA_ADRESÓW_KORESPONDENCYJNYCH.doc" target="_blank" href={`${this.baseUrl}/ZMIANA_ADRESÓW_KORESPONDENCYJNYCH.doc`}>POBRANIE FORMULARZA</a> umieszczonego powyżej i przesłanie jego podpisanej wersji w postaci scanu dokumentu na adres <a href="mailto:bank@dbkm.pl">bank@dbkm.pl</a> lub w oryginale za pośrednictwem poczty tradycyjnej na adres biura, znajdujący się na stronie <a href="http://www.bank.diag.pl" target="_blank">www.bank.diag.pl</a>.
            </BoldText>
            <SeparatorComponent />
            <InputComponent disabled={this.getDisabled('adr_koresp_szp_imie')} changeHandler={this.handleChange} id="adr_koresp_szp_imie" label={this.getLabel('adr_koresp_szp_imie')} error={this.getError('adr_koresp_szp_imie')} value={this.getValue('adr_koresp_szp_imie')} type="text" />
            <InputComponent disabled={this.getDisabled('adr_koresp_szp_nazwisko')} changeHandler={this.handleChange} id="adr_koresp_szp_nazwisko" label={this.getLabel('adr_koresp_szp_nazwisko')}  error={this.getError('adr_koresp_szp_nazwisko')} value={this.getValue('adr_koresp_szp_nazwisko')} type="text" />
            <InputComponent disabled={this.getDisabled('adr_koresp_szp_miasto')} required={this.getRequire('adr_koresp_szp_miasto')} changeHandler={this.handleChange} id="adr_koresp_szp_miasto" label={this.getLabel('adr_koresp_szp_miasto')}  error={this.getError('adr_koresp_szp_miasto')} value={this.getValue('adr_koresp_szp_miasto')} type="text" />
            <InputComponent disabled={this.getDisabled('adr_koresp_szp_kod')} required={this.getRequire('adr_koresp_szp_kod')} changeHandler={this.handleChange} id="adr_koresp_szp_kod"  label={this.getLabel('adr_koresp_szp_kod')} error={this.getError('adr_koresp_szp_kod')} value={this.getValue('adr_koresp_szp_kod')} type="text" valueType="postalCode" />
            <InputComponent disabled={this.getDisabled('adr_koresp_szp_ulica')} required={this.getRequire('adr_koresp_szp_ulica')} changeHandler={this.handleChange} id="adr_koresp_szp_ulica"  label={this.getLabel('adr_koresp_szp_ulica')}  error={this.getError('adr_koresp_szp_ulica')} value={this.getValue('adr_koresp_szp_ulica')} type="text" />
            <InputComponent disabled={this.getDisabled('adr_koresp_szp_nr')} required={this.getRequire('adr_koresp_szp_nr')} changeHandler={this.handleChange} id="adr_koresp_szp_nr" label={this.getLabel('adr_koresp_szp_nr')} error={this.getError('adr_koresp_szp_nr')} value={this.getValue('adr_koresp_szp_nr')} type="text" />
          </FormSection>
          <FormSection checkboxes>
            <CheckboxComponent disabled smallFont label={this.getLabel('zgoda_m1')} isChecked={this.getValue('zgoda_m1') == '1'} />
            <CheckboxComponent disabled smallFont label={this.getLabel('zgoda_m2')} isChecked={this.getValue('zgoda_m2') == '1'} />
            <CheckboxComponent disabled smallFont label={this.getLabel('zgoda_m3')} isChecked={this.getValue('zgoda_m3') == '1'} />
            
            <BoldText>
            Modyfikacje udzielonych zgód możliwe tylko w przypadku kontaktu z BIUREM OBSŁUGI KLIENTA.
            </BoldText>
          </FormSection>
          { this.error }
          { this.success }
          <ButtonComponent click={this.updateUserData} primary label="Zapisz zmiany" />
        </FormContent>
      </AddressPage>
    )
  }
}

export default UserData;