import React, {Fragment} from 'react';
import { ListBox, ListBoxItem, ListBoxCell } from "./Styles";
import { Text, Header4 } from "../Styled";

const ListComponent = ({items, onClick}) => {
  const getItems = () => {
    return items.map(item => {
      return(
        <ListBoxItem key={item.id} onClick={() => onClick(item.url)}>
          <ListBoxCell>
            <Text>{item.date}</Text>
          </ListBoxCell>
          <ListBoxCell>
            <Text>{item.id}</Text>
          </ListBoxCell>
          <ListBoxCell link>
            <Text>Pobierz</Text>
          </ListBoxCell>
        </ListBoxItem>
      )
    });
  }

  const getList = () => {
    if(items.length > 0){
      return (
        <Fragment>
          <ListBoxItem>
            <ListBoxCell>
              <Header4>Data umowy</Header4>
            </ListBoxCell>
            <ListBoxCell>
              <Header4>Numer umowy</Header4>
            </ListBoxCell>
            <ListBoxCell>
              <Header4></Header4>
            </ListBoxCell>
          </ListBoxItem>
          { getItems() }
        </Fragment>
      )
    }else{
      return (
        <Header4>Brak umów</Header4>
      )
    }
  }

  return(
    <ListBox>
      { getList() }
    </ListBox>
  );
}

export default ListComponent;