import React from 'react';
import {FormHeadline, FormHeadlineBox, FormHeadlineIcon} from './Styles';
import { Header4} from './../Styled';

const FormHeadlineComponent = ({mother, father, address, label}) => {
  return(
    <FormHeadline>
      <FormHeadlineBox>
        <FormHeadlineIcon mother={mother} father={father} address={address} />
        <Header4>{label}</Header4>
      </FormHeadlineBox>
    </FormHeadline>
  )
}

export default FormHeadlineComponent;