import styled from 'styled-components';
import {h5} from './../../Styled/Fonts';
import { media } from './../../Styled/helpers';

const LinkLabel = styled.span`
  color: inherit;
  text-transform: uppercase;
  opacity: 1;
  transition: opacity .3s ease-in-out;
  user-select: none;
  text-decoration: none;
  ${props => h5(props.theme)};
`;

const LinkBox = styled.button`
  border: 0;
  outline: 0;
  background-image: none;
  display: flex;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  opacity: ${props => props.disabled ? .4 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  background-color: ${props => {
    return props.theme.color.white;
  }};
  color: ${props => {
    return props.theme.color.black;
  }};
  margin-left: ${props => props.alone ? '48px' : '0px'};
  ${ media.mobile`
    margin-left: 0;
  `}
`;

export {LinkBox, LinkLabel};