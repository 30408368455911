import React, { Component, Fragment } from 'react';
import {Link} from 'react-router-dom';
import Headline from '../Headline';
import {SignInBox, ErrorBox, SuccessBox} from './Styles';
import InputComponent from '../Input';
import ButtonComponent from '../Button';
import LinkComponent from '../Link';

class NewPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      login_reset: '',
      email: '',
    }
    this.setField = this.setField.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  resetPassword(e){
    e.preventDefault();
    this.props.resetPassword({
      login: this.state.login_reset,
      email: this.state.email,
    });
  }

  setField(data){
    this.setState({[data.field]: data.value});
  }

  get error(){
    return this.props.resetErrors && this.props.resetErrors.map((error, i) => {
      return <ErrorBox key={i}>{ error }</ErrorBox>;
    });
  }

  get success(){
    return this.props.reset ? <SuccessBox>Na adres e-mail przypisany do Twojego konta wysłane zostało tymczasowe hasło, które należy wykorzystać przy tworzeniu nowego hasła.</SuccessBox> : '';
  }

  render() { 
    return ( 
      <SignInBox onSubmit={this.resetPassword}>
        <Headline>Generowanie nowe hasła</Headline>
        <InputComponent changeHandler={this.setField} id="login_reset" label="Login" value={this.state.login} type="text" />
        <InputComponent changeHandler={this.setField} id="email" label="i/lub email" value={this.state.email} type="email" />
        <Fragment>
          { this.error }
          { this.success }
        </Fragment>
        <ButtonComponent click={this.resetPassword} secondary label="Generuj nowe hasło" />
        
        <Link to='/new_password'>
          <LinkComponent action={() =>{}} label="Masz już kod tymczasowy?" />
        </Link>
      </SignInBox>
    );
  }
}
 
export default NewPasswordComponent;