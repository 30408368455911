import styled from 'styled-components';
import {font, h4, h5} from './../../Styled/Fonts';
import {grid, media} from './../../../components/Styled/helpers';

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 0px;
  width: 100%;
  margin-top: 16px;
  opacity: ${props => props.disabled ? 0.4 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  color: ${props => props.error ? props.theme.color.vermillion : props.theme.color.black };
  border-color: ${props =>  props.error ? props.theme.color.vermillion : props.theme.color.pinkishGrey };
`;

const InputLabel = styled.label`
  width: 100%;
  margin-bottom: 8px;
  color: inherit;
  ${props => h4(props.theme)}
`;

const InputField = styled.input`
  width: calc(100%);
  box-sizing: border-box;
  padding: 8px 16px;
  border-width: 1px;
  border-style: solid;
  border-color: inherit;
  border-radius: 3px;
  color: inherit;
  outline: none;
  ${props => font(props.theme)}
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
`;

const PromoInputBox = styled.div`
  display: flex;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, .5);
  width: ${grid(2.5)};
`;

const PromoInputField = styled.input`
  ${props => h5(props.theme)}
  font-weight: normal;
  color: ${props => props.theme.color.white};
  padding: 8px 16px;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  outline: 0;
  border: 0;
  &::-webkit-input-placeholder{
    color: ${props => props.theme.color.pinkishGrey}
  }
`
export {InputBox, InputLabel, InputField, PromoInputBox, PromoInputField};