import React from 'react';
import {Route, Redirect} from 'react-router-dom';

const PrivateRoute = ({ Component, logged, path, ...rest }) => {
  if(window.location.pathname === path){
    if(logged === null){
      return (
        <h1>loader</h1>
      )
    }else{
      if(logged === true){
        return  <Component {...rest} />
      }else{
        return <Redirect to='/' />
      }
    }
  }
  return '';
}

export default PrivateRoute;