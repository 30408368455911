import React, { Component } from 'react';
import ListItem from './ListItem';
import ClickOutside from 'react-click-outside';
import {SelectBox, SelectLabel, SelectField, SelectList} from './Styles';

class SelectComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      selectedValue: {
        szpital_id: null,
        nazwa: '',
        miasto: '',
        ulica: '',
      },
      query: '',
      opened: false,
    }

    this.handleValueChange = this.handleValueChange.bind(this);
    this.openSelectList = this.openSelectList.bind(this);
    this.closeSelectList = this.closeSelectList.bind(this);
    this.setQuery = this.setQuery.bind(this);
  }

  componentWillReceiveProps(props){
    const selectedValue = props.options.filter(option => option.szpital_id == props.value);
    if(selectedValue.length){
      this.setState({selectedValue: selectedValue[0]});
    }
  }

  get selectedValue(){
    const {szpital_id, nazwa, miasto, ulica} = this.state.selectedValue;
    if(this.state.opened){
      return this.state.query;
    }
    return szpital_id !== null ? `${nazwa}, ${miasto}, ${ulica}` : '';
  }

  handleValueChange(item){
    this.setState({selectedValue: item, query: '', opened: false}, () => {
      this.props.changeHandler({
        field: this.props.name,
        value: this.state.selectedValue.szpital_id
      })
    });
  }

  setQuery(e){
    this.setState({query: e.target.value});
  }

  openSelectList(){
    this.setState({opened: true});
  }

  closeSelectList(){
    this.setState({opened: false, query: ''});
  }

  render() { 
    const selectListItems = () => {
      const { query } = this.state;
      const { options } = this.props;
      const filtered = options && options.filter(option => {
        const name = option.nazwa.toLowerCase();
        const city = option.miasto.toLowerCase();
        const street = option.ulica.toLowerCase();
        const lowerCasedQuery = query.toLowerCase();
        return (query.length === 0 || query.length > 0 && (name.includes(lowerCasedQuery) || city.includes(lowerCasedQuery) || street.includes(lowerCasedQuery)));
      });

      return filtered.map(option => {
        return <ListItem key={option.szpital_id} item={option} selectedValue={this.state.selectedValue} changeSelectedValue={this.handleValueChange} />;
      })
    }
    return(
      <SelectBox {...this.props} opened={this.state.opened}>
        <ClickOutside onClickOutside={this.closeSelectList}>
          <SelectLabel htmlFor={this.props.name}>{this.props.label}</SelectLabel>
          <SelectField readOnly={!this.state.opened} value={this.selectedValue} id={this.props.name} type="text" onFocus={this.openSelectList} onChange={this.setQuery}></SelectField>
          <SelectList opened={this.state.opened}>
            { selectListItems() }
          </SelectList>
        </ClickOutside>
      </SelectBox>
    );
  }
}
 
export default SelectComponent;