export const orderStep1 = (state = {}, action) => {
  switch(action.type){
    case 'SET_ORDER_STEP1': {
      const userData = Object.assign({}, state);
      userData[action.payload.field].value = action.payload.value;
      return userData;
    }
    case 'INIT_ORDER_STEP1':
      return action.payload;
    default:
      return state;
  }
}