import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Headline from './../Headline';
import {SignUpBox} from './Styles';
import ButtonComponent from './../Button';

class FirstVisitComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() { 
    return ( 
      <SignUpBox>
        <Headline veryLightPink subheadline="Przejdź przez konfigurator i załóż konto na końcu procesu.">Po raz pierwszy na DBKM</Headline>
        <Link to='/pregnant'>
          <ButtonComponent primary label="Przejdź dalej" />
        </Link>
      </SignUpBox>
    );
  }
}
 
export default FirstVisitComponent;