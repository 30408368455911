import styled from 'styled-components';
import {font, h4} from './../../Styled/Fonts';

const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border: 0px;
  width: 100%;
  margin-top: 16px;
  position: relative;
  opacity: ${props => props.disabled ? 0.4 : 1};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
  color: ${props => props.error ? props.theme.color.vermillion : props.theme.color.black };
  border-color: ${props =>  props.error ? props.theme.color.vermillion : props.theme.color.pinkishGrey };
  max-width: 400px;
`;

const InputLabel = styled.label`
  width: 100%;
  margin-bottom: 8px;
  color: inherit;
  ${props => h4(props.theme)}
`;

const InputField = styled.input`
  width: calc(100% - 32px);
  padding: 8px 16px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.pinkishGrey};
  color: inherit;
  outline: none;
  ${props => font(props.theme)}
  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
`;

const CalendarBox = styled.div`
  position: absolute;
  width: 100%;
  top: 82px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: ${props => props.theme.color.white};
  max-height: ${props => props.opened ? '344px' : 0};
  height: auto;
  overflow: hidden;
  z-index: 999;
  border: 1px solid;
  border-color: ${props => props.opened ? props.theme.color.pinkishGrey : 'transparent'};
`;

const CalendarNav = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: calc(100% - 32px);
  min-height: 40px;
  max-height: 40px;
`;

const CalendarNavItem = styled.span`
  padding: 8px 32px;
  ${props => h4(props.theme)};
  color: ${props => props.theme.color.black};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  background-color: ${props => props.active ? props.theme.color.duckEggBlue : props.theme.color.white};
`;

const CalendarOptions = styled.div`
  padding: 16px;
  width: calc(100% - 32px);
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
`;

const DayItem = styled.span`
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => font(props.theme)};
  color: ${props => props.theme.color.black};
  border-radius: 50%;
  margin: 4px;
  cursor: pointer;
  background-color: ${props => props.selected ? props.theme.color.veryLightPink : props.theme.color.white};
  &:hover{
    background-color: ${props => props.theme.color.veryLightPink};
  }
`;

const MonthItem = styled.span`
  width: calc(100% / 4 - 4px);
  min-width: calc(100% / 4 - 4px);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => font(props.theme)};
  color: ${props => props.theme.color.black};
  border-radius: 20px;
  margin: 2px;
  cursor: ${props => props.isActive ? 'pointer' : 'default'};
  opacity: ${props => props.isActive ? 1 : 0.5};
  pointer-events: ${props => props.isActive ? 'all' : 'none'};
  background-color: ${props => props.selected ? props.theme.color.veryLightPink : props.theme.color.white};
  &:hover{
    background-color: ${props => props.theme.color.veryLightPink};
  }
`;


const YearItem = styled.span`
  width: calc(100% / 5 - 4px);
  min-width: calc(100% / 5 - 4px);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => font(props.theme)};
  color: ${props => props.theme.color.black};
  border-radius: 20px;
  margin: 2px;
  cursor: ${props => props.isActive ? 'pointer' : 'default'};
  opacity: ${props => props.isActive ? 1 : 0.5};
  pointer-events: ${props => props.isActive ? 'all' : 'none'};
  background-color: ${props => props.selected ? props.theme.color.veryLightPink : props.theme.color.white};
  &:hover{
    background-color: ${props => props.theme.color.veryLightPink};
  }
`;

export {InputBox, InputLabel, InputField, CalendarBox, CalendarNav, CalendarNavItem, CalendarOptions, DayItem, MonthItem, YearItem};