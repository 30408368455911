import Api from '../../../utils/api';
import {
  initHospitals,
  setError
} from '../index';

export function receiveHospitals() {
  return (dispatch) => {
    new Api(`/api.php?action=slownik${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({"slownik":"szpitale","maska":""})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            if(json.szpitale.length > 0){
              dispatch(initHospitals(json.szpitale));
            }
          }else{
            console.error(json);
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
      console.log(error.msg);
    });
  };
}

