import React, { Component } from 'react';
import {InputBox, InputField, InputLabel} from './Styles';

class InputComponent extends Component{
  constructor(props){
    super(props);
    this.state = {
      value: '',
      error: null,
    }

    this.updateValue = this.updateValue.bind(this);
  }

  updateValue(e){
    let val = e.target.value;
    if(this.props.valueType === 'postalCode' && val.length === 2){
      val += '-';
    }
    if((this.props.valueType === 'postalCode' && val.length <= 6) || this.props.valueType !== 'postalCode'){
      this.setState({value: val}, () => {
        this.props.changeHandler && this.props.changeHandler({
          field: this.props.id,
          value: this.state.value
        })
      });
    }
  }

  componentDidMount(){
    this.setState({value: this.props.value, error: this.props.error});
  }

  componentWillReceiveProps(props){
    if(props.value != this.state.value){
      this.setState({value: props.value, error: props.error}, () => {
      });
    }
  }

  get label(){
    return this.props.required ? `${this.props.label}*` : this.props.label;
  }

  render(){
    return (
      <InputBox {...this.props}>
        <InputLabel htmlFor={this.props.id} dangerouslySetInnerHTML={{__html: this.label}}></InputLabel>
        <InputField id={this.props.id} type={this.props.type} value={ this.state.value } onChange={this.updateValue}></InputField>
      </InputBox>
    )
  }
}

export default InputComponent;