import styled from 'styled-components';
import {ButtonBox} from './../../../components/Button/Styles';
import {grid, media} from './../../../components/Styled/helpers';
import { Header5, Header6, Header4 } from '../../../components/Styled';
import { InputBox } from '../../../components/Input/Styles';

const AddressPage = styled.div`
   ${ButtonBox}{
    align-self: center;
    justify-content: center;
    margin-top: 56px;
    margin-bottom: 80px;
    ${ media.mobile`
      margin: 16px 0 calc(20vh + 48px);
    `}
  }
`;

const FormSection = styled.div`
  width: ${grid(7)};
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 0 48px;
  ${Header5}{
    width: 100%;
    margin: 40px 0 24px;
  }
  ${InputBox}{
    width: calc(50% - 8px);
    position: relative;
    
    ${ media.mobile`
      width: 100%;
    `}
  }
  ${ media.mobile`
    width: 100%;
    margin: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  `}
`;


const ErrorBox = styled.span`
  ${ Header6 };
  color: ${props => props.theme.color.vermillion};
  margin-top: 16px;
`;

const SuccessBox = styled.span`
  ${ Header6 };
  color: ${props => props.theme.color.success};
  margin-top: 16px;
`;

const LinkButton = styled.h4`
  color: ${props => props.theme.color.black};
  font-weight: bold;
  width: 100%;
  margin: 0 0 24px;
  text-decoration: underline;
  cursor: pointer;
  ${Header4}
`

export {AddressPage, FormSection, ErrorBox, SuccessBox, LinkButton};