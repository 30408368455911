import styled from 'styled-components';
import { StrikeText, SmallText } from '../../Styled';
import { media } from '../../Styled/helpers';

const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 24px;
  margin-top: 16px;
  padding-right: 108px;
  ${StrikeText}{
    padding: 0 8px;
  }
  ${SmallText}{
    color: #8c8c8c;
    margin-top: 8px;
  }
  ${media.mobile`
    padding: 0;
    width: 100%:
  `}
`;

export {DescriptionBox};