import Api from '../../../utils/api';
import {
  setResetErrors,
  clearResetErrors, 
  resetSuccess,
  resetFail,
  showLoader,
  hideLoader,
  setError,
} from '../index';

export function resetPassword(data) {
  return (dispatch) => {
    dispatch(showLoader());
    new Api(`/api.php?action=passwordreset${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post(data)
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0 && json.WARNING === 0){
            dispatch(clearResetErrors());
            dispatch(resetSuccess());
          }else{
            const errors = json.ERRORS.ERROR_USERINFO.map(er => {
              return Object.values(er);
            })
            const warnings = json.WARNINGS.WARNING_USERINFO.map(er => {
              return Object.values(er);
            })
            dispatch(setResetErrors(errors.concat(warnings)));
            dispatch(resetFail());
          }
          dispatch(hideLoader());
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
      dispatch(resetFail());
      dispatch(hideLoader());
    });
  };
}
