import {
  clearErrors,
  clearAddressErrors,
  clearOrderErrors,
  clearUserErrors,
  clearAddressSuccess,
  clearResetErrors, 
  clearUserSuccess,
  clearAddressDataErrors,
} from './../index';

export function clearAllErrors(){
  return dispatch => {
    dispatch(clearAddressErrors());
    dispatch(clearAddressSuccess());
    dispatch(clearErrors());
    dispatch(clearOrderErrors());
    dispatch(clearUserSuccess());
    dispatch(clearResetErrors());
    dispatch(clearUserErrors());
    dispatch(clearAddressDataErrors());
  }
}