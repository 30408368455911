import React, { Component } from 'react';
import { ThemeProvider, injectGlobal } from 'styled-components';
import { reset } from 'styled-reset';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import reducers from './redux/reducers';
import { dbkm } from './themes/DBKM';
import { grid, media } from './components/Styled/helpers';
import MainContainer from './containers/main';

injectGlobal`
  ${reset};
  body{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
  }
  .content{
    width: ${grid(12)};
    max-width: 100%;
    margin: 0 auto;
    ${media.mobile` 
      width: calc(100% - 40px); 
      padding: 0 20px;
    `}
  }
  input[type=radio],
  input[type=checkbox]{
    margin: 0;
  }
`;

const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(thunkMiddleware)
);


class App extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <ThemeProvider theme={dbkm}>
        <Provider store={ store }>
          <MainContainer />
        </Provider>
      </ThemeProvider>
    );
  }
}

export default App;
