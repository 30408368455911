
export const userData = (state = {}, action) => {
  switch(action.type){
    case 'INIT_USER_DATA': {
      return action.payload;
    }
    case 'SET_USER_DATA':{
      const userData = Object.assign({}, state);
      userData[action.payload.field].value = action.payload.value;
      return userData;
    }
    default: {
      return state;
    }
  }
}