import styled from 'styled-components';
import { media } from './../../Styled/helpers';
import mother from './mother.svg';
import father from './father.svg';
import address from './address.svg';

const FormHeadline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin-top: 64px;
  &:before{
    content: '';
    display: flex;
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1px;
    background-color: ${props => props.theme.color.white3};
    z-index: -1;
  }
`;

const FormHeadlineBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 56px;
  background-color: ${props => props.theme.color.white};
  ${ media.mobile`
    padding: 0;
    width: 100%;
  `}
`;

const FormHeadlineIcon = styled.div`
  background-color: ${props => props.theme.color.black};
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-size: 24px;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 36px;
  background-image: ${props => {
    if(props.mother){
      return `url(${mother})`
    }
    if(props.father){
      return `url(${father})`
    }
    if(props.address){
      return `url(${address})`
    }
  }};
  
  ${ media.mobile`
    width: 32px;
    height: 32px;
    margin-right: 24px;
    background-size: 16px;
  `}
`;

export {FormHeadline, FormHeadlineBox, FormHeadlineIcon};