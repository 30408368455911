import React, {Component} from 'react';
import {RadioField, RadioLabel, RadioInputBox} from './Styles';
import TooltipComponent from './../Tooltip';

class RadioInputComponent extends Component{
  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e){
    e.stopPropagation();
    this.props.changeHandler();
  }
  
  get isChecked(){
    return this.props.selectedValue && this.props.selectedValue == this.props.value;
  }

  get tooltip(){
    return this.props.tooltipCopy && <TooltipComponent copy={this.props.tooltipCopy} />;
  }

  render(){
    return (
      <RadioInputBox>
        <RadioField type="radio" id={this.props.id} checked={this.isChecked} onChange={this.handleClick} value={this.props.value} name={this.props.name}  />
        <RadioLabel htmlFor={this.props.id}>{this.props.label}</RadioLabel>
        {this.tooltip}
      </RadioInputBox>
    );
  }
}

export default RadioInputComponent;