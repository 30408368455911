import Api from '../../../utils/api';
import {
  setErrors,
  clearErrors, 
  loginSuccess, 
  logout,
  loginFail,
  hideLoader,
  showLoader,
  setError,
  receiveRegistrationData
} from '../index';

export function logoutUser() {
  return (dispatch) => {
    dispatch(showLoader());
    new Api(`/api.php?action=logout${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post({})
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0 && json.WARNING === 0){
            localStorage.removeItem('sessionID');
            dispatch(clearErrors());
            dispatch(logout());
            dispatch(hideLoader());
            window.location.reload();
          }
        });
      }
    }).catch(error => {
      dispatch(setErrors([{common: 'Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'}]));
      dispatch(hideLoader());
    });
  };
}

export function loginUser(data) {
  return (dispatch) => {
    dispatch(showLoader());
    new Api(`/api.php?action=login${localStorage.getItem('sessionID') !== null ? '&SESSIONID=' + localStorage.getItem('sessionID') : ''}`).post(data)
    .then((response) => {
      if (response.status === 200) {
        response.json().then((json) => {
          if(json.ERROR === 0){
            dispatch(clearErrors());
            dispatch(loginSuccess());
            dispatch(hideLoader());
          }else{
            if(typeof(data.password) != 'undefined' && typeof(data.password) != 'undefined'){
              const errors = json.ERRORS.ERROR_USERINFO.map(er => {
                return Object.values(er);
              })
              const warnings = json.WARNINGS.WARNING_USERINFO.map(er => {
                return Object.values(er);
              })
              dispatch(setErrors(errors.concat(warnings)));
            }
            localStorage.setItem('sessionID', json.SESSION.ID);
            dispatch(receiveRegistrationData());
            dispatch(loginFail());
            dispatch(hideLoader());
          }
        });
      }
    }).catch(error => {
      dispatch(setError('Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'));
      // dispatch(setErrors([{common: 'Wystąpił niespodziewany błąd. Prosimy o kontakt z administratorem.'}]));
      dispatch(loginFail());
      dispatch(hideLoader());
    });
  };
}
