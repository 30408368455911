import { connect } from 'react-redux';
import NewPassword from './../../screens/NewPassword';
import {  clearAllErrors, resetPassword, loginUser } from './../../redux/actions';

const mapStateToProps = (state, ownProps) => {
  return {
    resetErrors: state.get('resetErrors'),
    reset: state.get('reset'),
    errors: state.get('loggedErrors'),
    logged: state.get('logged'),
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    resetPassword: (data) => {
      dispatch(resetPassword(data));
    },
    loginUser: (data) => {
      dispatch(loginUser(data));
    },
    clearAllErrors: () => {
      dispatch(clearAllErrors());
    }
  }
}

const NewPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPassword);

export default NewPasswordContainer;
